import {Map} from "immutable"
import usersReducer from "./usersReducer"
import contactsReducer from "./contactsReducer"
import jobsReducer from "./jobsReducer"
import didsReducer from "./didsReducer"
import ivrsReducer from "./ivrsReducer"
import routingGroupsReducer from "./routingGroupsReducer"
import botsReducer from "./botsReducer"
import templatesReducer from "./templatesReducer"
import recordingsReducer from "./recordingsReducer"
import groupsReducer from "./groupsReducer"
import initialState from "./initial"

function pageReducer(state = initialState.page, action) {
    if (action.type === "PAGE_SET") {
        return action.page
    }
    return state
}

function loadingReducer(state = initialState.loading, action) {
    if (action.type === "LOADING_START") {
        return state + 1
    }
    if (action.type === "LOADING_END") {
        return state - 1
    }
    return state
}

function authReducer(state = initialState.auth, action) {
    if (action.type === "AUTH_AUTHORIZED") {
        state = state
            .set("user", action.user)
            .set("authorized", true)
            .set("token", action.token)
            .set("stack", action.stack)
            .set("timeOffset", action.timeOffset)
            .set("error", "")
            .set("theme", action.theme)
            .set("checked", true)
            .set("balance", null)
        return state
    }
    if (action.type === "AUTH_ERROR") {
        return state.set("error", action.msg).set("checked", true)
    }
    if (action.type === "AUTH_CHECKED") {
        return state.set("error", action.msg).set("checked", true)
    }
    if (action.type === "AUTH_BALANCE_LOADED") {
        return state.set("balance", action.balance)
    }
    if (action.type === "AUTH_SMS_APIKEY") {
        return state.set("user", state.get("user").set("smsapikey", action.apikey))
    }
    if (action.type === "AUTH_APIKEY") {
        return state.set("user", state.get("user").set("apikey", action.apikey))
    }
    if (action.type === "AUTH_POST_LOGOUT") {
        state = state
            .set("user", Map({}))
            .set("authorized", false)
            .set("checked", true)
        return state
    }
    if (action.type === "AUTH_COLOR_THEME") {
        return state.set("theme", action.theme)
    }
    return state
}

const reducers = {
    page: pageReducer,
    auth: authReducer,
    dids: didsReducer,
    users: usersReducer,
    contacts: contactsReducer,
    jobs: jobsReducer,
    ivrs: ivrsReducer,
    bots: botsReducer,
    recordings: recordingsReducer,
    groups: groupsReducer,
    templates: templatesReducer,
    routinggroups: routingGroupsReducer,
    loading: loadingReducer,
}

export default reducers
