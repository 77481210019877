import React from "react"
import config from "./config"
import {connect} from "react-redux"
import {page} from "./actions/page"

class Title extends React.PureComponent {
    update = title => {
        document.title = title + " — " + config.title
        this.props.dispatch(page(title))
    }

    componentDidMount = () => {
        this.update(this.props.title)
    }

    componentDidUpdate = prevProps => {
        this.update(this.props.title)
    }

    componentWillUnmount = () => {
        document.title = config.title
        this.props.dispatch(page(""))
    }

    render() {
        return ""
    }
}

export default connect()(Title)
