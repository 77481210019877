import React from "react"
import {Table, Button} from "react-bootstrap"
import Icon from "./Icon"

class Ivrs extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Table striped borderless className="table-centered">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Recording</th>
                            <th>Menu Items</th>
                            {!this.props.restricted && <th>User</th>}
                            <th style={{width: "150px"}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.size === 0 && (
                            <tr>
                                <td colSpan={6}>
                                    <center>No data</center>
                                </td>
                            </tr>
                        )}
                        {this.props.data.map(row => (
                            <tr key={row.get("id")}>
                                <td>{row.get("id")}</td>
                                <td>{row.get("name")}</td>
                                <td>{row.get("recording") && <span className="icon-success" title="Yes"></span>}</td>
                                <td>{row.get("items").size}</td>
                                {!this.props.restricted && <td>{row.get("email")}</td>}
                                <td style={{textAlign: "right"}}>
                                    <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                        <Icon icon="pencil-alt" /> Edit
                                    </Button>{" "}
                                    <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

export default Ivrs
