import React from "react"
import {connect} from "react-redux"
import {replace} from "connected-react-router"
import * as actions from "./actions/auth"

class Logout extends React.Component {
    componentDidMount() {
        this.props.dispatch(actions.logout())
        this.props.dispatch(replace("/"))
    }

    render() {
        return "Logout"
    }
}

export default connect()(Logout)
