import React from "react"
import {Table, Button, Modal} from "react-bootstrap"
import {List} from "immutable"
import Moment from "react-moment"
import Title from "./Title"
import Icon from "./Icon"
import iconDids from "./images/menu/dids.svg"
import {fixTime} from "./tools"

class Jobs extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            view: null,
            checked: List([]),
        }
    }

    onCheckAll = e => {
        let checked = e.target.checked ? this.props.data.map(item => item.get("id")) : []
        this.setState({
            checked: List(checked),
        })
    }

    onCheck = id => {
        let checked = this.state.checked
        let i = checked.indexOf(id)
        if (i < 0) checked = checked.push(id)
        else {
            checked = checked.delete(i)
        }
        this.setState({
            checked: checked,
        })
    }

    setView = row => {
        this.setState({view: row})
    }

    onHide = () => {
        this.setState({view: null})
    }

    formatType = type => {
        if (type === "singleorder") return "single order"
        return type
    }

    render() {
        return (
            <React.Fragment>
                <Title title="Jobs" />
                <Table striped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>DIDs</th>
                            <th>Status</th>
                            <th>Message</th>
                            {!this.props.restricted && <th>Creator</th>}
                            <th style={{width: "160px"}}>
                                <div className="float-right"></div>
                                Date/Time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.size === 0 && (
                            <tr>
                                <td colSpan={8}>
                                    <center>No data</center>
                                </td>
                            </tr>
                        )}
                        {this.props.data.map((row, key) => (
                            <tr key={key}>
                                <td>
                                    <Button variant="link" onClick={() => this.setView(row)}>
                                        {row.get("id")}
                                    </Button>
                                </td>
                                <td>{this.formatType(row.get("type"))}</td>
                                <td>
                                    {row.get("type") === "import" && <React.Fragment>{row.get("didsresponse")}</React.Fragment>}
                                    {row.get("type") === "transfer" && <React.Fragment>{row.get("dids")}</React.Fragment>}
                                    {row.get("type").endsWith("order") && (
                                        <React.Fragment>
                                            {row.get("didsresponse")} / {row.get("dids")}
                                        </React.Fragment>
                                    )}
                                </td>
                                <td>{row.get("status")}</td>
                                <td>{row.get("msg")}</td>
                                {!this.props.restricted && <td>{row.get("email")}</td>}
                                <td>
                                    <Moment format="L LT" local={true}>
                                        {fixTime(row.get("dateadded"), this.props.timeOffset)}
                                    </Moment>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {this.state.view && (
                    <Modal backdrop show animation={false} onHide={this.onHide}>
                        <Modal.Header>
                            <Modal.Title>
                                <Icon icon="arrow-left" onClick={this.onHide} color="#CDCDCD" />
                                <span>
                                    <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                                    <span>Job Details</span>
                                </span>
                                <Icon icon="times" onClick={this.onHide} color="#CDCDCD" />
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <ul>
                                {this.state.view.get("type") === "order" && (
                                    <>
                                        {this.state.view.get("perstate").has("TF") && <li>Toll-Free DIDs</li>}
                                        {this.state.view.get("features") && <li>Features: {this.state.view.get("features").join(", ")}</li>}
                                        <li>Requested DIDs: {this.state.view.get("dids")} </li>
                                        {!this.state.view.get("perstate").has("TF") && (
                                            <>
                                                <li>
                                                    Requested States:{" "}
                                                    {(!this.state.view.get("perstate") || this.state.view.get("perstate").size === 0) && (
                                                        <span>Random</span>
                                                    )}
                                                    {this.state.view.get("perstate") && (
                                                        <ul>
                                                            {this.state.view
                                                                .get("perstate")
                                                                .keySeq()
                                                                .sort()
                                                                .map(key => (
                                                                    <li key={key}>
                                                                        {key}: {this.state.view.get("perstate").get(key)}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    )}
                                                </li>

                                                {this.state.view.get("perstateresponse") && (
                                                    <li>
                                                        Ordered DIDs:
                                                        <ul>
                                                            {this.state.view
                                                                .get("perstateresponse")
                                                                .keySeq()
                                                                .sort()
                                                                .map(key => (
                                                                    <li key={key}>
                                                                        {key}: {this.state.view.get("perstateresponse").get(key)}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </li>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                <li>
                                    Total {this.state.view.get("type").replace("single", "")}ed: {this.state.view.get("didsresponse")}
                                </li>
                            </ul>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="danger" className="close" onClick={this.onHide}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </React.Fragment>
        )
    }
}

export default Jobs
