import React from "react"
import Icon from "./Icon"
import {Button} from "react-bootstrap"

class AudioPlayer extends React.Component {
    constructor(props) {
        super(props)
        this.audioRef = React.createRef()
        this.state = {
            error: false,
            canPlay: false,
            playing: false,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.src !== prevProps.src) {
            this.audioRef.current.load()
        }
    }

    play = () => {
        const audio = this.audioRef.current
        audio.currentTime = 0
        audio.play()
        this.setState({playing: true})
    }

    pause = () => {
        const audio = this.audioRef.current
        audio.pause()
    }

    onPlay = () => {
        this.setState({playing: true})
    }

    onPause = () => {
        this.setState({playing: false})
    }

    onCanPlay = () => {
        this.setState({
            canPlay: true,
            error: false,
        })
    }

    onError = e => {
        this.setState({
            canPlay: false,
            error: true,
        })
    }

    render() {
        return (
            <React.Fragment>
                <audio
                    ref={this.audioRef}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onEnded={this.onPause}
                    onCanPlay={this.onCanPlay}
                    onError={this.onError}
                >
                    <source src={this.props.src} />
                </audio>
                {!this.state.playing && (
                    <Button block onClick={this.play} disabled={this.state.disabled || !this.state.canPlay}>
                        <Icon icon="play" />
                    </Button>
                )}
                {this.state.playing && (
                    <Button block onClick={this.pause} disabled={this.state.disabled || !this.state.canPlay}>
                        <Icon icon="stop" />
                    </Button>
                )}
            </React.Fragment>
        )
    }
}

export default AudioPlayer
