import React from "react"
import {connect} from "react-redux"
import {Form} from "react-bootstrap"
import {formatPhone} from "./tools"

const RoutingValueSelect = React.memo(props => {
    const value = props.value !== undefined ? props.value : ""

    switch (props.type) {
        case "ivr":
            return (
                <Form.Control as="select" name={props.name} value={value} onChange={props.onChange}>
                    {!value && (
                        <option disabled value="">
                            Select IVR
                        </option>
                    )}
                    {props.ivrs
                        .filter(item => !props.exclude || !props.exclude.includes(item.get("id")))
                        .map(ivr => (
                            <option key={ivr.get("id")} value={ivr.get("id")}>
                                {ivr.get("name")}
                            </option>
                        ))}
                </Form.Control>
            )
        case "routinggroup":
            return (
                <Form.Control as="select" name={props.name} value={value} onChange={props.onChange}>
                    {!value && (
                        <option disabled value="">
                            Select Routing Group
                        </option>
                    )}
                    {props.routinggroups
                        .filter(item => !props.exclude || !props.exclude.includes(item.get("id")))
                        .map(routinggroup => (
                            <option key={routinggroup.get("id")} value={routinggroup.get("id")}>
                                {routinggroup.get("title")}
                            </option>
                        ))}
                </Form.Control>
            )
        case "contact":
            return (
                <Form.Control as="select" name={props.name} value={value} onChange={props.onChange}>
                    {!value && (
                        <option disabled value="">
                            Select Contact
                        </option>
                    )}
                    {props.contacts
                        .filter(item => !props.exclude || !props.exclude.includes(item.get("id")))
                        .map(contact => (
                            <option key={contact.get("id")} value={contact.get("id")}>
                                {contact.get("title")} - {contact.get("mode") === "did" && formatPhone(contact.get("number"))}
                                {contact.get("mode") === "sip" && contact.get("siptrunk")}
                            </option>
                        ))}
                </Form.Control>
            )
        case "group":
            return (
                <Form.Control as="select" name={props.name} value={value} onChange={props.onChange}>
                    {!value && !props.allowEmpty && (
                        <option disabled value="">
                            Select Group
                        </option>
                    )}
                    {props.allowEmpty && <option value="">No Group</option>}
                    {props.groups
                        .filter(item => !props.exclude || !props.exclude.includes(item.get("id")))
                        .map(group => (
                            <option key={group.get("id")} value={group.get("id")}>
                                {group.get("name")}
                            </option>
                        ))}
                </Form.Control>
            )
        default:
            return ""
    }
})

const mapStateToProps = state => {
    const userid = state.auth.get("user").get("id")
    return {
        ivrs: state.ivrs.get("data").filter(item => item.get("userid") === userid),
        routinggroups: state.routinggroups.get("data"),
        contacts: state.contacts.get("data"),
        groups: state.groups.get("data"),
    }
}

export default connect(mapStateToProps)(RoutingValueSelect)
