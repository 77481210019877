import initialState from "./initial"
import {List} from "immutable"
import {save} from "../LocalStorage"

const sorter = (sortfield, sortdir) => (a, b) => {
    const neg = sortdir === "desc" ? -1 : 1
    let av = a.get(sortfield)
    let bv = b.get(sortfield)
    if (av < bv) return -1 * neg
    if (av > bv) return 1 * neg
    return 0
}

function didsReducer(state = initialState.dids, action) {
    if (action.type === "DIDS_LOADING") {
        return state.set("loading", true).set("loaderror", "")
    }
    if (action.type === "DIDS_SAVED") {
        const k = state.get("data").findIndex(v => v.get("id") === action.did.get("id"))
        if (k >= 0) {
            state = state.set("data", state.get("data").set(k, action.did))
        }
    }
    if (action.type === "DIDS_LOADERROR") {
        return state
            .set("data", List([]))
            .set("alldata", List([]))
            .set("loaded", false)
            .set("loading", false)
            .set("loaderror", action.msg)
    }
    if (action.type === "DIDS_LOADED") {
        const alldata = action.data.sort(sorter(state.get("sortfield"), state.get("sortdir")))
        const data = alldata.slice(0, state.get("pageLength"))
        state = state
            .set("alldata", alldata)
            .set("data", data)
            .set("loaded", true)
            .set("loading", false)
            .set("loaderror", "")
            .set("currentPage", 0)
            .set("totalItems", alldata.size)
        return state
    }
    if (action.type === "DIDS_SET_FILTER") {
        state = state.set("filter", action.filter)
    }
    if (action.type === "DIDS_SET_SORT") {
        save("dids-sortfield", action.sortfield)
        save("dids-sortdir", action.sortdir)
        const alldata = state.get("alldata").sort(sorter(action.sortfield, action.sortdir))
        const data = alldata.slice(0, state.get("pageLength"))
        state = state
            .set("sortfield", action.sortfield)
            .set("sortdir", action.sortdir)
            .set("loading", false)
            .set("loaded", true)
            .set("data", data)
            .set("alldata", alldata)
            .set("currentPage", 0)
    }

    if (action.type === "DIDS_DELETED") {
        const ids = action.ids
        const page = state.get("currentPage")
        const alldata = state.get("alldata").map(item => (ids.includes(item.get("id")) ? item.set("status", "deleted") : item))
        const data = alldata.slice(page * state.get("pageLength"), page * state.get("pageLength") + state.get("pageLength"))
        state = state.set("alldata", alldata).set("data", data)
    }

    if (action.type === "DIDS_SET_PAGE") {
        const page = action.page
        const data = state.get("alldata").slice(page * state.get("pageLength"), page * state.get("pageLength") + state.get("pageLength"))
        state = state.set("data", data).set("currentPage", page)
    }
    return state
}

export default didsReducer
