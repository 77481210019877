import React from "react"
import {Row, Form, Col} from "react-bootstrap"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"
import DidsTable from "./DidsTable"

class DidGroupEdit extends React.PureComponent {
    changeField = e => {
        let name = e.target.name
        let value = e.target.value
        if (name === "smsendpointid") {
            value = parseInt(value, 10)
        }
        let item = this.props.item.set(name, value)
        if (name === "dest_type") {
            item = item.set("dest_value", "")
        }
        this.props.onChange(item)
    }

    render() {
        const item = this.props.item
        return (
            <>
                <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                        Name
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Group Name"
                            value={item.get("name")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                {this.props.enableRouting && (
                    <>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Routing Active
                            </Form.Label>
                            <Col sm="4">
                                <RoutingTypeSelect type="did" name="dest_type" value={item.get("dest_type")} onChange={this.changeField} />
                            </Col>
                            <Col sm="5">
                                <RoutingValueSelect
                                    name="dest_value"
                                    type={item.get("dest_type")}
                                    value={item.get("dest_value")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Routing Disabled
                            </Form.Label>
                            <Col sm="4">
                                <RoutingTypeSelect
                                    type="group_disabled"
                                    name="dest_type_disabled"
                                    value={item.get("dest_type_disabled")}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm="5">
                                <RoutingValueSelect
                                    name="dest_value_disabled"
                                    type={item.get("dest_type_disabled")}
                                    value={item.get("dest_value_disabled")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                    </>
                )}
                {this.props.smsendpoints && this.props.smsendpoints.size > 0 && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            SMS Action
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control as="select" name="smsendpointid" value={item.get("smsendpointid")} onChange={this.changeField}>
                                {!item.get("smsendpointid") && <option value="">Select SMS Endpoint</option>}
                                {this.props.smsendpoints
                                    .filter(ep => ep.get("name") !== "SMS Bot")
                                    .map(ep => (
                                        <option key={ep.get("id")} value={ep.get("id")}>
                                            {ep.get("name")}
                                        </option>
                                    ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                )}

                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        IVR Status
                    </Form.Label>
                    <Col sm="9">
                        <Form.Check
                            inline
                            name="status"
                            type="radio"
                            onChange={this.changeField}
                            value="active"
                            label="Active"
                            checked={item.get("status") === "active"}
                        />
                        <Form.Check
                            inline
                            name="status"
                            type="radio"
                            onChange={this.changeField}
                            value="disabled"
                            label="Disabled"
                            checked={item.get("status") === "disabled"}
                        />
                    </Col>
                </Form.Group>
                {!!item.get("id") && (
                    <Form.Group>
                        <DidsTable
                            dids={this.props.dids}
                            checked={this.props.checked}
                            onChangeChecked={this.props.onChangeChecked}
                            onDelete={this.props.onDelete}
                        />
                    </Form.Group>
                )}
            </>
        )
    }
}

export default DidGroupEdit
