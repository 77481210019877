import React from "react"
import Icon from "../../Icon"
import {Map} from "immutable"
import {formatPhone} from "../../tools"

class IvrLogRow extends React.PureComponent {
    state = {
        fieldfilter: null,
    }

    showFilter = fieldfield => {
        this.setState({fieldfilter: fieldfield})
    }

    hideFilter = () => {
        this.setState({fieldfilter: null})
    }

    onFilter = () => {
        const field = this.state.fieldfilter
        let f = Map({}).set(field, this.props.ivrlog.get(field))
        this.props.onFilter(f)
    }

    onUnFilter = () => {
        let f = Map({}).set(this.state.fieldfilter, "")
        this.props.onFilter(f)
    }

    render() {
        const fieldfilter = this.state.fieldfilter
        const ivrlog = this.props.ivrlog
        const value = ivrlog.get(fieldfilter)
        const active = !!fieldfilter && this.props.filter.get(fieldfilter) === value

        const icon = (
            <div
                style={{position: "absolute", marginLeft: "-18px", marginTop: "-20px"}}
                title={active ? `Remove filter by ${value}` : `Filter by ${value}`}
            >
                {" "}
                <span className="fa-layers fa-fw">
                    <Icon icon="filter" inverse onClick={this.onFilter} />
                    {active && <Icon size="2x" icon="ban" color="red" style={{marginLeft: "-5px"}} onClick={this.onUnFilter} />}
                </span>
            </div>
        )

        return (
            <tr>
                <td onMouseEnter={() => this.showFilter("did")} onMouseLeave={this.hideFilter}>
                    {formatPhone(ivrlog.get("did"))}
                    {fieldfilter === "did" && icon}
                </td>
                <td onMouseEnter={() => this.showFilter("ivr")} onMouseLeave={this.hideFilter}>
                    {ivrlog.get("ivr")}
                    {fieldfilter === "ivr" && icon}
                </td>
                {this.props.opts.map(opt => (
                    <td key={opt}>{ivrlog.get("opt" + opt)}</td>
                ))}
                {this.props.restricted || (
                    <td onMouseEnter={() => this.showFilter("email")} onMouseLeave={this.hideFilter}>
                        {ivrlog.get("email")}
                        {fieldfilter === "email" && icon}
                    </td>
                )}
            </tr>
        )
    }
}

export default IvrLogRow
