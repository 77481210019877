import React from "react"
import moment from "moment"
import Icon from "../../Icon"
import {Map} from "immutable"
import {Form} from "react-bootstrap"
import {formatTime, formatPhone, fixTime} from "../../tools"

class LiveCallRow extends React.PureComponent {
    state = {
        fieldfilter: null,
    }

    showFilter = fieldfield => {
        this.setState({fieldfilter: fieldfield})
    }

    hideFilter = () => {
        this.setState({fieldfilter: null})
    }

    onFilter = () => {
        let f = Map({}).set(this.state.fieldfilter, this.props.livecall.get(this.state.fieldfilter))
        this.props.onFilter(f)
    }

    onUnFilter = () => {
        let f = Map({}).set(this.state.fieldfilter, "")
        this.props.onFilter(f)
    }

    icon = (active, value) => {
        return (
            <div
                style={{position: "absolute", marginLeft: "-18px", marginTop: "-40px"}}
                title={active ? `Remove filter by ${value}` : `Filter by ${value}`}
            >
                {" "}
                <span className="fa-layers fa-fw">
                    <Icon icon="filter" inverse onClick={this.onFilter} />
                    {active && <Icon size="2x" icon="ban" color="red" style={{marginLeft: "-5px"}} onClick={this.onUnFilter} />}
                </span>
            </div>
        )
    }

    render() {
        const livecall = this.props.livecall
        const active = this.props.filter.get(this.state.fieldfilter) === livecall.get(this.state.fieldfilter)
        const value = livecall.get(this.state.fieldfilter)

        const calldate = fixTime(livecall.get("calldate"), this.props.timeOffset)
        const ds = livecall.get("duration") + this.props.delta
        const duration = formatTime(ds)
        return (
            <tr>
                <td>
                    <Form.Check
                        type="checkbox"
                        label=""
                        checked={this.props.checked}
                        onChange={() => this.props.onCheck(livecall.get("id"))}
                    />
                </td>
                <td>{livecall.get("direction") === "in" ? "In" : "Out"}</td>
                <td onMouseEnter={() => this.showFilter("did")} onMouseLeave={this.hideFilter}>
                    {formatPhone(livecall.get("did"))}
                    {this.state.fieldfilter === "did" && this.icon(active, value)}
                </td>
                <td>{formatPhone(livecall.get("callerid"))}</td>
                <td>{livecall.get("context")}</td>
                {this.props.restricted || (
                    <td onMouseEnter={() => this.showFilter("email")} onMouseLeave={this.hideFilter} className="d-none d-md-table-cell">
                        {livecall.get("email")}
                        {this.state.fieldfilter === "email" && this.icon(active, value)}
                    </td>
                )}
                <td title={`${ds} seconds`}>{duration}</td>
                <td className="d-none d-md-table-cell">{moment(calldate).format("L LT")}</td>
            </tr>
        )
    }
}

export default LiveCallRow
