import React from "react"
import {Table, Button} from "react-bootstrap"
import Icon from "../Icon"
import {fixTime, formatPhone} from "../tools"

class Blacklist extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th className="d-none d-md-table-cell">ID</th>
                        <th>CallerID</th>
                        <th>Notes</th>
                        <th className="d-none d-lg-table-cell">Date/Time</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={6}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((row, key) => (
                        <tr key={row.get("id")}>
                            <td className="d-none d-md-table-cell">{row.get("id")}</td>
                            <td>{formatPhone(row.get("clid"))}</td>
                            <td>{row.get("notes")}</td>
                            <td className="d-none d-lg-table-cell">
                                {fixTime(row.get("dateadded"), this.props.timeOffset).format("L LT")}
                            </td>
                            <td className="float-right">
                                <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                    <Icon icon="pencil-alt" /> Edit
                                </Button>{" "}
                                <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Blacklist
