import React, {PureComponent} from "react"
import {Button, Form, Row, Col, InputGroup} from "react-bootstrap"
import {Map} from "immutable"
import IvrItemEdit from "./IvrItemEdit"
import config from "./config"
import AudioPlayer from "./AudioPlayer"
import Icon from "./Icon"
import {Typeahead} from "react-bootstrap-typeahead"
import "../node_modules/react-bootstrap-typeahead/css/Typeahead-bs4.min.css"

class IvrEdit extends PureComponent {
    constructor(props) {
        super(props)

        this.fileRef = React.createRef()
        this.fileRef2 = React.createRef()
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let ivr = this.props.ivr.set(field, value)
        if (field === "newrecording_file" && !ivr.get("newrecording_name")) {
            ivr = ivr.set("newrecording_name", this.formatFilename(value.name))
        }
        if (field === "dnc_newrecording_file" && !ivr.get("dnc_newrecording_name")) {
            ivr = ivr.set("dnc_newrecording_name", this.formatFilename(value.name))
        }
        if (field === "recording") {
            ivr = ivr
                .set("newrecording_name", "")
                .set("newrecording_file", null)
                .set(field, value ? parseInt(value, 10) : null)
        }
        if (field === "dncrecording") {
            ivr = ivr
                .set("dnc_newrecording_name", "")
                .set("dnc_newrecording_file", null)
                .set(field, value ? parseInt(value, 10) : null)
        }
        this.props.onChange(ivr)
    }

    onMenuItemAdd = () => {
        const items = this.props.ivr.get("items").push(
            Map({
                opt: "",
                action: "",
                value: "",
            }),
        )
        this.props.onChange(this.props.ivr.set("items", items))
    }

    onMenuItemDelete = i => {
        if (!window.confirm("Delete?")) return
        const items = this.props.ivr.get("items").delete(i)
        this.props.onChange(this.props.ivr.set("items", items))
    }

    onMenuItemChange = (i, value) => {
        const items = this.props.ivr.get("items").set(i, value)
        this.props.onChange(this.props.ivr.set("items", items))
    }

    formatFilename = filename => {
        return filename
            .replace(/\.([^.]+)$/, "")
            .replace(/[_-]+/g, " ")
            .trim()
    }

    onSelectFile = e => {
        this.changeFieldValue("newrecording_file", e.target.files[0])
    }

    onSelectFile2 = e => {
        this.changeFieldValue("dnc_newrecording_file", e.target.files[0])
    }

    render() {
        const ivr = this.props.ivr

        const recordings = this.props.recordings
            .valueSeq()
            .map(recording => {
                return {
                    id: recording.get("id"),
                    label: recording.get("name"),
                    filepath: recording.get("filepath"),
                }
            })
            .toArray()

        let selectedRecording = []
        if (this.props.ivr.get("recording")) {
            const tmp = recordings.filter(r => r.id === this.props.ivr.get("recording"))
            selectedRecording = tmp.length > 0 ? [tmp[0]] : []
        }

        let selectedDncRecording = []
        if (this.props.ivr.get("dncrecording")) {
            const tmp = recordings.filter(r => r.id === this.props.ivr.get("dncrecording"))
            selectedDncRecording = tmp.length > 0 ? [tmp[0]] : []
        }

        return (
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Name
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="IVR name"
                            defaultValue={ivr.get("name")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Recording
                    </Form.Label>
                    <Col sm="8">
                        <InputGroup>
                            <Typeahead
                                placeholder="Select or add new recording"
                                style={{flex: "1 1 auto"}}
                                id={`typeahead`}
                                selected={selectedRecording}
                                onChange={e => this.changeFieldValue("recording", e[0] ? e[0].id : null)}
                                options={recordings}
                            />
                            {!!this.props.ivr.get("recording") && (
                                <InputGroup.Append>
                                    <Button variant="secondary" onClick={() => this.changeFieldValue("recording", null)}>
                                        <Icon icon="times" />
                                    </Button>
                                </InputGroup.Append>
                            )}
                        </InputGroup>
                    </Col>
                    <Col sm="1">
                        {(!ivr.get("recording") || !recordings) && (
                            <Button block disabled>
                                <Icon icon="question" />
                            </Button>
                        )}
                        {ivr.get("recording") && recordings && selectedRecording.length && (
                            <AudioPlayer src={`${config.dataEndpoint}${selectedRecording[0].filepath}`} />
                        )}
                    </Col>
                </Form.Group>
                {!ivr.get("recording") && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Col sm={3} />
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    name="newrecording_name"
                                    placeholder="New Recording Name"
                                    value={ivr.get("newrecording_name") ? ivr.get("newrecording_name") : ""}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={3} />
                            <Col sm={9}>
                                <input
                                    style={{display: "none"}}
                                    ref={this.fileRef}
                                    type="file"
                                    name="file"
                                    onChange={this.onSelectFile}
                                    accept=".mp3,.wav"
                                />
                                <Button variant="success" onClick={() => this.fileRef.current.click()}>
                                    Select File
                                </Button>{" "}
                                {ivr.get("newrecording_file") && (
                                    <span style={{marginLeft: "3pt"}}>
                                        {ivr.get("newrecording_file").name} ({Math.round(ivr.get("newrecording_file").size / 1024)} Kb)
                                    </span>
                                )}
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}

                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        DNC Recording
                    </Form.Label>
                    <Col sm="8">
                        <InputGroup>
                            <Typeahead
                                placeholder="Select or add new DNC recording"
                                style={{flex: "1 1 auto"}}
                                id={`typeahead`}
                                selected={selectedDncRecording}
                                onChange={e => this.changeFieldValue("dncrecording", e[0] ? e[0].id : null)}
                                options={recordings}
                            />
                            {!!this.props.ivr.get("dncrecording") && (
                                <InputGroup.Append>
                                    <Button variant="secondary" onClick={() => this.changeFieldValue("dncrecording", null)}>
                                        <Icon icon="times" />
                                    </Button>
                                </InputGroup.Append>
                            )}
                        </InputGroup>
                    </Col>
                    <Col sm="1">
                        {(!ivr.get("dncrecording") || !recordings) && (
                            <Button block disabled>
                                <Icon icon="question" />
                            </Button>
                        )}
                        {ivr.get("dncrecording") && recordings && selectedDncRecording.length && (
                            <AudioPlayer src={`${config.dataEndpoint}${selectedDncRecording[0].filepath}`} />
                        )}
                    </Col>
                </Form.Group>
                {!ivr.get("dncrecording") && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Col sm={3} />
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    name="dnc_newrecording_name"
                                    placeholder="New DNC Recording Name"
                                    value={ivr.get("dnc_newrecording_name") ? ivr.get("dnc_newrecording_name") : ""}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={3} />
                            <Col sm={9}>
                                <input
                                    style={{display: "none"}}
                                    ref={this.fileRef2}
                                    type="file"
                                    name="file"
                                    onChange={this.onSelectFile2}
                                    accept=".mp3,.wav"
                                />
                                <Button variant="success" onClick={() => this.fileRef2.current.click()}>
                                    Select File
                                </Button>{" "}
                                {ivr.get("dnc_newrecording_file") && (
                                    <span style={{marginLeft: "3pt"}}>
                                        {ivr.get("dnc_newrecording_file").name} ({Math.round(ivr.get("dnc_newrecording_file").size / 1024)}{" "}
                                        Kb)
                                    </span>
                                )}
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}
                <hr />
                <Form.Group as={Row}>
                    <Col xs="12">
                        <Button variant="secondary" onClick={this.onMenuItemAdd}>
                            Add Menu Item
                        </Button>
                    </Col>
                </Form.Group>
                {ivr.get("items").size > 0 && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Col xs={2}>Opt</Col>
                            <Col xs={4}>Destination</Col>
                            <Col xs={4}>Value</Col>
                        </Form.Group>
                        {ivr.get("items").map((item, key) => (
                            <Form.Group as={Row} key={key}>
                                <IvrItemEdit
                                    item={item}
                                    ivrs={this.props.ivrs}
                                    onChange={value => this.onMenuItemChange(key, value)}
                                    onDelete={() => this.onMenuItemDelete(key)}
                                />
                            </Form.Group>
                        ))}
                    </React.Fragment>
                )}
            </Form>
        )
    }
}
export default IvrEdit
