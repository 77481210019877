import React from "react"
import {connect} from "react-redux"
import {Map} from "immutable"
import {toast} from "react-toastify"

import {smsLoad} from "../actions/reports"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"

import Sms from "./Sms"
import Paginator from '../Paginator'

class SmsControlled extends React.PureComponent {
    state = {
        data: null,
        total: 0,
        filter: Map({
            direction: this.props.direction,
        }),
        start: 0,
        limit: 50,
    }

    reload = () => {
        smsLoad(
            this.state.filter,
            "sms.id",
            "desc",
            this.props.dispatch,
            this.state.limit,
            this.state.start,
        )
            .then(({data, total}) => {
                this.setState({data, total})
            })
            .catch(e => {
                console.log(e)
                toast.error("Cannot load SMS list", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    componentDidMount = () => {
        this.reload()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.direction !== this.props.direction) {
            this.setState({filter: this.state.filter.set("direction", this.props.direction)}, this.reload)
        }
    }

    onPageClick(pg) {
        let start = pg * this.state.limit;
        this.setState({start}, this.reload);
    }

    onPagLimit(limit) {
        this.setState({limit, start: 0}, this.reload);
    }

    render() {
        const title = this.props.direction === "in" ? "Inbound SMS" : "Outbound SMS"
        const {total, limit, start} = this.state;
        return (
            <div>
                <Title title={title} />
                <div className="actionBar">
                    <div className="float-right">
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.state.data !== null && (
                    <Sms
                        data={this.state.data}
                        direction={this.props.direction}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                        onReload={this.reload}
                        onTest={this.onTest}
                    />
                )}
                {this.state.total > 10 && (
                    <>
                        SMS per page:&nbsp;
                        <select
                            className="form-control d-inline w-auto"
                            onChange={({target: {value}}) => this.onPagLimit(Number(value))}
                            value={this.state.limit}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                        </select>
                    </>
                )}
                {this.state.total > this.state.limit && (
                    <Paginator
                        onSetPage={p => this.onPageClick(p)}
                        totalItems={total}
                        pageLength={limit}
                        currentPage={Math.floor(start / limit)}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(SmsControlled)
