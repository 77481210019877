import React from "react"
import {connect} from "react-redux"
import Dropdown from "./Dropdown"
import {Nav} from "react-bootstrap"
import {replace} from "connected-react-router"
import {Set} from "immutable"

import iconDashboard from "./images/menu/dashboard.svg"
import iconUsers from "./images/menu/users.svg"
import iconDids from "./images/menu/dids.svg"
//import iconJobs from "./images/menu/jobs.svg"
import iconIvrs from "./images/menu/ivrs.svg"
//import iconIvrLogs from "./images/menu/ivrlogs.svg"
import iconCdrs from "./images/menu/cdrs.svg"
import iconLiveCalls from "./images/menu/livecalls.svg"
import iconBots from "./images/menu/bots.svg"
import iconReports from "./images/menu/reports.svg"
import config from "./config"

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: null,
            user: props.user,
            menu: this.getMenu(props.user),
        }
    }

    onLink = (link, e) => {
        e.preventDefault()
        this.props.onHideMenu()
        this.props.dispatch(replace(link))
    }

    onCloseLink = (link, e) => {
        e.preventDefault()
        this.setState({expanded: null})
        this.props.onHideMenu()
        this.props.dispatch(replace(link))
    }

    componentDidUpdate = prevProps => {
        if (this.props.user !== prevProps.user) {
            this.setState({
                user: this.props.user,
                menu: this.getMenu(this.props.user),
            })
        }
    }

    getMenu = user => {
        let features = Set([])
        if (user && user.has("trunks") && user.get("trunks")) {
            user.get("trunks").forEach(trunk => {
                features = features.union(config.trunks[trunk.toString()].features)
            })
        }

        const isAdmin = user.get("role") === "admin"
        const isUser = user.get("role") === "user"
        const isClient = user.get("role") === "client"

        const isIvrs = user.get("opt_ivr_enabled") !== 0
        const isBots = user.get("opt_bots_enabled") !== 0
        const isSMS = user.get("opt_sms_enabled") !== 0

        const isWholesale = user.get("clienttype") === "wholesale"

        return [
            {title: "Dashboard", icon: iconDashboard, url: "/"},
            {title: "Users", icon: iconUsers, url: "/users", enabled: isAdmin},
            {
                title: "Clients",
                icon: iconUsers,
                enabled: (isIvrs || isWholesale) && isUser,
                submenu: [
                    {title: "Clients", url: "/clients"},
                    {title: "Contacts", url: "/contacts"},
                ],
            },
            {
                title: "DIDs",
                icon: iconDids,
                enabled: isAdmin || isUser,
                submenu: [
                    {title: "DIDs", url: "/dids"},
                    {title: "Blacklist", url: "/blacklist/", enabled: isIvrs},
                    {title: "Jobs", url: "/jobs"},
                    {title: "DID Groups", url: "/dids/groups/"},
                    {title: "Routing Groups", url: "/routinggroups/", enabled: isIvrs},
                ],
            },
            {
                title: "SMS",
                icon: iconDids,
                enabled: (isUser && isSMS) || isAdmin,
                submenu: [
                    {title: "Settings", url: "/sms/", enabled: isUser},
                    {title: "Inbound", url: "/sms/inbound/"},
                    {title: "Outbound", url: "/sms/outbound/"},
                ],
            },
            {
                title: "IVR",
                icon: iconIvrs,
                enabled: isIvrs,
                url: "/ivrs",
            },
            {title: "CDR", icon: iconCdrs, url: "/cdrs", enabled: isIvrs || isClient},
            {title: "Live Calls", icon: iconLiveCalls, url: "/livecalls", enabled: isIvrs},
            {
                title: "Virtual Assistants",
                icon: iconBots,
                enabled: isBots,
                submenu: [
                    {title: "Virtual Assistants", url: "/virtualassistants"},
                    {title: "Templates", url: "/virtualassistants/templates", enabled: isAdmin},
                ],
            },
            {
                title: "Reports",
                icon: iconReports,
                enabled: isAdmin || isUser,
                submenu: [
                    {title: "Group Report", url: "/reports/group", enabled: isIvrs},
                    {title: "IVR Report", url: "/ivrlogs", enabled: isIvrs},
                    {title: "Earnings", url: "/reports/earnings/", enabled: isAdmin},
                    {title: "Client Endpoints", url: "/reports/earnings/", enabled: isUser || isClient},
                    {title: "Transactions", url: "/transactions"},
                    {title: "DIDs Report", url: "/reports/dids", enabled: isAdmin || isUser},
                    {title: "Spending", url: "/reports/spending", enabled: isAdmin || isUser},
                ],
            },
        ]
    }

    onDropdownClick = key => {
        this.setState({expanded: this.state.expanded === key ? null : key})
    }

    render() {
        return (
            <div className="menu">
                {this.state.menu.map(
                    (item, key) =>
                        (item.enabled === undefined || item.enabled === true) && (
                            <React.Fragment key={key}>
                                {!item.submenu && (
                                    <Nav.Link onSelect={this.onCloseLink} href={item.url}>
                                        <img src={item.icon} alt="" /> {item.title}
                                    </Nav.Link>
                                )}
                                {item.submenu && (
                                    <Dropdown
                                        icon={item.icon}
                                        title={item.title}
                                        expanded={this.state.expanded === key}
                                        onChange={() => this.onDropdownClick(key)}
                                    >
                                        {item.submenu.map(
                                            (subitem, subkey) =>
                                                (subitem.enabled === undefined || subitem.enabled === true) && (
                                                    <Nav.Link onSelect={this.onLink} href={subitem.url} key={subkey}>
                                                        {subitem.title}
                                                    </Nav.Link>
                                                ),
                                        )}
                                    </Dropdown>
                                )}
                            </React.Fragment>
                        ),
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(Menu)
