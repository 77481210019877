import React from "react"
import {Button} from "react-bootstrap"
import Icon from "./Icon"
import QuestionTypes from "./data/QuestionTypes"

class Question extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: true,
            question: props.question,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.question !== prevProps.question) {
            this.setState({question: this.props.question})
        }
    }

    render() {
        const question = this.state.question

        return (
            <tr className={question.get("status") === "deleted" ? "hidden" : ""}>
                <td>{this.props.handle}</td>
                <td style={{width: "70px"}}>{QuestionTypes[question.get("type")]}</td>
                <td style={{maxWidth: "150px", width: "150px", wordWrap: "break-word"}}>{question.get("name")}</td>
                <td>
                    {question.get("questions").size > 0 && question.get("questions").get(0)}
                    {question.get("questions").size > 1 && (
                        <React.Fragment>
                            {" "}
                            <Icon
                                size="lg"
                                icon={this.state.collapsed ? "chevron-circle-right" : "chevron-circle-down"}
                                onClick={() => this.setState({collapsed: !this.state.collapsed})}
                            />
                            {!this.state.collapsed &&
                                question
                                    .get("questions")
                                    .filter((v, k) => k > 0)
                                    .map((q, k) => <div key={k}>{q}</div>)}
                        </React.Fragment>
                    )}
                </td>
                <td style={{width: "150px", textAlign: "right"}}>
                    <Button size="sm" className="edit" variant="info" onClick={this.props.onEdit}>
                        <Icon icon="pencil-alt" /> Edit
                    </Button>{" "}
                    <Button size="sm" variant="danger" onClick={this.props.onDelete}>
                        Delete
                    </Button>
                </td>
            </tr>
        )
    }
}

export default Question
