import React from "react"
import {Form} from "react-bootstrap"

const filter = {
    title: "DID",
    Input: props => {
        return <Form.Control type="text" value={props.value} onChange={e => props.onChange(e.target.value)} />
    },
}

export default filter
