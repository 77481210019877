import React, {useState} from "react"
import {store} from "../data/store"
import {Typeahead} from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "react-bootstrap-typeahead/css/Typeahead-bs4.min.css"

const sort = (a, b) => {
    a = a.get("email").toLowerCase()
    b = b.get("email").toLowerCase()
    return a < b ? -1 : a > b ? 1 : 0
}

const UsersSelector = props => {
    const [tags, setTags] = useState(
        props.users
            .filter(user => props.selected.includes(user.get("id")))
            .map(user => {
                return {
                    id: user.get("id"),
                    label: user.get("email"),
                }
            })
            .toJS(),
    )

    return (
        <Typeahead
            id="users-select"
            selected={tags}
            onChange={selected => {
                setTags(selected)
                const value = selected.map(item => item.id)
                const title = selected.map(item => item.label).join(", ")
                props.onChange(value, title)
            }}
            options={props.users
                .filter(user => user.get("role") !== "admin")
                .sort(sort)
                .map(user => {
                    return {id: user.get("id"), label: user.get("email")}
                })
                .toJS()}
            placeholderText="Select Users"
            multiple
        />
    )
}

const filter = {
    title: "Users",
    Format: value => {
        if (!value) return ""
        return store
            .getState()
            .users.get("data")
            .filter(u => u.get("role") !== "admin" && value.includes(u.get("id")))
            .map(u => u.get("email"))
            .join(", ")
    },
    Input: props => {
        const value = props.value === "" ? [] : props.value
        const users = store.getState().users.get("data")
        return <UsersSelector users={users} selected={value} onChange={props.onChange} />
    },
}

export default filter
