export function load(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "SMSENDPOINTS_LOAD",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function save(smsendpoint, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "SMSENDPOINTS_SAVE",
            smsendpoint: smsendpoint,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "SMSENDPOINTS_DELETE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function test(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "SMSENDPOINTS_TEST",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}
