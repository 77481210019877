import React from "react"
import {connect} from "react-redux"
import * as actions from "./actions/auth"
import {Map} from "immutable"
import {Navbar, Nav, Card, Button} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import RoutesUnauth from "./RoutesUnauth"
import {replace} from "connected-react-router"
import logo from "./images/logo.svg"
//import iconSettings from "./images/menu/settings.svg"
//import iconDownload from "./images/icons/download.svg"
import iconHamburger from "./images/icons/hamburger.svg"
import Switch from "./Switch"
import Loading from "./Loading"
import Menu from "./Menu"
import Icon from "./Icon"
import Loader from "./Loader"
import Balance from "./Balance"

class Auth extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            authorized: false,
            checked: false,
            email: "",
            password: "",
            user: Map({}),
            error: null,
            loading: props.loading,
            page: "",
            stack: props.stack,
        }
    }

    componentDidMount = () => {
        actions
            .check(this.props.dispatch)
            .then(user => {
                this.setState({authorized: true, error: null})
            })
            .catch(e => {
                if (e === "Login required") {
                    this.props.dispatch(replace("/login/"))
                    return
                }
                this.setState({
                    authorized: false,
                    error: e,
                })
            })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            page: props.page,
            authorized: props.authorized,
            checked: props.checked,
            login: props.authorized ? state.login : "",
            password: props.authorized ? state.password : "",
            user: props.user,
            error: props.error,
            loading: props.loading,
            stack: props.stack,
        }
    }

    componentDidUpdate = prevProps => {
        if (prevProps.theme !== this.props.theme) {
            const css = document.getElementById("darkmodecss")
            css.disabled = this.props.theme !== "dark"

            const body = document.getElementsByTagName("body")[0]
            body.classList.remove("theme-light", "theme-dark")
            body.classList.add("theme-" + this.props.theme)

            const metas = document.getElementsByTagName("META")
            for (var i = 0; i < metas.length; i++) {
                const meta = metas[i]
                if (meta.name === "theme-color") {
                    if (this.props.theme === "light") {
                        meta.content = "#white"
                    } else {
                        meta.content = "#1C2E46"
                    }
                }
            }
        }
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleLoginClick = e => {
        e.preventDefault()
        this.props.dispatch(actions.login(this.state.login, this.state.password))
    }

    handleToggleLogin = e => {
        e.preventDefault()
        this.setState({showLogin: !this.state.showLogin})
    }

    onLogin = email => {
        this.setState({email: email})
    }

    onLink = (link, e) => {
        e.preventDefault()
        this.props.dispatch(replace(link))
    }

    onChangeTheme = e => {
        this.props.dispatch(actions.setColorTheme(e.target.checked ? "dark" : "light"))
    }

    onShowMenu = e => {
        e.preventDefault()
        this.setState({showMenu: true})
    }

    onHideMenu = () => {
        this.setState({showMenu: false})
    }

    render() {
        const email = this.state.user.get("email")

        if (this.state.error || window.location.pathname === "/error") {
            return (
                <Card className="backend-error">
                    <Card.Body>
                        <Card.Title>Network error</Card.Title>
                        <Card.Text>Please try again later</Card.Text>
                        <Button variant="primary" onClick={() => window.location.reload()}>
                            Reload
                        </Button>
                    </Card.Body>
                </Card>
            )
        }

        if (!this.state.checked) {
            return ""
        }

        if (!this.state.authorized) return <RoutesUnauth />

        return (
            <Loader>
                {this.state.loading > 0 && <Loading />}
                <div style={{display: "flex", flexDirection: "row", alignItems: "stretch", minHeight: "100%"}}>
                    {this.state.showMenu && <div className="sidebar-backdrop" onClick={this.onHideMenu}></div>}
                    <div id="left" className={this.state.showMenu ? "mobile-menu-visible" : "mobile-menu-hidden"}>
                        <Navbar className="sidebar">
                            <Nav key={1} className="flex-column" style={{width: "290px"}}>
                                <Navbar.Brand>
                                    <LinkContainer to="/">
                                        <a href="/">
                                            <img src={logo} alt="Logo" />
                                        </a>
                                    </LinkContainer>
                                </Navbar.Brand>
                                <div className="mobile-menu-title">
                                    <div>Menu</div>
                                    <div>
                                        <Icon icon="times" onClick={this.onHideMenu} />
                                    </div>
                                </div>

                                <Menu onHideMenu={this.onHideMenu} />
                                <div className="switch-wrapper">
                                    <div>
                                        Dark Mode <Switch onChange={this.onChangeTheme} checked={this.props.theme === "dark"} />
                                    </div>
                                </div>
                            </Nav>
                        </Navbar>
                    </div>
                    <div id="right">
                        <div>
                            <Navbar bg="light" className="mobile">
                                <Nav key={1}>
                                    <Nav.Link onClick={this.onShowMenu}>
                                        <img className="icon-hamburger" src={iconHamburger} alt="Menu" />
                                    </Nav.Link>
                                </Nav>
                                <Nav key={2}>
                                    <img src={logo} alt="Logo" />
                                </Nav>
                                <Nav.Item>
                                    <Nav.Link onSelect={this.onLink} href="/logout">
                                        Logout
                                    </Nav.Link>
                                </Nav.Item>
                            </Navbar>
                            <Navbar bg="light" className="navbar-top-desktop">
                                <Nav key={1} className="mr-auto">
                                    <span className="page-title">{this.state.page}</span>
                                </Nav>
                                <Nav key={2}>
                                    {/*
                                        <Nav.Item>
                                            <Nav.Link onSelect={this.onLink} href="/settings">
                                                <img src={iconSettings} alt="Settings" style={{height: "20px"}} />
                                            </Nav.Link>
                                        </Nav.Item>*/}
                                    {this.state.user.get("role") === "user" && (
                                        <Nav.Item className="static">
                                            <Balance
                                                suspensionlimit={this.props.user.get("suspensionlimit")}
                                                notificationlimit={this.props.user.get("notificationlimit")}
                                            />
                                        </Nav.Item>
                                    )}
                                    <Nav.Item>
                                        <Nav.Link eventKey="disabled" disabled>
                                            {email}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link onSelect={this.onLink} href="/logout">
                                            {this.state.stack.isEmpty() && <>Logout</>}
                                            {!this.state.stack.isEmpty() && <>Back to {this.state.stack.peek().get("user")}</>}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Navbar>
                        </div>
                        <div id="main">{this.props.children}</div>
                        <div id="bottom">
                            <span style={{color: "#979797"}}>Copyright 2019 © Caller Statistics. All rights reserved</span>
                            {/*<span>
                                    <a href="/help">Help Board</a>
                                    <a href="/payments">Payments</a>
                                    <a href="/about">About Us</a>
                                    <a href="/terms">Terms and Conditions</a>
                                </span>*/}
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

const mapStateToProps = state => {
    return {
        authorized: state.auth.get("authorized"),
        checked: state.auth.get("checked"),
        user: state.auth.get("user"),
        theme: state.auth.get("theme"),
        error: state.auth.get("error"),
        loading: state.loading,
        page: state.page,
        stack: state.auth.get("stack"),
    }
}

export default connect(mapStateToProps)(Auth)
