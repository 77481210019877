import React from "react"
import {Table} from "react-bootstrap"
import DidReportRow from "./DidReportRow"
import Th from "../../Th"
import {formatMoney} from "../../tools"

class DidReport extends React.PureComponent {
    state = {}

    onCheck = id => {
        let checked = this.props.checked
        if (checked.includes(id)) {
            checked = checked.delete(id)
        } else {
            checked = checked.add(id)
        }

        this.props.setChecked(checked)
    }

    render() {
        let totals = {}

        this.props.data.forEach(item => {
            item.forEach((v, k) => {
                if (typeof v === "number") {
                    if (totals[k] === undefined) {
                        totals[k] = 0
                    }
                    totals[k] += v
                }
            })
        })

        return (
            <Table striped>
                <thead>
                    <tr>
                        <Th field="did" sortfield={this.props.sortfield} sortdir={this.props.sortdir} onChange={this.props.onChangeSort}>
                            DID
                        </Th>
                        {!this.props.restricted && (
                            <Th
                                field="email"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                                className="d-none d-md-table-cell"
                            >
                                Email
                            </Th>
                        )}
                        <Th
                            field="minutes_in"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="right"
                        >
                            In Minutes
                        </Th>
                        <Th
                            field="price_in"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="right"
                        >
                            In Price
                        </Th>
                        <Th
                            field="minutes_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="right"
                        >
                            Out Minutes
                        </Th>
                        <Th
                            field="price_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="right"
                        >
                            Out Price
                        </Th>
                        <Th
                            field="price_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="right"
                        >
                            Total Price
                        </Th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={8}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((item, key) => (
                        <DidReportRow key={item.get("did")} row={item} restricted={this.props.restricted} onSelect={this.props.onSelect} />
                    ))}
                </tbody>
                {this.props.data.size > 0 && (
                    <tfoot>
                        <tr>
                            <td>TOTAL</td>
                            {!this.props.restricted && <td className="d-none d-md-table-cell" />}
                            <td className="right">{totals.minutes_in}</td>
                            <td className="right">{formatMoney(totals.price_in, 2)}</td>
                            <td className="right">{totals.minutes_out}</td>
                            <td className="right">{formatMoney(totals.price_out, 2)}</td>
                            <td className="right">{formatMoney(totals.price_in + totals.price_out, 2)}</td>
                        </tr>
                    </tfoot>
                )}
            </Table>
        )
    }
}

export default DidReport
