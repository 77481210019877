import * as actions from "../actions/groups"
import * as loading from "../actions/loading"
import axios from "axios"
import config from "../config"
import {fromJS} from "immutable"
import moment from "moment"

const groupsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "GROUPS_LOAD":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}user/groups`)
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(fromJS(response.data)))
                    })
                    .catch(e => {
                        const msg = e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message
                        console.log(msg)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading DID groups list"))
                    })
                break

            case "GROUPS_REPORT_LOAD":
                store.dispatch(loading.start())

                let date1
                switch (action.range) {
                    case "today":
                        date1 = moment().startOf("day")
                        break
                    case "week":
                        date1 = moment().startOf("isoWeek")
                        break
                    default:
                        date1 = moment("2019-09-01")
                }

                axios
                    .get(`${config.apiEndpoint}groups?date1=` + encodeURIComponent(date1.format()))
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "GROUPS_CALLS_LOAD":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}group/${action.id}/calls`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "GROUPS_UNLINK":
                store.dispatch(loading.start())
                axios
                    .post(`${config.apiEndpoint}group/${action.id}/unlink`, action.items)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "GROUPS_STATUS":
                axios
                    .get(`${config.apiEndpoint}group/${action.id}/status/${action.status}`)
                    .then(response => {
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "GROUPS_SAVE":
                const noid = action.didgroup.delete("id").toJS()
                const id = action.didgroup.get("id")
                const req = id ? axios.put(`${config.apiEndpoint}group/${id}`, noid) : axios.post(`${config.apiEndpoint}group`, noid)
                req.then(response => {
                    action.resolve(response)
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            case "GROUPS_USAGE":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}group/${action.id}/usage`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "GROUPS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}group/${action.id}`, {
                        data: action.newrouting,
                    })
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default groupsMiddleware
