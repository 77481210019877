import initialState from "./initial"
import {List} from "immutable"

const groupsReducer = (state = initialState.groups, action) => {
    if (action.type === "GROUPS_LOADING") {
        return state.set("loading", true)
    }
    if (action.type === "GROUPS_LOADERROR") {
        return state
            .set("data", List([]))
            .set("loaded", false)
            .set("loading", false)
            .set("loaderror", action.msg)
    }
    if (action.type === "GROUPS_LOADED") {
        state = state
            .set("data", action.data)
            .set("loaded", true)
            .set("loading", false)
            .set("loaderror", "")
        return state
    }
    return state
}

export default groupsReducer
