import React from "react"
import {connect} from "react-redux"
import GlobalLoadError from "./GlobalLoadError"
import * as groupsActions from "./actions/groups"
import * as contactsActions from "./actions/contacts"
import * as routingGroupsActions from "./actions/routinggroups"
import * as ivrsActions from "./actions/ivrs"
import * as recordingsActions from "./actions/recordings"
import * as botsActions from "./actions/bots"
import * as templatesActions from "./actions/templates"
import * as usersActions from "./actions/users"

const types = {
    groups: groupsActions,
    contacts: contactsActions,
    routinggroups: routingGroupsActions,
    ivrs: ivrsActions,
    recordings: recordingsActions,
    bots: botsActions,
    templates: templatesActions,
    users: usersActions,
}

const typekeys = Object.keys(types)

class Loader extends React.PureComponent {
    reload = () => {
        if (this.props.user.get("role") === "client") return
        const keys = this.props.failed.length === 0 ? typekeys : this.props.failed
        for (var type of keys) {
            if (type === "users" && this.props.user.get("role") !== "admin") continue
            this.props.dispatch(types[type].load())
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.user.get("id") !== prevProps.user.get("id")) {
            this.reload()
        }
    }

    componentDidMount = () => {
        this.reload()
    }

    render() {
        return (
            <>
                {this.props.loadErrors.length > 0 && <GlobalLoadError message={this.props.loadErrors} onReload={this.reload} />}
                {this.props.children}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        loaded: typekeys.filter(type => !state[type].get("loaded")).length === 0,
        failed: typekeys.filter(type => state[type].get("loaderror")),
        loadErrors: typekeys
            .map(type => state[type].get("loaderror"))
            .filter(err => !!err)
            .map((err, key) => <div key={key}>{err}</div>),
    }
}

export default connect(mapStateToProps)(Loader)
