export function load() {
    return {
        type: "RECORDINGS_LOAD",
    }
}

export function loading() {
    return {
        type: "RECORDINGS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "RECORDINGS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "RECORDINGS_LOADERROR",
        msg: msg,
    }
}

export function save(recording, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "RECORDINGS_SAVE",
            recording: recording,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "RECORDINGS_DELETE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}
