import React from "react"
import {connect} from "react-redux"
import {Button} from "react-bootstrap"
import {Map} from "immutable"
import {toast} from "react-toastify"

import * as actions from "../actions/smsendpoints"
import {renewSmsApiKey} from "../actions/users"
import Icon from "../Icon"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"

import SmsEndpoints from "./SmsEndpoints"
import SmsEndpointEditControlled from "./SmsEndpointEditControlled"

class SmsEndpointsControlled extends React.PureComponent {
    state = {
        data: null,
        edit: null,
        deleting: null,
    }

    newSmsEndpoint = Map({
        id: 0,
        name: "",
        url: "",
    })

    reload = () => {
        actions
            .load(this.props.dispatch)
            .then(data => this.setState({data: data.filter(ep => ep.get("userid") !== 0)}))
            .catch(e => {
                console.log(e)
                toast.error("Cannot load SMS endpoints", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onTest = endpoint => {
        actions
            .test(endpoint.get("id"), this.props.dispatch)
            .then(resp => alert(resp))
            .catch(e => {
                console.log(e)
                toast.error("Cannot send test message", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onEdit = smsendpoint => {
        this.setState({
            edit: smsendpoint,
        })
    }

    onClose = reload => {
        if (reload === true) {
            this.reload()
        }
        this.setState({edit: null})
    }

    onDelete = smsendpoint => {
        this.setState({deleting: smsendpoint})
    }

    componentDidMount = () => {
        this.reload()
    }

    onRenew = () => {
        if (!window.confirm("Once you re-generate api key your old api key will stop working immediately. Continue?")) return
        renewSmsApiKey(this.props.dispatch)
    }

    render() {
        return (
            <div>
                <Title title="SMS Endpoints" />
                <div className="actionBar">
                    <div className="float-left" style={{lineHeight: "38px"}}>
                        Your SMS API key: <strong>{this.props.user.get("smsapikey")}</strong>{" "}
                        <Button variant="link" style={{paddingTop: "2px"}} onClick={this.onRenew}>
                            Renew
                        </Button>
                    </div>
                    <div className="float-right">
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newSmsEndpoint)}>
                            <Icon icon="plus" /> Add SMS Endpoint
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.state.data !== null && (
                    <SmsEndpoints
                        data={this.state.data}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                        onReload={this.reload}
                        onTest={this.onTest}
                    />
                )}
                {this.state.edit !== null && <SmsEndpointEditControlled smsendpoint={this.state.edit} onClose={this.onClose} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(SmsEndpointsControlled)
