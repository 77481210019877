import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
//import "./theme.min.css"
import App from "./App"
import {unregister} from "./serviceWorker"
import {store, history} from "./data/store"
import {Provider} from "react-redux"
import {ConnectedRouter} from "connected-react-router"

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root"),
)
unregister()
