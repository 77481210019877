import React, {Component} from "react"
import {Form, FormGroup, FormControl, Card, InputGroup} from "react-bootstrap"
import SpinnerButton from "./SpinnerButton"
import Icon from "./Icon"
import logo from "./images/logo.svg"

const wrapperStyle = {
    width: "100%",
    height: "100%",
    position: "fixed",
}

const panelStyle = {
    width: "300pt",
    left: "50%",
    top: "50%",
    transform: "translateY(-50%)",
    marginLeft: "-150pt",
    position: "absolute",
}

const headerStyle = {
    textAlign: "center",
    fontSize: "130%",
    color: "#337ab7",
    marginBottom: "20pt",
    fontWeight: "bold",
}

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: props.active,
            credentials: props.credentials,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.active !== prevProps.active || this.props.credentials !== prevProps.credentials) {
            this.setState({
                active: this.props.active,
                credentials: this.props.credentials,
            })
        }
    }

    onChangeField = e => {
        this.props.onChange(this.state.credentials.set(e.target.name, e.target.value))
    }

    onLogin = e => {
        e.preventDefault()
        this.props.onLogin()
    }

    onForgot = e => {
        e.preventDefault()
    }

    callbackLinkedIn = e => {
        console.log(e)
    }

    render() {
        return (
            <div style={wrapperStyle}>
                <div style={panelStyle}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={this.onLogin}>
                                <h1 style={headerStyle}>
                                    <img src={logo} alt="Logo" />
                                </h1>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <Icon icon="user" />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            className="icon"
                                            type="text"
                                            placeholder="Email address"
                                            name="email"
                                            value={this.state.credentials.get("email")}
                                            onChange={this.onChangeField}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <Icon icon="lock" />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            className="icon"
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            value={this.state.credentials.get("password")}
                                            onChange={this.onChangeField}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <SpinnerButton
                                        type="submit"
                                        block
                                        active={this.state.active}
                                        regularText="Login"
                                        activeText="Logging in..."
                                        icon=""
                                    />
                                </FormGroup>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }
}

export default Login
