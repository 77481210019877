import React from "react"
import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from "recharts"
import moment from "moment"
import {fixTime} from "./tools"

const convert = (data, timeOffset) => {
    let out = []
    if (!data) {
        return out
    }

    if (timeOffset !== 0) {
        for (var i in data) {
            let tmp = fixTime(data[i].calldate, timeOffset)
            data[i].calldate = tmp
        }
    }

    let j = 0
    if (!data[j] || !data[j].calldate) return out
    let jt = new Date(data[j].calldate).getTime()
    for (let i = new Date(data[0].calldate).getTime(); i < new Date(data[data.length - 1].calldate).getTime(); i += 60 * 30 * 1000) {
        let v = 0
        if (jt === i) {
            v = data[j].number
            j++
            jt = new Date(data[j].calldate).getTime()
        }
        out.push({calldate: i, Calls: v})
    }
    return out
}

class CdrsStats extends React.PureComponent {
    render() {
        if (!this.props.data.length) return ""

        const data = convert(this.props.data, this.props.timeOffset)

        return (
            <ResponsiveContainer width="100%" height={280}>
                <AreaChart data={data}>
                    <defs>
                        <linearGradient id="colorCalls" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="calldate"
                        scale="time"
                        type="number"
                        domain={["auto", "auto"]}
                        tickFormatter={timeStr => moment(timeStr).format("MMM D, LT")}
                        angle={-45}
                        textAnchor="end"
                        height={85}
                    />
                    <YAxis width={40} />
                    <Area
                        type="monotone"
                        dataKey="Calls"
                        stroke="#8884d8"
                        isAnimationActive={false}
                        fillOpacity={1}
                        fill="url(#colorCalls)"
                    />
                    <Tooltip labelFormatter={v => moment(v).format("L LT")} isAnimationActive={false} />
                </AreaChart>
            </ResponsiveContainer>
        )
    }
}

export default CdrsStats
