import React from "react"
import {Form, Col, Row} from "react-bootstrap"
import GroupField from "./GroupField"
import states from "./states"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"

class DidsAdd extends React.PureComponent {
    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let data = this.props.data.set(field, value)
        if (field === "destination_type") {
            data = data.set("destination_value", "")
        }

        this.props.onChange(data)
    }

    render() {
        return (
            <Form>
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        name="dids"
                        placeholder="DIDs (line by line)"
                        value={this.props.data.get("dids")}
                        onChange={this.changeField}
                        rows="10"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        name="notes"
                        placeholder="Notes"
                        value={this.props.data.get("notes")}
                        onChange={this.changeField}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Location
                    </Form.Label>
                    <Col sm="4">
                        <Form.Control as="select" name="state" value={this.props.data.get("state")} onChange={this.changeField}>
                            {!this.props.data.get("state") && (
                                <option disabled value="">
                                    Select State
                                </option>
                            )}
                            {Object.keys(states).map(key => (
                                <option value={key} key={key}>
                                    {states[key]}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col sm="5">
                        <Form.Control name="city" value={this.props.data.get("city")} onChange={this.changeField} placeholder="City" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                        Group
                    </Form.Label>
                    <Col sm={9}>
                        <GroupField group={this.props.data.get("group")} groups={this.props.groups} onChange={this.changeField} />
                    </Col>
                </Form.Group>
                {!this.props.data.get("group") && this.props.enableRouting && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            Route To
                        </Form.Label>
                        <Col sm="4">
                            <RoutingTypeSelect
                                type="did"
                                name="destination_type"
                                value={this.props.data.get("destination_type")}
                                onChange={this.changeField}
                            />
                        </Col>
                        <Col sm="5">
                            <RoutingValueSelect
                                name="destination_value"
                                type={this.props.data.get("destination_type")}
                                value={this.props.data.get("destination_value")}
                                onChange={this.changeField}
                            />
                        </Col>
                    </Form.Group>
                )}
                {this.props.enableBots && this.props.bots.size > 0 && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            Virtual Assistant
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control as="select" name="botid" value={this.props.data.get("botid")} onChange={this.changeField}>
                                <option value="">Select Virtual Assistant</option>
                                {this.props.bots.map(bot => (
                                    <option key={bot.get("id")} value={bot.get("id")}>
                                        {bot.get("name")} ({bot.get("token")})
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                )}
            </Form>
        )
    }
}
export default DidsAdd
