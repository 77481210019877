import React from "react"
import {Table} from "react-bootstrap"
import LiveCallRow from "./LiveCallRow"
import Th from "../../Th"

class LiveCalls extends React.PureComponent {
    state = {}

    onCheck = id => {
        let checked = this.props.checked
        if (checked.includes(id)) {
            checked = checked.delete(id)
        } else {
            checked = checked.add(id)
        }

        this.props.setChecked(checked)
    }

    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th></th>
                        <th>Direction</th>
                        <Th
                            field="livecalls.did"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            DID
                        </Th>
                        <th>Caller ID</th>
                        <th>Destination</th>
                        {this.props.restricted || (
                            <Th
                                field="email"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                                className="d-none d-md-table-cell"
                            >
                                User
                            </Th>
                        )}
                        <Th
                            field="duration"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            Duration
                        </Th>
                        <Th
                            field="id"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="d-none d-md-table-cell"
                        >
                            Date/time
                        </Th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={10}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((item, key) => (
                        <LiveCallRow
                            restricted={this.props.restricted}
                            index={key}
                            id={key}
                            key={item.get("id")}
                            livecall={item}
                            delta={this.props.delta}
                            onEdit={() => this.props.onEdit(item)}
                            onDelete={() => this.props.onDelete(item)}
                            onFilter={this.props.onFilter}
                            filter={this.props.filter}
                            onCheck={this.onCheck}
                            checked={this.props.checked.contains(item.get("id"))}
                            timeOffset={this.props.timeOffset}
                        />
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default LiveCalls
