import axios from "axios"
import config from "../config"
import {fromJS} from "immutable"
import * as loading from "../actions/loading"

const smsEndpointsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "SMSENDPOINTS_LOAD":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}smsendpoints`, {withCredentials: true})
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "SMSENDPOINTS_SAVE":
                const noid = action.smsendpoint.delete("id").toJS()
                const id = action.smsendpoint.get("id")
                const req = id
                    ? axios.put(`${config.apiEndpoint}smsendpoint/${id}`, noid)
                    : axios.post(`${config.apiEndpoint}smsendpoint`, noid)
                req.then(response => {
                    action.resolve(response.data.id)
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })

                break
            case "SMSENDPOINTS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}smsendpoint/${action.id}`)
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "SMSENDPOINTS_TEST":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}smsendpoint/${action.id}/test`, {withCredentials: true})
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve("Endpoint response:\n\n" + response.data)
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default smsEndpointsMiddleware
