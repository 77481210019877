import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import BotEdit from "./BotEdit"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "./actions/bots"
import Loading from "./Loading"
import QuestionEditControlled from "./QuestionEditControlled"
import {Map, List} from "immutable"
import Icon from "./Icon"
import iconBots from "./images/menu/bots.svg"

class BotEditControlled extends Component {
    constructor(props) {
        super(props)

        this.state = {
            saving: false,
            loading: false,
            bot: props.bot,
            questions: null,
            questioneditid: null,
            questionedit: null,
            templates: props.templates,
        }
    }

    componentDidMount = () => {
        if (!this.state.bot.get("id")) return
        this.setState({loading: true})
        actions
            .questionsLoad(this.state.bot.get("id"), this.props.dispatch)
            .then(data => {
                this.setState({
                    loading: false,
                    bot: this.state.bot.set("questions", data),
                })
            })
            .catch(err => {
                this.setState({loading: false})
                toast.error("Network error, please retry", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onSaveBot = () => {
        this.setState({saving: true})
        actions
            .save(this.state.bot, this.props.dispatch)
            .then(id => {
                return actions.questionsSave(id, this.state.bot.get("questions"), this.props.dispatch)
            })
            .then(() => {
                toast.success("Virtual assistant saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
                this.props.dispatch(actions.load())
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChange = bot => {
        this.setState({bot: bot.set("questions", bot.get("questions").map((q, k) => q.set("position", k)))})
    }

    onQuestionChange = question => {
        const id = this.state.questioneditid
        let bot = this.state.bot
        let questions = bot.get("questions")
        if (id >= 0) {
            questions = questions.set(id, question)
        } else {
            questions = questions.push(question)
        }

        questions = questions.map((q, k) => q.set("position", k))

        this.setState({
            bot: bot.set("questions", questions),
            questioneditid: null,
            questionedit: null,
        })
    }

    onQuestionAdd = () => {
        this.setState({
            questioneditid: -1,
            questionedit: Map({
                id: 0,
                name: "",
                type: "text",
                status: "active",
                questions: List([]),
                retries: List([]),
                attempts: 3,
                constraints: Map({
                    min: 0,
                    max: 0,
                    target_min: 0,
                    target_max: 0,
                    regex_pattern: "",
                    accept_yes: true,
                }),
            }),
        })
    }

    onQuestionEdit = questionid => {
        this.setState({
            questioneditid: questionid,
            questionedit: this.state.bot.get("questions").get(questionid),
        })
    }

    onQuestionDelete = questionid => {
        if (!window.confirm("Delete?")) return
        let questions = this.state.bot.get("questions")
        const question = questions.get(questionid)
        if (question.get("id")) {
            questions = questions.set(questionid, question.set("status", "deleted"))
        } else {
            questions = questions.delete(questionid)
        }
        const bot = this.state.bot.set("questions", questions)
        this.setState({bot: bot})
    }

    render() {
        if (this.state.loading) return <Loading />

        if (this.state.questioneditid !== null) {
            return (
                <QuestionEditControlled
                    question={this.state.questionedit}
                    onChange={this.onQuestionChange}
                    onClose={() => this.setState({questioneditid: null})}
                    templates={this.state.templates}
                />
            )
        }

        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconBots} alt="" style={{marginRight: "20px"}} />
                            {this.state.bot.get("id") === 0 && <span>New Virtual Assistant</span>}
                            {this.state.bot.get("id") !== 0 && <span>Edit Virtual Assistant</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <BotEdit
                        bot={this.state.bot}
                        onChange={this.onChange}
                        onQuestionAdd={this.onQuestionAdd}
                        onQuestionEdit={this.onQuestionEdit}
                        onQuestionDelete={this.onQuestionDelete}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveBot} active={this.state.saving} />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect()(BotEditControlled)
