export function load() {
    return {
        type: "BOTS_LOAD",
    }
}

export function loading() {
    return {
        type: "BOTS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "BOTS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "BOTS_LOADERROR",
        msg: msg,
    }
}

export function save(bot, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BOTS_SAVE",
            bot: bot,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BOTS_DELETE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function questionsLoad(botid, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BOTS_QUESTIONS_LOAD",
            botid: botid,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function questionsSave(botid, questions, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BOTS_QUESTIONS_SAVE",
            botid: botid,
            questions: questions,
            resolve: resolve,
            reject: reject,
        })
    })
}
