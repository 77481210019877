import React, {Component} from "react"
import {connect} from "react-redux"
import Cdrs from "./Cdrs"
import {List, Map, fromJS} from "immutable"
import {Button} from "react-bootstrap"
import {toast} from "react-toastify"
import {cdrsLoad, cdrsStatsLoad, cdrsCSV} from "./actions/reports"
import Paginator from "./Paginator"
import Title from "./Title"
import * as loading from "./actions/loading"
import CdrsStats from "./CdrsStats"
import Filters from "./filters/Filters"
import ReloadIcon from "./ReloadIcon"
import iconCdrs from "./images/menu/cdrs.svg"
import moment from "moment"

const pageLength = 50

class CdrsControlled extends Component {
    constructor(props) {
        super(props)

        const role = props.user.get("role")

        this.filters =
            role !== "client"
                ? ["date1", "date2", "direction", "did", "src", "duration", "email", "group"]
                : ["date1", "date2", "did", "src", "duration"]
        this.state = {
            filter: Map({}),
            currentPage: 0,
            totalItems: 0,
            sortfield: "id",
            sortdir: "desc",
            data: List([]),
            stats: null,
            groupedModal: false,
            user: props.user,
        }
    }

    onFilter = fields => {
        this.onChangeFilter(this.state.filter.merge(fields).filter(f => !!f))
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            () => {
                this.reload()
                this.reloadStats()
            },
        )
    }

    reloadStats = () => {
        cdrsStatsLoad(this.state.filter, this.props.dispatch)
            .then(data => {
                this.setState({
                    stats: data,
                })
            })
            .catch(e => {
                toast.error("Error loading stats", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    reload = state => {
        if (!state) state = this.state
        let page = state.currentPage

        this.props.dispatch(loading.start())

        let start = pageLength * page

        cdrsLoad(this.state.filter, start, pageLength, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data, total} = r
                const totalPages = Math.ceil(total / pageLength)
                if (page > totalPages) {
                    page = totalPages
                }

                this.props.dispatch(loading.end())
                this.setState({
                    currentPage: page,
                    totalItems: total,
                    data: data,
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                })
            })
            .catch(e => {
                toast.error("Error loading cdrs", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount() {
        let filter = window.sessionStorage.getItem("cdrs-filter")
        if (filter) {
            window.sessionStorage.removeItem("cdrs-filter")
            let f = JSON.parse(filter)
            if (f.type === "today") {
                const date = new Date()
                const d = new Date(date.getFullYear(), date.getMonth(), date.getDate())
                f.date1 = d
                delete f.type
            }
            if (f.type === "week") {
                const date = moment()
                    .startOf("isoWeek")
                    .toDate()
                f.date1 = date
                delete f.type
            }

            this.onChangeFilter(this.state.filter.merge(fromJS(f)))
        } else {
            this.reload()
            this.reloadStats()
        }
    }

    onSetPage = page => {
        this.reload({
            currentPage: page,
            sortfield: this.state.sortfield,
            sortdir: this.state.sortdir,
        })
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            currentPage: 0,
            sortfield: field,
            sortdir: dir,
        })
    }

    onExportCSV = () => {
        cdrsCSV(this.state.filter, this.state.sortfield, this.state.sortdir, this.props.dispatch).then(url => {
            const link = document.createElement("a")
            link.download = "cdrs.csv"
            link.href = url
            link.onclick = () => document.body.removeChild(link)
            document.body.appendChild(link)
            link.click()
        })
    }

    render() {
        return (
            <>
                <Title title="Call Detail Records" />
                {this.state.stats && <CdrsStats data={this.state.stats} timeOffset={this.props.timeOffset} />}
                <div className="actionBar">
                    <div className="float-right">
                        <Filters icon={iconCdrs} enabled={this.filters} filter={this.state.filter} onChange={this.onChangeFilter} />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <Cdrs
                    role={this.state.user.get("role")}
                    data={this.state.data}
                    filter={this.state.filter}
                    onReload={this.reload}
                    onFilter={this.onFilter}
                    onChangeSort={this.onChangeSort}
                    sortfield={this.state.sortfield}
                    sortdir={this.state.sortdir}
                    timeOffset={this.props.timeOffset}
                />
                <div className="actionBar">
                    <div className="float-right d-none d-md-block">
                        <Button variant="secondary" className="download" onClick={this.onExportCSV}>
                            Download CSV
                        </Button>
                    </div>
                </div>
                <div className="paging">
                    {this.state.totalItems > pageLength && (
                        <Paginator
                            currentPage={this.state.currentPage}
                            totalItems={this.state.totalItems}
                            pageLength={pageLength}
                            onSetPage={this.onSetPage}
                        />
                    )}
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        timeOffset: state.auth.get("timeOffset"),
    }
}

export default connect(mapStateToProps)(CdrsControlled)
