import React, {PureComponent} from "react"
import {Button, Table, Badge} from "react-bootstrap"
import Icon from "./Icon"
import RoutingDestination from "./RoutingDestination"
import {formatTimeMS, formatMoney, noNaN} from "./tools"
import iconArrowDown from "./images/icons/arrow-down.svg"
import iconArrowUp from "./images/icons/arrow-up.svg"

class DidGroups extends PureComponent {
    status(name) {
        const title = name === "active" ? "Active" : "Disabled"
        const variant = name === "active" ? "success" : "danger"
        return <Badge variant={variant}>{title}</Badge>
    }

    render() {
        let totals = {}
        let nums = {}
        const data = this.props.data.map(item => {
            return item
                .set("avg_in", item.get("num_in") ? Math.round(item.get("duration_in") / item.get("num_in")) : 0)
                .set("avg_out", item.get("num_out") ? Math.round(item.get("duration_out") / item.get("num_out")) : 0)
        })

        data.forEach(item => {
            item.forEach((v, k) => {
                if (typeof v === "number") {
                    if (totals[k] === undefined) {
                        totals[k] = 0
                        nums[k] = 0
                    }
                    totals[k] += v
                    if (v > 0) {
                        nums[k]++
                    }
                }
            })
        })

        for (var k in totals) {
            if (k.includes("avg")) {
                totals[k] = Math.round(totals[k] / nums[k])
            }
        }

        return (
            <Table className="didgroups">
                <thead>
                    <tr>
                        <th rowSpan={2}>Name</th>
                        <th rowSpan={2}>Destination</th>
                        <th rowSpan={2}>DIDs</th>
                        <th colSpan={2}>Live Calls</th>
                        <th colSpan={2}>All Calls</th>
                        <th colSpan={2}>Unique Calls</th>
                        <th colSpan={2}>Minutes</th>
                        <th colSpan={2}>Avg. Talk Time</th>
                        <th rowSpan={2}>Price</th>
                        <th rowSpan={2}></th>
                    </tr>
                    <tr>
                        <th className="direction-in">
                            <img src={iconArrowDown} alt="In" />
                        </th>
                        <th className="direction-out">
                            <img src={iconArrowUp} alt="Out" />
                        </th>
                        <th className="direction-in">
                            <img src={iconArrowDown} alt="In" />
                        </th>
                        <th className="direction-out">
                            <img src={iconArrowUp} alt="Out" />
                        </th>
                        <th className="direction-in">
                            <img src={iconArrowDown} alt="In" />
                        </th>
                        <th className="direction-out">
                            <img src={iconArrowUp} alt="Out" />
                        </th>
                        <th className="direction-in">
                            <img src={iconArrowDown} alt="In" />
                        </th>
                        <th className="direction-out">
                            <img src={iconArrowUp} alt="Out" />
                        </th>
                        <th className="direction-in">
                            <img src={iconArrowDown} alt="In" />
                        </th>
                        <th className="direction-out">
                            <img src={iconArrowUp} alt="Out" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.length === 0 && (
                        <tr>
                            <td colSpan={15}>No Data</td>
                        </tr>
                    )}
                    {data.map((row, key) => (
                        <tr key={row.get("id")} className={`status-${row.get("status")}`}>
                            <td>{row.get("name")}</td>
                            <td>
                                {row.get("status") === "active" && (
                                    <RoutingDestination type={row.get("dest_type")} value={row.get("dest_value")} />
                                )}
                                {row.get("status") === "disabled" && (
                                    <RoutingDestination type={row.get("dest_type_disabled")} value={row.get("dest_value_disabled")} />
                                )}
                            </td>
                            <td className="direction-out">
                                <button className="button-link" onClick={() => this.props.onDids(row)}>
                                    {row.get("dids")}
                                </button>
                            </td>
                            <td className="direction-in">
                                <button className="button-link" onClick={() => this.props.onLiveCalls(row, "in")}>
                                    {row.get("livenum_in")}
                                </button>
                            </td>
                            <td className="direction-out">
                                <button className="button-link" onClick={() => this.props.onLiveCalls(row, "out")}>
                                    {row.get("livenum_out")}
                                </button>
                            </td>
                            <td className="direction-in">
                                <button className="button-link" onClick={() => this.props.onTotal(row, "in")}>
                                    {row.get("num_in")}
                                </button>
                            </td>
                            <td className="direction-out">
                                <button className="button-link" onClick={() => this.props.onTotal(row, "out")}>
                                    {row.get("num_out")}
                                </button>
                            </td>
                            <td className="direction-in">{row.get("uniquenum_in")}</td>
                            <td className="direction-out">{row.get("uniquenum_out")}</td>
                            <td className="direction-in">{Math.round(noNaN(row.get("duration_in") / 60))}</td>
                            <td className="direction-out">{Math.round(noNaN(row.get("duration_out") / 60))}</td>
                            <td className="direction-in">{formatTimeMS(row.get("avg_in"))}</td>
                            <td className="direction-out">{formatTimeMS(row.get("avg_out"))}</td>
                            <td>{formatMoney(row.get("num_price"))}</td>
                            <td>
                                <div className="float-right">
                                    {row.get("status") === "active" && (
                                        <Button
                                            size="sm"
                                            variant="info"
                                            className="btn-pause"
                                            onClick={() => this.props.onChangeStatus(row, "disabled")}
                                            title="Disable"
                                        >
                                            <Icon icon="pause" /> Off
                                        </Button>
                                    )}
                                    {row.get("status") === "disabled" && (
                                        <Button
                                            size="sm"
                                            variant="info"
                                            className="btn-play"
                                            onClick={() => this.props.onChangeStatus(row, "active")}
                                            title="Activate"
                                        >
                                            <Icon icon="play" /> On
                                        </Button>
                                    )}{" "}
                                    <Button size="sm" variant="info" onClick={() => this.props.onEdit(row)}>
                                        <Icon icon="edit" />
                                    </Button>{" "}
                                    <Button size="sm" variant="danger" onClick={() => this.props.onDelete(row)}></Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2} className="right">
                            TOTAL
                        </td>
                        <td>{totals.dids}</td>
                        <td className="direction-in">{totals.livenum_in}</td>
                        <td className="direction-out">{totals.livenum_out}</td>
                        <td className="direction-in">{totals.num_in}</td>
                        <td className="direction-out">{totals.num_out}</td>
                        <td className="direction-in">{totals.uniquenum_in}</td>
                        <td className="direction-out">{totals.uniquenum_out}</td>
                        <td className="direction-in">{Math.round(noNaN(totals.duration_in / 60))}</td>
                        <td className="direction-out">{Math.round(noNaN(totals.duration_out / 60))}</td>
                        <td className="direction-in">{formatTimeMS(totals.avg_in)}</td>
                        <td className="direction-out">{formatTimeMS(totals.avg_out)}</td>
                        <td>{formatMoney(totals.num_price)}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </Table>
        )
    }
}

export default DidGroups
