import React from "react"
import {Pagination} from "react-bootstrap"

const Paginator = props => {
    const currentPage = props.currentPage
    const totalPages = Math.ceil(props.totalItems / props.pageLength)
    const spread = 3

    if (totalPages < 2) {
        return ""
    }

    let items = []
    items.push(
        <Pagination.Item key={0} active={currentPage === 0} onClick={() => props.onSetPage(0)}>
            1
        </Pagination.Item>,
    )
    if (currentPage > spread + 1) {
        items.push(<Pagination.Ellipsis key={-1} className="nomobile" />)
    }
    for (let i = currentPage - spread; i <= currentPage + spread; i++) {
        if (i <= 0 || i >= totalPages) continue
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => props.onSetPage(i)}
                className={Math.abs(i - currentPage) > 1 ? "nomobile" : ""}
            >
                {i + 1}
            </Pagination.Item>,
        )
    }

    if (currentPage < totalPages - spread - 2) {
        items.push(<Pagination.Ellipsis key={totalPages + 1} className="nomobile" />)
    }
    if (currentPage < totalPages - spread - 1) {
        items.push(
            <Pagination.Item key={totalPages - 1} onClick={() => props.onSetPage(totalPages - 1)}>
                {totalPages}
            </Pagination.Item>,
        )
    }

    return (
        <div className="paginationContainer">
            <div className="paginationButtons">
                <Pagination>{items}</Pagination>
            </div>
        </div>
    )
}

export default Paginator
