import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {fab} from "@fortawesome/free-brands-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
    faPause,
    faPlus,
    faArrowLeft,
    faUser,
    faLock,
    faPencilAlt,
    faSyncAlt,
    faEdit,
    faTrashAlt,
    faMinus,
    faPlay,
    faStop,
    faTimes,
    faQuestion,
    faChevronCircleRight,
    faChevronCircleDown,
    faFilter,
    faBan,
    faSpinner,
    faExclamationTriangle,
    faCheckCircle,
    faSearch,
    faShoppingCart,
    faPhoneSlash,
    faPhoneAlt,
    faSms,
    faList,
    faUsers,
    faRetweet,
    faSave,
    faDollarSign,
} from "@fortawesome/free-solid-svg-icons"
import {faCcVisa, faCcMastercard, faCcDiscover, faCcDinersClub, faCcJcb, faCcAmex} from "@fortawesome/free-brands-svg-icons"

library.add(
    fab,
    faPause,
    faPlus,
    faArrowLeft,
    faUser,
    faLock,
    faPencilAlt,
    faSyncAlt,
    faEdit,
    faTrashAlt,
    faMinus,
    faPlay,
    faStop,
    faTimes,
    faQuestion,
    faChevronCircleRight,
    faChevronCircleDown,
    faFilter,
    faBan,
    faSpinner,
    faExclamationTriangle,
    faCheckCircle,
    faSearch,
    faShoppingCart,
    faPhoneSlash,
    faList,
    faUsers,
    faRetweet,
    faSave,
    faDollarSign,
    faCcVisa,
    faCcMastercard,
    faCcDiscover,
    faCcDinersClub,
    faCcJcb,
    faCcAmex,
    faPhoneAlt,
    faSms,
)

const Icon = props => {
    let style = props.style ? props.style : {}
    if (props.onClick) style.cursor = "pointer"
    let icon = props.icon
    if (props.card) {
        switch (props.card) {
            case "visa":
                icon = ["fab", "cc-visa"]
                break
            case "mastercard":
                icon = ["fab", "cc-mastercard"]
                break
            case "amex":
                icon = ["fab", "cc-amex"]
                break
            case "diners":
                icon = ["fab", "cc-diners-club"]
                break
            default:
                icon = "credit-card"
        }
    }
    return (
        <FontAwesomeIcon
            icon={icon}
            onClick={props.onClick}
            style={style}
            size={props.size}
            color={props.color}
            spin={props.spin}
            title={props.title}
        />
    )
}

export default Icon
