export function load() {
    return {
        type: "ROUTINGGROUPS_LOAD",
    }
}

export function loading() {
    return {
        type: "ROUTINGGROUPS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "ROUTINGGROUPS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "ROUTINGGROUPS_LOADERROR",
        msg: msg,
    }
}

export function get(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "ROUTINGGROUPS_GET",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function usage(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "ROUTINGGROUPS_USAGE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function unlink(id, items, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "ROUTINGGROUPS_UNLINK",
            id: id,
            items: items,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function save(routinggroup, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "ROUTINGGROUPS_SAVE",
            routinggroup: routinggroup,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, newrouting, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "ROUTINGGROUPS_DELETE",
            id: id,
            newrouting: newrouting,
            resolve: resolve,
            reject: reject,
        })
    })
}
