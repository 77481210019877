import * as actions from "../actions/templates"
import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"

const templatesMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "TEMPLATES_LOAD":
                store.dispatch(loading.start())
                store.dispatch(actions.loading())
                axios
                    .get(`${config.apiEndpoint}templates`)
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        const msg = e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message
                        console.log(msg)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading templates"))
                    })
                break

            case "TEMPLATES_DELETE":
                axios
                    .delete(`${config.apiEndpoint}templates/${action.id}`)
                    .then(() => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "TEMPLATES_SAVE":
                const template = action.template
                const noid = template.delete("id").toJS()
                const id = template.get("id")
                const req = id ? axios.put(`${config.apiEndpoint}template/${id}`, noid) : axios.post(`${config.apiEndpoint}template`, noid)
                req.then(response => {
                    action.resolve()
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            default:
                return next(action)
        }
    }
})()

export default templatesMiddleware
