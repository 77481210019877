import React, {Component} from "react"
import {Alert, Button} from "react-bootstrap"

class GlobalLoadError extends Component {
    render() {
        return (
            <>
                <div className="globalerror-backdrop"></div>
                <Alert variant="danger" className="globalerror">
                    <Alert.Heading>{this.props.message}</Alert.Heading>
                    <Button variant="outline-danger" onClick={this.props.onReload}>
                        Try Again
                    </Button>
                </Alert>
            </>
        )
    }
}

export default GlobalLoadError
