import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"
import {convertTypes} from "../tools"

const transactionsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "TRANSACTIONS_SAVE":
                const types = {
                    amount: "decimal",
                }
                const tx = convertTypes(action.transaction, types)
                const noid = tx.delete("id").toJS()
                const req = tx.has("id")
                    ? axios.put(`${config.apiEndpoint}transaction/${tx.get("id")}`, noid)
                    : axios.post(`${config.apiEndpoint}transaction`, noid)

                store.dispatch(loading.start())
                req.then(response => {
                    store.dispatch(loading.end())
                    action.resolve(response.data.id)
                }).catch(e => {
                    console.log(e)
                    store.dispatch(loading.end())
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            default:
                return next(action)
        }
    }
})()

export default transactionsMiddleware
