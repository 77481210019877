import React from "react"
import {Button} from "react-bootstrap"
import PropTypes from "prop-types"
import Icon from "./Icon"
import Spinner from "./Spinner"

class SpinnerButton extends React.PureComponent {
    render() {
        let props = Object.assign({}, this.props)
        delete props.activeText
        delete props.regularText
        const className = "save " + (this.props.active ? " active" : "")
        const text = this.props.active ? this.props.activeText : this.props.regularText
        const variant = this.props.variant ? this.props.variant : "info"
        return (
            <Button
                variant={variant}
                className={className}
                onClick={this.props.onClick}
                disabled={this.props.disabled || this.props.active}
                {...props}
            >
                {this.props.active && <Spinner />}
                {this.props.icon && !this.props.active && <Icon icon={props.icon} />} {text}
            </Button>
        )
    }
}

SpinnerButton.propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    regularText: PropTypes.node,
    activeText: PropTypes.node,
    icon: PropTypes.string,
}

SpinnerButton.defaultProps = {
    regularText: "Save",
    activeText: "Saving...",
    icon: "save",
}

export default SpinnerButton
