import React from "react"
import {Table, Button} from "react-bootstrap"
import {List} from "immutable"
import Icon from "./Icon"

class Ivrs extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            recordings: props.recordings,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.data !== prevProps.data) {
            this.setState({data: this.props.data})
        }
    }

    onCheckAll = e => {
        let checked = e.target.checked ? this.state.data.map(item => item.get("id")) : []
        this.setState({
            checked: List(checked),
        })
    }

    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th className="d-none d-md-table-cell">ID</th>
                        <th>Name</th>
                        <th>Assistant Token</th>
                        {!this.props.restricted && <th className="d-none d-md-table-cell">Creator</th>}
                        <th style={{width: "150px"}}></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data.size === 0 && (
                        <tr>
                            <td colSpan={5}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.state.data.map(row => (
                        <tr key={row.get("id")}>
                            <td className="d-none d-md-table-cell">{row.get("id")}</td>
                            <td>{row.get("name")}</td>
                            <td>{row.get("token")}</td>
                            {!this.props.restricted && <td className="d-none d-md-table-cell">{row.get("email")}</td>}
                            <td className="float-right">
                                <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                    <Icon icon="pencil-alt" /> Edit
                                </Button>{" "}
                                <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Ivrs
