import initialState from "./initial"
import {List, fromJS} from "immutable"

const jobsReducer = (state = initialState.jobs, action) => {
    if (action.type === "JOBS_LOADING") {
        return state.set("loading", true).set("loaderror", "")
    }
    if (action.type === "JOBS_LOADERROR") {
        return state
            .set("data", List([]))
            .set("loaded", false)
            .set("loading", false)
            .set("loaderror", action.msg)
    }
    if (action.type === "JOBS_LOADED") {
        state = state
            .set("data", fromJS(action.data))
            .set("loaded", true)
            .set("loading", false)
            .set("loaderror", "")
        return state
    }
    return state
}

export default jobsReducer
