import axios from "axios"
import config from "../config"

const ratecentersMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "RATECENTERS_SEARCH":
                axios
                    .get(`${config.apiEndpoint}ratecenters?state=${action.state}&ratecenter=${action.ratecenter}`)
                    .then(response => {
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break
            default:
                return next(action)
        }
    }
})()

export default ratecentersMiddleware
