import React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import "react-day-picker/lib/style.css"
import moment from "moment"

const formatDate = date => moment(date).format("YYYY-MM-DD")

const parseDate = str => {
    let m = moment(str, "YYYY-MM-DD")
    if (!m.isValid()) return
    return m.toDate()
}

const stripTime = date => {
    return moment(date)
        .subtract(12, "hour")
        .toDate()
}

const make = title => {
    return {
        title: title,
        Format: value => formatDate(value),
        Input: props => {
            return (
                <DayPickerInput
                    readonly
                    value={props.value}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={date => props.onChange(stripTime(date), formatDate(date))}
                    placeholder={title}
                />
            )
        },
    }
}

export default make
