import React from "react"
import {Table} from "react-bootstrap"
import EarningsRow from "./EarningsRow"
import Th from "../../Th"

class Earnings extends React.PureComponent {
    state = {}

    onCheck = id => {
        let checked = this.props.checked
        if (checked.includes(id)) {
            checked = checked.delete(id)
        } else {
            checked = checked.add(id)
        }

        this.props.setChecked(checked)
    }

    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <Th field="email" sortfield={this.props.sortfield} sortdir={this.props.sortdir} onChange={this.props.onChangeSort}>
                            Email
                        </Th>
                        <Th
                            field="minutes_in"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            In Minutes
                        </Th>
                        <Th
                            field="rate_in"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            In Rate
                        </Th>
                        <Th
                            field="price_in"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            In Price
                        </Th>
                        <Th
                            field="minutes_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            Out Minutes
                        </Th>
                        <Th
                            field="rate_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            Out Rate
                        </Th>
                        <Th
                            field="price_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            Out Price
                        </Th>
                        <Th
                            field="price_out"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            Total Price
                        </Th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={10}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((item, key) => (
                        <EarningsRow key={item.get("email")} row={item} onSelect={this.props.onSelect} />
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Earnings
