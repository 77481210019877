import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import RoutingGroupEdit from "./RoutingGroupEdit"
import SpinnerButton from "../SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "../actions/routinggroups"
import {isNumber} from "../tools"
import Icon from "../Icon"
import iconDids from "../images/menu/dids.svg"
import {Set} from "immutable"
import {formatTimeMS} from "../tools"
import * as validators from "../validators"

class RoutingGroupEditControlled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            routinggroup: null,
            errors: Set([]),
            checked: Set([]),
        }
    }

    componentDidMount = () => {
        this.reload()
    }

    toMS(data) {
        return data.set(
            "contacts",
            data.get("contacts").map(contact => contact.set("mindurationtext", formatTimeMS(contact.get("minduration")))),
        )
    }

    reload = () => {
        const id = this.props.routinggroup.get("id")
        if (id === 0) {
            this.setState({routinggroup: this.toMS(this.props.routinggroup)})
            return
        }

        actions
            .get(id, this.props.dispatch)
            .then(response => {
                const data = this.toMS(response)
                this.setState({routinggroup: data, errors: this.validate(data)})
            })
            .catch(error => {
                console.log(error)
                this.props.onClose(false)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onSave = () => {
        this.setState({saving: true})
        actions
            .save(this.state.routinggroup, this.props.dispatch)
            .then(() => {
                this.setState({saving: false})
                toast.success("Routing Group saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose(true)
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = routinggroup => {
        let errors = Set([])
        if (!validators.isNonEmpty(routinggroup.get("title"))) errors = errors.add("title")
        if (!validators.isNonEmpty(routinggroup.get("dest_type"))) errors = errors.add("dest_type")
        if (
            routinggroup.get("contacts").filter(c => c.get("status") === "active").size === 0 ||
            routinggroup.get("contacts").filter(c => !isNumber(c.get("contact"), true) || !isNumber(c.get("forwards"), true)).size > 0
        )
            errors = errors.add("contacts")
        return errors
    }

    onChange = routinggroup => {
        this.setState({
            routinggroup: routinggroup,
            errors: this.validate(routinggroup),
        })
    }

    onChangeChecked = checked => {
        this.setState({checked: checked})
    }

    onDelete = items => {
        actions
            .unlink(this.state.routinggroup.get("id"), items, this.props.dispatch)
            .then(response => {
                toast.success("Deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.reload()
                //this.props.onReload()
            })
            .catch(error => {
                this.setState({loaderror: error})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onDeleteOne = id => {
        if (!window.confirm("Delete the DID from the routing group?")) return
        this.onDelete([id])
    }

    onDeleteChecked = () => {
        if (!window.confirm("Delete selected DIDs from the routing group?")) return
        this.onDelete(this.state.checked.toJS())
    }

    render() {
        if (!this.state.routinggroup) return ""

        return (
            <Modal show size="lg" backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            {this.state.routinggroup.get("id") === 0 && <span>New Routing Group</span>}
                            {this.state.routinggroup.get("id") !== 0 && <span>Edit Routing Group</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <RoutingGroupEdit
                        routinggroup={this.state.routinggroup}
                        errors={this.state.errors}
                        contacts={this.props.contacts}
                        onChange={this.onChange}
                        checked={this.state.checked}
                        onChangeChecked={this.onChangeChecked}
                        onDelete={this.onDeleteOne}
                    />
                </Modal.Body>

                <Modal.Footer>
                    {!!this.state.routinggroup.get("id") && this.state.routinggroup.get("dids").size > 0 && (
                        <Button
                            variant="danger"
                            className="mr-auto"
                            disabled={this.state.checked.size === 0}
                            onClick={this.onDeleteChecked}
                        >
                            Delete
                        </Button>
                    )}
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSave} disabled={this.state.errors.size > 0} active={this.state.saving} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.contacts.get("data"),
    }
}

export default connect(mapStateToProps)(RoutingGroupEditControlled)
