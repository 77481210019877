import initialState from "./initial"
import {List, fromJS} from "immutable"

const contactsReducer = (state = initialState.contacts, action) => {
    if (action.type === "CONTACTS_LOADING") {
        return state.set("loading", true)
    }
    if (action.type === "CONTACTS_LOADERROR") {
        return state
            .set("data", List([]))
            .set("loaded", false)
            .set("loading", false)
            .set("loaderror", action.msg)
    }
    if (action.type === "CONTACTS_LOADED") {
        state = state
            .set("data", fromJS(action.data))
            .set("loaded", true)
            .set("loading", false)
            .set("loaderror", "")
        return state
    }
    return state
}

export default contactsReducer
