import React from "react"
import {Button, Badge} from "react-bootstrap"
import Icon from "../Icon"
import {Map} from "immutable"
import FiltersModal from "./FiltersModal"
import iconClear from "../images/icons/clearFilter.svg"

import MakeTextFilter from "./filterCustomText"
import MakeSubTextFilter from "./filterCustomSubText"
import MakeSubPhoneNumberFilter from "./filterCustomSubPhoneNumber"
import MakeDateFilter from "./filterCustomDate"

import Did from "./filterDid"
import MakeRadioFilter from "./filterRadio"
import State from "./filterState"
import DidGroup from "./filterDidGroup"
import Duration from "./filterDuration"
import Users from "./filterUsers"
import TransactionTypes from "./filterTransactionTypes"

class Filters extends React.Component {
    constructor(props) {
        super(props)

        const enabled = props.enabled

        let filters = {}
        if (enabled.includes("date1")) filters["date1"] = MakeDateFilter("Start Date")
        if (enabled.includes("date2")) filters["date2"] = MakeDateFilter("End Date")
        if (enabled.includes("direction")) filters["direction"] = MakeRadioFilter("Direction", {all: "All", in: "Inbound", out: "Outbound"})
        if (enabled.includes("did")) filters["did"] = Did
        if (enabled.includes("src")) filters["src"] = MakeSubPhoneNumberFilter("Caller ID")
        if (enabled.includes("status"))
            filters["status"] = MakeRadioFilter("Status", {all: "All", good: "Good", probably: "Probably", blacklisted: "Blacklisted"})
        if (enabled.includes("destination"))
            filters["destination"] = MakeRadioFilter("Destination", {all: "All", ivr: "IVR", contact: "Call Forward", dnc: "Do Not Call"})
        if (enabled.includes("state")) filters["state"] = State
        if (enabled.includes("city")) filters["city"] = MakeTextFilter("City")
        if (enabled.includes("notes")) filters["notes"] = MakeTextFilter("Notes")
        if (enabled.includes("ivr")) filters["ivr"] = MakeSubTextFilter("IVR")
        if (enabled.includes("group")) filters["group"] = DidGroup
        if (enabled.includes("duration")) filters["duration"] = Duration
        if (enabled.includes("user")) filters["user"] = MakeTextFilter("User")
        if (enabled.includes("email")) filters["email"] = MakeTextFilter("Email")
        if (enabled.includes("users")) filters["users"] = Users
        if (enabled.includes("transactiontypes")) filters["transactiontypes"] = TransactionTypes
        if (enabled.includes("transferred"))
            filters["transferred"] = MakeRadioFilter("Transferred", {all: "All", yes: "Transferred only", no: "Non-transferred only"})
        if (enabled.includes("orderedafter")) filters["orderedafter"] = MakeDateFilter("Ordered After")

        this.state = {
            show: false,
            titles: Map({}),
            filter: Map({}),
            //filter: props.filter,
            filters: filters,
        }
    }

    static getDerivedStateFromProps(props, state) {
        let filter = props.filter
        let titles = state.titles

        if (filter.size === 0) {
            return {
                filter: filter,
                titles: Map({}),
            }
        }

        for (var key in state.filters) {
            const func = state.filters[key]
            const title = func.title
            const oldval = state.filter.get(key)
            const newval = props.filter.get(key)
            const formatted = func.Format === undefined ? newval : func.Format(newval)

            if (!!formatted && oldval !== newval) {
                titles = titles.set(title, formatted)
                continue
            }

            if (oldval !== undefined && !formatted) {
                titles = titles.remove(title)
                continue
            }
        }

        return {
            filter: filter,
            titles: titles,
        }
    }

    onChange = (filter, titles) => {
        this.setState({
            show: false,
            filter: filter,
            titles: titles,
        })
        this.props.onChange(filter)
    }

    onClear = () => {
        this.props.onChange(Map({}))
    }

    onClose = () => {
        this.setState({show: false})
    }

    render() {
        const titles = this.state.titles
        return (
            <span className="filter-row">
                {titles.size > 0 && (
                    <>
                        <span className="filter-titles">
                            {titles.keySeq().map(key => (
                                <Badge variant="info" key={key}>
                                    {key}
                                    {titles.has(key) && titles.get(key).substr(0, 2) !== "  " ? ": " : ""}
                                    {titles.get(key)}
                                </Badge>
                            ))}
                        </span>
                        <Button variant="link" onClick={this.onClear}>
                            <img src={iconClear} alt="Clear" />
                        </Button>
                    </>
                )}
                <Button variant="info" onClick={() => this.setState({show: true})}>
                    <Icon icon="filter" /> Filter
                </Button>
                {this.state.show && (
                    <FiltersModal
                        icon={this.props.icon}
                        titles={this.state.titles}
                        filter={this.state.filter}
                        filters={this.state.filters}
                        onChange={this.onChange}
                        onClose={this.onClose}
                    />
                )}
            </span>
        )
    }
}

export default Filters
