import React, {Component} from "react"
import "./App.css"
import {ToastContainer} from "react-toastify"
import Auth from "./Auth"
import "react-toastify/dist/ReactToastify.css"
import Routes from "./Routes"
import axios from "axios"

class App extends Component {
    state = {
        version: "",
    }

    checkVersion = () => {
        axios.get("/version.txt?" + Math.random()).then(response => {
            const version = response.data
            if (this.state.version === "") {
                this.setState({version: version})
                return
            }
            if (version !== this.state.version) {
                console.log("New version detected, reloading in 5 seconds...")
                setTimeout(() => {
                    window.location.reload()
                }, 5000)
            }
        })
    }

    componentDidMount = () => {
        this.checkVersion()
        setInterval(this.checkVersion, 60000)
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Auth>
                    <Routes />
                </Auth>
            </>
        )
    }
}

export default App
