import React from "react"
import {Table, Form, Button} from "react-bootstrap"
import {formatPhone} from "./tools"

class DidsTable extends React.PureComponent {
    onCheck = e => {
        const id = parseInt(e.target.value, 10)
        let checked = this.props.checked
        if (checked.includes(id)) {
            checked = checked.delete(id)
        } else {
            checked = checked.add(id)
        }

        this.props.onChangeChecked(checked)
    }

    onCheckAll = e => {
        let checked = this.props.checked.clear()
        if (e.target.checked) {
            this.props.dids.forEach(item => {
                if (this.props.hideGrouped && item.get("group")) return
                checked = checked.add(item.get("id"))
            })
        }
        this.props.onChangeChecked(checked)
    }

    render() {
        return (
            <Table striped size="sm">
                <thead>
                    <tr>
                        <th>
                            <Form.Check type="checkbox" onChange={this.onCheckAll} />
                        </th>
                        <th>DID</th>
                        <th>Location</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.dids.size === 0 && (
                        <tr>
                            <td colSpan={4}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.dids.map(item => (
                        <tr key={item.get("id")}>
                            <td>
                                <Form.Check
                                    nametype="checkbox"
                                    checked={this.props.checked.includes(item.get("id"))}
                                    value={item.get("id")}
                                    onChange={this.onCheck}
                                    disabled={this.props.hideGrouped && item.get("group")}
                                />
                            </td>
                            <td>{formatPhone(item.get("did"))}</td>
                            <td>{item.get("description")}</td>
                            <td>
                                <Button
                                    variant="danger"
                                    className="float-right"
                                    onClick={() => this.props.onDelete(item.get("id"))}
                                    disabled={this.props.hideGrouped && item.get("group")}
                                    title={
                                        this.props.hideGrouped && item.get("group")
                                            ? `This DID's routing is managed by DID group “${item.get("group")}”`
                                            : ""
                                    }
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default DidsTable
