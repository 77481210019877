import * as actions from "../actions/contacts"
import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"
import {fromJS} from "immutable"

const contactsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "CONTACTS_LOAD":
                store.dispatch(loading.start())
                store.dispatch(actions.loading())
                axios
                    .get(`${config.apiEndpoint}contacts`)
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        console.log(e)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading contacts list"))
                    })

                break

            case "CONTACTS_SAVE":
                const contact = action.contact.toJS()
                const noid = action.contact.delete("id").toJS()
                const req = contact.id
                    ? axios.put(`${config.apiEndpoint}contact/${contact.id}`, noid)
                    : axios.post(`${config.apiEndpoint}contact`, noid)
                req.then(response => {
                    action.resolve(response.data.id)
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            case "CONTACTS_STATUS":
                axios
                    .get(`${config.apiEndpoint}contact/${action.id}/status/${action.status}`)
                    .then(() => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "CONTACTS_USAGE":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}contact/${action.id}/usage`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "CONTACTS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}contact/${action.id}`, {
                        data: action.newrouting,
                    })
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default contactsMiddleware
