import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import DidsAdd from "./DidsAdd"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as groupsActions from "./actions/groups"
import * as actions from "./actions/dids"
import iconDids from "./images/menu/dids.svg"
import Icon from "./Icon"
import {Map} from "immutable"

class DidsAddControlled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({
                dids: "",
                notes: "",
                destination_type: "",
                destination_value: "",
                state: "",
                city: "",
                group: "",
                botid: "",
            }),
            valid: false,
            saving: false,

            enableRouting: !!props.user.get("opt_ivr_enabled"),
            enableBots: !!props.user.get("opt_bots_enabled"),
        }
    }

    validate = data => {
        let valid = true
        if (!data.get("dids").trim()) {
            valid = false
        }
        if (data.get("destination_type") === "ivr" && !data.get("destination_value").match(/^[0-9]+$/)) {
            valid = false
        }
        if (data.get("destination_type") === "contact" && !data.get("destination_value").match(/^[0-9]+$/)) {
            valid = false
        }
        if (data.get("destination_type") === "routinggroup" && !data.get("destination_value").match(/^[0-9]+$/)) {
            valid = false
        }
        return valid
    }

    onSaveDids = () => {
        this.setState({saving: true})
        actions
            .bulkadd(this.state.data, this.props.dispatch)
            .then(r => {
                this.props.dispatch(actions.load())
                this.props.dispatch(groupsActions.load())
                toast.success("DIDs added: " + r.get("affected"), {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChange = data => {
        this.setState({
            data: data,
            valid: this.validate(data),
        })
    }

    render() {
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            <span>Bulk Add DIDs</span>
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DidsAdd
                        data={this.state.data}
                        onChange={this.onChange}
                        enableRouting={this.state.enableRouting}
                        enableBots={this.state.enableBots}
                        groups={this.props.groups}
                        bots={this.props.bots}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveDids} active={this.state.saving} disabled={!this.state.valid} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        groups: state.groups.get("data"),
        bots: state.bots.get("data"),
    }
}

export default connect(mapStateToProps)(DidsAddControlled)
