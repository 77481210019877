import React from "react"
import {Table} from "react-bootstrap"
import {fixTime, formatPhone, formatMoney} from "../tools"

class Sms extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th width="250">ID</th>
                        <th width="110">From</th>
                        <th width="110">To</th>
                        <th>Body</th>
                        <th width="80">Price</th>
                        <th width="80">Status</th>
                        <th className="right" width="90">Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={7}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((row, key) => (
                        <tr key={row.get("id")}>
                            <td>{row.get("guid")}</td>
                            <td>{formatPhone(row.get("from"))}</td>
                            <td>{formatPhone(row.get("to"))}</td>
                            <td>{row.get("body")}</td>
                            <td>{formatMoney(row.get("price"))}</td>
                            <td>{row.get("status")}</td>
                            <td className="right">{fixTime(row.get("dateadded"), this.props.timeOffset).format("L LT")}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Sms
