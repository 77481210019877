import React, {Component} from "react"
import {Form, Row, Col, Alert} from "react-bootstrap"

class UserFund extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transaction: props.transaction,
        }
    }

    componentDidUpdate = nextProps => {
        if (this.props.transaction !== nextProps.transaction) {
            this.setState({
                transaction: this.props.transaction,
            })
        }
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        const transaction = this.state.transaction.set(field, value)
        this.props.onChange(transaction)
    }

    render() {
        return (
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Amount
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            name="amount"
                            placeholder="Funds Amount"
                            defaultValue={this.state.transaction.get("amount")}
                            onChange={this.changeField}
                        />
                        {parseFloat(this.state.transaction.get("amount")) < 0 && (
                            <Alert variant="info">
                                <strong>Warning!</strong> Negative amount will be deducted from user's balance
                            </Alert>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Note
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            name="note"
                            placeholder="Note (optional)"
                            defaultValue={this.state.transaction.get("note")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
            </Form>
        )
    }
}
export default UserFund
