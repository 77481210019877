const prod =
    window.location.hostname === "callerstatistics.com" ||
    window.location.hostname === "app.callerstatistics.com" ||
    window.location.hostname === "qa.callerstatistics.com"

const endpoint = (local, remote) => {
    if (window.location.host.endsWith(":3000")) return window.location.origin.replace(":3000", ":8000") + local
    return window.location.origin + remote
}

export default {
    prod: prod,
    paypalClientId: prod
        ? "AfvNsYHZ549ubpfebFuEMPs4MsC7PinHYD3VwiKRUZ6WauBB2S8VZACp4mimpeQnv0BX_zy6TYd2uNnd"
        : "AX8mP67raMhQ1ePZsH2fRLRKyCVjbRE3fZlS3Q2ybewBnuz9SMdAXLf9J1ftZL6_95_4sN2p5JXsvtdE",
    stripeApiKey: prod ? "pk_live_51CAiPPLdPkm7HTA1cHtDtwAQ4P62jVvLfvfcw0RemUbbxPlKTauDGUTP6PTPX9hKo82650aBZQnbuKa3zgPQxnJ000WKYYb3Za" : "pk_test_viSBCTftIuAvbO0dfiJHtBvp00FvmOfAKG",
    title: "Caller Statistics" + (prod ? "" : " [TEST]"),
    siteName: "CallerStatistics",
    apiEndpoint: endpoint("/", "/api/"),
    dataEndpoint: endpoint("/", "/"),
    enableIVR: window.location.host !== "bulkdids.thirdrocktelecom.ca",
    trunks: {
        1: {title: "Teleapi", features: ["voice"]},
        2: {title: "Telnyx", features: ["voice", "sms"]},
        3: {title: 'Peerless', features: ["voice"]},
        4: {title: 'VoipInnovations', features: ["voice", "sms"]},
        5: {title: 'Bandwidth', features: ["voice", "sms"]},
    },
    destinations: {
        did: {
            ivr: {value: "ivr", title: "IVR"},
            contact: {value: "contact", title: "Call Forward"},
            routinggroup: {value: "routinggroup", title: "Routing Group"},
            dnc: {value: "dnc", title: "Do Not Call(Blacklist)"},
        },
        contact: {
            ivr: {value: "ivr", title: "IVR"},
            contact: {value: "contact", title: "Call Forward"},
            hangup: {value: "hangup", title: "Hangup"},
        },
        ivr: {
            ivr: {value: "ivr", title: "IVR"},
            contact: {value: "contact", title: "Call Forward"},
            routinggroup: {value: "routinggroup", title: "Routing Group"},
            dnc: {value: "dnc", title: "Do Not Call(Blacklist)"},
            replay: {value: "replay", title: "Replay"},
        },
        group_disabled: {
            hangup: {value: "hangup", title: "Hangup"},
            contact: {value: "contact", title: "Call Forward"},
        },
        routinggroup: {
            contacts: {value: "contact", title: "Contact"},
        },
        routinggroup_default: {
            hangup: {value: "hangup", title: "Hangup"},
            contact: {value: "contact", title: "Call Forward"},
        },
    },
    entities: {
        ivr: ["IVR", "IVRs"],
        routinggroup: ["Routing Group", "Routing Groups"],
        routinggroupdefault: ["Routing Group (default)", "Routing Groups (default)"],
        contact: ["Client", "Clients"],
        did: ["DID", "DIDs"],
        group: ["DID Group", "DID Groups"],
        group_disabled: ["DID Group (disabled)", "DID Groups (disabled)"],
    },
    transactionTypes: [
        {id: "topup", label: "Top-up"},
        //{id: "call", label: "Call"},
        {id: "sms", label: "Outbound SMS"},
        {id: "smsin", label: "Inbound SMS"},
        {id: "order", label: "Purchase"},
        {id: "cancel", label: "Cancelation"},
        {id: "monthly", label: "Renewal"},
    ],
}
