export function load() {
    return {
        type: "IVRS_LOAD",
    }
}

export function loading() {
    return {
        type: "IVRS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "IVRS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "IVRS_LOADERROR",
        msg: msg,
    }
}

export function save(ivr, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "IVRS_SAVE",
            ivr: ivr,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function usage(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "IVRS_USAGE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, newrouting, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "IVRS_DELETE",
            id: id,
            newrouting: newrouting,
            resolve: resolve,
            reject: reject,
        })
    })
}
