export function load(filter, start, limit, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "JOBS_LOAD",
            filter: filter,
            start: start,
            limit: limit,
            resolve: resolve,
            reject: reject,
        })
    })
}
