import React from "react"
import {connect} from "react-redux"
import SpinnerButton from "./SpinnerButton"
import {injectStripe} from "react-stripe-elements"
import * as actions from "./actions/stripe"

class StripePayButton extends React.PureComponent {
    state = {
        active: false,
    }

    pay = () => {
        this.setState({active: true})
        actions
            .paymentintent(this.props.paymentmethodid, this.props.amount, this.props.netamount, this.props.dispatch)
            .then(clientsecret =>
                this.props.stripe.confirmCardPayment(clientsecret, {
                    payment_method: this.props.paymentmethodid,
                }),
            )
            .then(result => {
                this.setState({active: false})
                if (result.error) {
                    if (this.props.onFailure) {
                        this.props.onFailure(result.error.message)
                    }
                } else {
                    if (this.props.onSuccess) {
                        this.props.onSuccess()
                    }
                }
            })
            .catch(e => {
                this.props.onFailure(e.message)
                this.setState({active: false})
                console.log(e)
            })
    }

    render() {
        return (
            <SpinnerButton
                block
                onClick={this.pay}
                regularText={this.props.children}
                activeText="Paying..."
                active={this.state.active}
                disabled={this.props.disabled}
                icon=""
            />
        )
    }
}

export default injectStripe(connect()(StripePayButton))
