import React from "react"
import {Table} from "react-bootstrap"
import IvrLogRow from "./IvrLogRow"
import Th from "../../Th"

class IvrLogs extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <Th field="did" sortfield={this.props.sortfield} sortdir={this.props.sortdir} onChange={this.props.onChangeSort}>
                            DID
                        </Th>
                        <Th
                            field="ivrs.name"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            IVR
                        </Th>
                        {this.props.opts.map(opt => (
                            <th key={opt}>Opt #{opt}</th>
                        ))}
                        {this.props.restricted || (
                            <Th
                                field="email"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                User
                            </Th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={3}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((item, key) => (
                        <IvrLogRow
                            restricted={this.props.restricted}
                            index={key}
                            id={key}
                            key={item.get("didid") + "-" + item.get("ivr") + "-" + item.get("opt")}
                            ivrlog={item}
                            opts={this.props.opts}
                            onEdit={() => this.props.onEdit(item)}
                            onDelete={() => this.props.onDelete(item)}
                            onFilter={this.props.onFilter}
                            filter={this.props.filter}
                        />
                    ))}
                </tbody>
                <thead>
                    <tr>
                        <td colSpan={2}>Total</td>
                        {this.props.opts.map(opt => (
                            <td key={opt}>{this.props.totals.get(opt.toString())}</td>
                        ))}

                        {this.props.restricted || <td></td>}
                    </tr>
                </thead>
            </Table>
        )
    }
}

export default IvrLogs
