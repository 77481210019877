import React, {PureComponent} from "react"
import {Button, Modal} from "react-bootstrap"
import UserEdit from "./UserEdit"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "./actions/users"
import Icon from "./Icon"
import iconUsers from "./images/menu/users.svg"
import {Set} from "immutable"
import {isFloat, isSignedInt, isInt} from "./validators"

class UserEditControlled extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            user: props.user.set("password", "").map((v, k) => {
                if (k === "pricesms") return v.toFixed(4)
                if (k === "pricesmsin") return v.toFixed(4)
                if (k.startsWith("price")) return v.toFixed(2)
                if (k.startsWith("rate")) return v.toFixed(4)
                return v
            }),
            errors: Set([]),
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.user.get("apikey") !== prevProps.user.get("apikey")) {
            this.setState({user: this.state.user.set("apikey", this.props.user.get("apikey"))})
        }
    }

    onSaveUser = () => {
        this.setState({saving: true})
        actions
            .save(this.state.user, this.props.dispatch)
            .then(() => {
                toast.success("User saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
                this.props.dispatch(actions.load())
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = user => {
        const fields = [
            "ratein",
            "rateout",
            "priceorder",
            "pricecancel",
            "pricemonthly",
            "rateintf",
            "rateouttf",
            "rateoutsip",
            "priceordertf",
            "pricecanceltf",
            "pricemonthlytf",
            "pricesms",
            "pricesmsin",
            "notificationlimit",
            "suspensionlimit",
            "servicefeepaypal",
            "servicefeestripe",
            "mintopup",
        ]
        let errors = Set([])
        for (var field of fields) {
            const value = user.has(field) ? user.get(field) : ""
            if (
                field === "ratein" ||
                field === "rateout" ||
                field === "priceorder" ||
                field === "pricecancel" ||
                field === "pricemonthly" ||
                field === "rateintf" ||
                field === "rateouttf" ||
                field === "rateoutsip" ||
                field === "priceordertf" ||
                field === "pricecanceltf" ||
                field === "pricemonthlytf" ||
                field === "pricesms" ||
                field === "servicefeepaypal" ||
                field === "servicefeestripe"
            ) {
                if (!isFloat(value)) {
                    errors = errors.add(field)
                }
            }
            if (field === "notificationlimit" || field === "suspensionlimit") {
                if (!isSignedInt(value)) {
                    errors = errors.add(field)
                }
            }
            if (field === "mintopup") {
                if (!isInt(value)) {
                    errors = errors.add(field)
                }
            }
        }
        return errors
    }

    onChange = user => {
        this.setState({user: user, errors: this.validate(user)})
    }

    onRenew = () => {
        if (!window.confirm("Once you re-generate api key user's old api key will stop working immediately. Continue?")) return
        actions
            .renewApiKey(this.state.user.get("id"), this.props.dispatch)
            .then(apikey => this.setState({user: this.state.user.set("apikey", apikey)}))
    }

    render() {
        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconUsers} alt="" style={{marginRight: "20px"}} />
                            {this.state.user.get("id") === 0 && <span>New User</span>}
                            {this.state.user.get("id") !== 0 && <span>Edit User</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <UserEdit user={this.state.user} onRenewApiKey={this.onRenew} errors={this.state.errors} onChange={this.onChange} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveUser} active={this.state.saving} disabled={this.state.errors.size > 0} />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect()(UserEditControlled)
