import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import TemplateEdit from "./TemplateEdit"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import {isNumber} from "./tools"
import * as actions from "./actions/templates"
import Icon from "./Icon"
import iconBots from "./images/menu/bots.svg"

class TemplateEditControlled extends Component {
    constructor(props) {
        super(props)

        this.state = {
            saving: false,
            loading: false,
            template: props.template,
            valid: this.validate(props.template, false),
        }
    }

    onSave = () => {
        let t = this.state.template
        t = t.set("questions", t.get("questions").filter(t => !!t))
        t = t.set("retries", t.get("retries").filter(t => !!t))
        if (!this.validate(t, true)) {
            this.setState({template: t})
            return
        }

        this.setState({saving: true})
        actions
            .save(this.state.template, this.props.dispatch)
            .then(() => {
                toast.success("Template saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose(true)
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = (q, showAlert) => {
        const err = msg => {
            if (showAlert) {
                toast.error(msg, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
            return false
        }

        if (!q.get("name")) return err("Enter template name")
        if (!q.get("questions").filter(q => !!q).size) return err("Enter questions")
        if (!isNumber(q.get("attempts"))) return err("Enter attempts count")

        const c = q.get("constraints")
        if (!isNumber(c.get("min"))) return err("Enter Min constraint")
        if (!isNumber(c.get("max"))) return err("Enter Max constraint")
        if (!isNumber(c.get("target_min"))) return err("Enter Target Min constraint")
        if (!isNumber(c.get("target_max"))) return err("Enter Target Max constraint")

        if (q.get("type") === "numeric") {
            if (c.get("min") > c.get("max")) return err("Min constraint cannot be greater than Max constraint")
            if (c.get("target_min") > c.get("target_max")) return err("Target Min constraint cannot be greater than Target Max constraint")
        }

        return true
    }

    onChange = template => {
        this.setState({
            template: template,
            valid: this.validate(template),
        })
    }

    render() {
        return (
            <Modal show backdrop size="lg" animation={false}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconBots} alt="" style={{marginRight: "20px"}} />
                            {this.state.template.get("id") === 0 && <span>New Template</span>}
                            {this.state.template.get("id") !== 0 && <span>Edit Template</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <TemplateEdit template={this.state.template} onChange={this.onChange} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={() => this.props.onClose(false)} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSave} active={this.state.saving} />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect()(TemplateEditControlled)
