import React, {PureComponent} from "react"
import {Table} from "react-bootstrap"
import {formatPhone} from "./tools"
import SpinnerButton from "./SpinnerButton"

class DidsOrderSingleList extends PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Number</th>
                        <th>Location</th>
                        <th>Features</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.dids.size === 0 && (
                        <tr>
                            <td align="center" colSpan={4}>
                                No data
                            </td>
                        </tr>
                    )}
                    {this.props.dids.map((did, k) => (
                        <tr key={k}>
                            <td>{formatPhone(did.get("number"))}</td>
                            <td>
                                {(did.get("state") === "TF" && "Toll-free") || (
                                    <>
                                        {did.get("state")}, {did.get("ratecenter")}
                                    </>
                                )}
                            </td>
                            <td>{did.get("features").join(", ")}</td>
                            <td align="right" width="150px">
                                {!!did.get("ordered") && <span style={{marginRight: "30px"}}>Ordered</span>}
                                {!did.get("ordered") && (
                                    <SpinnerButton
                                        active={!!did.get("ordering")}
                                        icon="shopping-cart"
                                        variant="success"
                                        size="sm"
                                        onClick={() => this.props.onBuy(did)}
                                        regularText="Order"
                                        activeText="Ordering..."
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default DidsOrderSingleList
