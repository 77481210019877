import React from "react"
import {Table, Button} from "react-bootstrap"
import Icon from "../Icon"

class SmsEndpoints extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>URL</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={4}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((row, key) => (
                        <tr key={row.get("id")}>
                            <td>{row.get("id")}</td>
                            <td>{row.get("name")}</td>
                            <td>{row.get("url")}</td>
                            <td className="float-right">
                                <Button variant="warning" size="sm" onClick={() => this.props.onTest(row)}>
                                    <Icon icon="sms" /> Test
                                </Button>{" "}
                                <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                    <Icon icon="pencil-alt" /> Edit
                                </Button>{" "}
                                <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default SmsEndpoints
