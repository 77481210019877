import React from "react"
import {Button} from "react-bootstrap"
import {connect} from "react-redux"
import Bots from "./Bots"
import BotEditControlled from "./BotEditControlled"
import {Map, List} from "immutable"
import {toast} from "react-toastify"
import * as actions from "./actions/bots"
import Icon from "./Icon"
import ReloadIcon from "./ReloadIcon"
import Title from "./Title"

class BotsControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            editor: null,
        }

        this.newBot = Map({
            id: 0,
            name: "",
            type: "text",
            status: "active",
            questions: List([]),
        })
    }

    reload = () => {
        this.props.dispatch(actions.load())
    }

    onDelete = bot => {
        if (!window.confirm("Delete Virtual Assistant?")) return
        actions
            .del(bot.get("id"), this.props.dispatch)
            .then(() => {
                toast.success("Virtual assistant deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.reload()
            })
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onEdit = bot => {
        this.setState({editor: bot})
    }

    render() {
        return (
            <>
                <Title title="Virtual Assistants" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button
                            variant="success"
                            className="addnew"
                            onClick={() => this.onEdit(this.newBot.set("botid", this.props.user.get("id")))}
                        >
                            <Icon icon="plus" /> Add Virtual Assistant
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.props.loaded && (
                    <Bots
                        restricted={this.props.user.get("role") !== "admin"}
                        data={this.props.data}
                        recordings={this.state.recordings}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        onReload={this.reload}
                    />
                )}
                {this.state.editor && (
                    <BotEditControlled templates={this.props.templates} bot={this.state.editor} onClose={() => this.onEdit(null)} />
                )}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        data: state.bots.get("data"),
        loaded: state.bots.get("loaded"),
        templates: state.templates.get("data"),
    }
}

export default connect(mapStateToProps)(BotsControlled)
