import React from "react"
import {connect} from "react-redux"
import {Button} from "react-bootstrap"
import {Map} from "immutable"

import * as actions from "../actions/blacklist"
import Icon from "../Icon"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"
import {toast} from "react-toastify"
import iconDids from "../images/menu/dids.svg"

import Filters from "../filters/Filters"
import Blacklist from "./Blacklist"
import BlacklistEditControlled from "./BlacklistEditControlled"
import Paginator from "../Paginator"
import {delAll} from "../actions/blacklist";

const pageLength = 30

class BlacklistControlled extends React.PureComponent {
    state = {
        currentPage: 0,
        totalItems: 0,
        sortfield: "id",
        sortdir: "desc",
        data: null,
        edit: null,
        deleting: null,
        filter: Map({}),
    }

    newBlacklist = Map({
        id: 0,
        clid: "",
        did: "",
        notes: "",
        csv: null,
        mode: "list",
    })

    reload = state => {
        if (!state) state = this.state
        let page = state.currentPage
        let start = pageLength * page

        actions
            .load(this.state.filter, start, pageLength, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data, total} = r
                const totalPages = Math.ceil(total / pageLength)
                if (page > totalPages) {
                    page = totalPages
                }

                this.setState({
                    currentPage: page,
                    totalItems: total,
                    data: data,
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    componentDidMount = () => {
        this.reload()
    }

    onEdit = client => {
        this.setState({
            edit: client,
        })
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            () => {
                this.reload()
            },
        )
    }

    onSetPage = page => {
        this.reload({
            currentPage: page,
            sortfield: this.state.sortfield,
            sortdir: this.state.sortdir,
        })
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            currentPage: 0,
            sortfield: field,
            sortdir: dir,
        })
    }

    onDelete = item => {
        if (!window.confirm("Delete?")) return
        actions
            .del(item.get("id"), this.props.dispatch)
            .then(() => this.reload())
            .catch(e => {
                console.log(e)
                toast.error("Network error, please try again", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }
    onDeleteAll = ()  => {
        if (!window.confirm("Are you sure Delete ALL?")) return
        actions
            .delAll( this.props.dispatch)
            .then(() => this.reload())
            .catch(e => {
                console.log(e)
                toast.error("Network error, please try again", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onExportCSV = () => {
        actions.csv(this.state.filter, this.state.sortfield, this.state.sortdir, this.props.dispatch).then(url => {
            const link = document.createElement("a")
            link.download = "blacklist.csv"
            link.href = url
            link.onclick = () => document.body.removeChild(link)
            document.body.appendChild(link)
            link.click()
        })
    }

    render() {
        if (this.state.data === null) return ""
        return (
            <div>
                <Title title="Blacklist" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters icon={iconDids} enabled={["src"]} filter={this.state.filter} onChange={this.onChangeFilter}/>
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newBlacklist)}>
                            <Icon icon="plus" /> Add To Blacklist
                        </Button>
                        <Button variant="danger" className="deleteAll" onClick={() => this.onDeleteAll()}>
                            <Icon icon="delete" /> Delete All
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <Blacklist
                    data={this.state.data}
                    onDelete={this.onDelete}
                    onEdit={this.onEdit}
                    onReload={this.reload}
                    timeOffset={this.props.timeOffset}
                />
                <div className="actionBar">
                    <div className="float-right d-none d-md-block">
                        <Button variant="secondary" className="download" onClick={this.onExportCSV}>
                            Download CSV
                        </Button>
                    </div>
                </div>
                <div className="paging">
                    {this.state.totalItems > pageLength && (
                        <Paginator
                            currentPage={this.state.currentPage}
                            totalItems={this.state.totalItems}
                            pageLength={pageLength}
                            onSetPage={this.onSetPage}
                        />
                    )}
                </div>
                {this.state.edit !== null && (
                    <BlacklistEditControlled
                        blacklist={this.state.edit}
                        onReload={this.reload}
                        onClose={() => this.setState({edit: null})}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        timeOffset: state.auth.get("timeOffset"),
    }
}

export default connect(mapStateToProps)(BlacklistControlled)
