import * as actions from "../actions/ivrs"
import {save as saveRecording} from "../actions/recordings"
import axios from "axios"
import config from "../config"
import {fromJS, Map} from "immutable"
import * as loading from "../actions/loading"

const ivrsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "IVRS_LOAD":
                store.dispatch(loading.start())
                store.dispatch(actions.loading())
                axios
                    .get(`${config.apiEndpoint}ivrs`, {withCredentials: true})
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        const msg = e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message
                        console.log(msg)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading IVRS list"))
                    })
                break
            case "IVRS_SAVE":
                let ivr = action.ivr

                const saveIVR = (ivr, resolve, reject) => {
                    let noid = ivr.delete("id").toJS()
                    if (!noid["recording"]) delete noid["recording"]
                    if (!noid["dncrecording"]) delete noid["dncrecording"]
                    const id = ivr.get("id")
                    const req = id ? axios.put(`${config.apiEndpoint}ivr/${id}`, noid) : axios.post(`${config.apiEndpoint}ivr`, noid)
                    req.then(response => {
                        resolve()
                    }).catch(e => {
                        if (e.response && e.response.data && e.response.data.msg)
                            action.reject([e.response.data.msg, ivr.get("recording"), ivr.get("dncrecording")])
                        else reject([e.message, ivr.get("recording"), ivr.get("dncrecording")])
                    })
                }

                if (
                    (!ivr.get("recording") && ivr.get("newrecording_file")) ||
                    (!ivr.get("dncrecording") && ivr.get("dnc_newrecording_file"))
                ) {
                    ;(async function loop() {
                        let r = ["", ivr.get("recording") ? ivr.get("recording") : 0, ivr.get("dncrecording") ? ivr.get("dncrecording") : 0]

                        try {
                            if (!ivr.get("recording") && ivr.get("newrecording_file")) {
                                const response = await saveRecording(
                                    Map({
                                        userid: ivr.get("userid"),
                                        name: ivr.get("newrecording_name"),
                                        file: ivr.get("newrecording_file"),
                                    }),
                                    store.dispatch,
                                )
                                ivr = ivr
                                    .set("recording", response.id)
                                    .delete("newrecording_file")
                                    .delete("newrecording_name")
                                r[1] = response.id
                            }
                        } catch (e) {
                            r[0] = e
                            action.reject(r)
                            return
                        }

                        try {
                            if (!ivr.get("dncrecording") && ivr.get("dnc_newrecording_file")) {
                                const response = await saveRecording(
                                    Map({
                                        userid: ivr.get("userid"),
                                        name: ivr.get("dnc_newrecording_name"),
                                        file: ivr.get("dnc_newrecording_file"),
                                    }),
                                    store.dispatch,
                                )
                                ivr = ivr
                                    .set("dncrecording", response.id)
                                    .delete("dnc_newrecording_file")
                                    .delete("dnc_newrecording_name")
                                r[2] = response.id
                            }
                        } catch (e) {
                            r[0] = e
                            action.reject(r)
                            return
                        }

                        saveIVR(ivr, action.resolve, action.reject)
                    })()
                    return
                } else {
                    saveIVR(ivr, action.resolve, action.reject)
                }

                break

            case "IVRS_USAGE":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}ivr/${action.id}/usage`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "IVRS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}ivr/${action.id}`, {
                        data: action.newrouting,
                    })
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default ivrsMiddleware
