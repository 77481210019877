import React from "react"
import {library} from "@fortawesome/fontawesome-svg-core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons"

library.add(faCaretUp, faCaretDown)

const Th = props => {
    const sign =
        props.sortfield !== props.field ? (
            ""
        ) : props.sortdir === "asc" ? (
            <FontAwesomeIcon icon="caret-up" />
        ) : (
            <FontAwesomeIcon icon="caret-down" />
        )

    return (
        <th className={props.className}>
            <span onClick={() => props.onChange(props.field)} style={{cursor: "pointer"}}>
                {props.children} {sign}
            </span>
        </th>
    )
}

export default Th
