export function load(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CLIENTS_LOAD",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function save(client, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CLIENTS_SAVE",
            client: client,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CLIENTS_DELETE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}
