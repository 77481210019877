import React from "react"
import {Col, Row, Form} from "react-bootstrap"

const onChange = (dir, value, onChange) => {
    onChange(`${dir}-${value}`, parseInt(value, 10) > 0 ? "  " + (dir === "more" ? ">=" : "<=") + " " + value : "")
}

const filter = {
    title: "Duration",
    Input: props => {
        let dir = "more"
        let value = 0
        if (props.value) {
            const s = props.value.split("-")
            dir = s[0]
            value = s[1]
        }

        return (
            <Row>
                <Col sm={6}>
                    <Form.Check
                        inline
                        type="radio"
                        label="More"
                        checked={dir === "more"}
                        onChange={() => onChange("more", value, props.onChange)}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        label="Less"
                        checked={dir === "less"}
                        onChange={() => onChange("less", value, props.onChange)}
                    />
                </Col>
                <Col sm={6}>
                    <Form.Control
                        type="text"
                        placeholder="Seconds"
                        value={value}
                        onChange={e => onChange(dir, e.target.value, props.onChange)}
                    />
                </Col>
            </Row>
        )
    },
}

export default filter
