export function loadCards(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "STRIPE_LOAD_CARDS",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function remove(token, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "STRIPE_DELETE",
            token: token,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function authorize(token, amount, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "STRIPE_AUTHORIZE",
            token: token,
            amount: amount,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function paymentintent(paymentmethod, amount, netamount, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "STRIPE_PAYMENTINTENT",
            paymentmethod: paymentmethod,
            amount: amount,
            netamount: netamount,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function setupintent(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "STRIPE_SETUPINTENT",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function attachCard(token, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "STRIPE_ATTACH_CARD",
            token: token,
            resolve: resolve,
            reject: reject,
        })
    })
}
