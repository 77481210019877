import React from "react"
import {Button, Modal} from "react-bootstrap"
import DidEdit from "./DidEdit"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "./actions/dids"
import {load as loadSmsEndpoints} from "./actions/smsendpoints"
import * as groupsActions from "./actions/groups"
import iconDids from "./images/menu/dids.svg"
import Icon from "./Icon"

class DidEditControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            did: props.did,
            valid: this.validate(props.did),
            hasSms: props.did.get("features") ? props.did.get("features").includes("sms") : false,
            saving: false,
        }
    }

    validate = did => {
        let valid = true
        if (did.get("destination_type") === "ivr" && !did.get("destination_value").match(/^[0-9]+$/)) {
            valid = false
        }
        if (did.get("destination_type") === "contact" && !did.get("destination_value").match(/^[0-9]+$/)) {
            valid = false
        }
        if (did.get("destination_type") === "routinggroup" && !did.get("destination_value").match(/^[0-9]+$/)) {
            valid = false
        }
        return valid
    }

    onSaveDid = () => {
        this.setState({saving: true})
        actions
            .save(this.state.did, this.props.dispatch)
            .then(() => {
                this.props.onClose()
                toast.success("DID saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(groupsActions.load())
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChange = did => {
        this.setState({
            did: did,
            valid: this.validate(did),
        })
    }

    componentDidMount = () => {
        if (this.state.hasSms) {
            loadSmsEndpoints(this.props.dispatch)
                .then(data => this.setState({smsendpoints: data}))
                .catch(e => {
                    console.log(e)
                    toast.error("Cannot load SMS endpoints", {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    this.props.onClose()
                })
        }
    }

    render() {
        if (this.state.hasSms && !this.state.smsendpoints) return ""
        const enableRouting = !!this.props.user.get("opt_ivr_enabled")
        const enableBots = !!this.props.user.get("opt_bots_enabled")
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            <span>Edit DID</span>
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DidEdit
                        did={this.state.did}
                        ivrs={this.props.ivrs}
                        bots={this.props.bots}
                        onChange={this.onChange}
                        enableRouting={enableRouting}
                        enableBots={enableBots}
                        enableGroups={this.state.did.get("userid") === this.props.user.get("id")}
                        groups={this.props.groups}
                        routinggroups={this.props.routinggroups}
                        contacts={this.props.contacts}
                        smsendpoints={this.state.smsendpoints}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveDid} active={this.state.saving} disabled={!this.state.valid} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        contacts: state.contacts.get("data"),
        groups: state.groups.get("data"),
        routinggroups: state.routinggroups.get("data"),
        ivrs: state.groups.get("data"),
    }
}

export default connect(mapStateToProps)(DidEditControlled)
