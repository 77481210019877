import React from "react"
import {Form, Row, Col} from "react-bootstrap"

class SmsEndpointEdit extends React.PureComponent {
    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let smsendpoint = this.props.smsendpoint.set(field, value)
        this.props.onChange(smsendpoint)
    }

    render() {
        const smsendpoint = this.props.smsendpoint

        return (
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Name
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            defaultValue={smsendpoint.get("name")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        URL
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="url"
                            placeholder="URL"
                            defaultValue={smsendpoint.get("url")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
            </Form>
        )
    }
}
export default SmsEndpointEdit
