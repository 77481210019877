import axios from "axios"
import config from "../config"
import {fromJS} from "immutable"
import * as loading from "../actions/loading"

const jobsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "JOBS_LOAD":
                store.dispatch(loading.start())
                let filter = action.filter ? JSON.stringify(action.filter.filter(v => !!v).toJS()) : ""
                axios
                    .get(`${config.apiEndpoint}/jobs?filter=${encodeURIComponent(filter)}&start=${action.start}&limit=${action.limit}`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve({
                            data: fromJS(response.data),
                            total: parseInt(response.headers["x-total-count"], 10),
                        })
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break
            default:
                return next(action)
        }
    }
})()

export default jobsMiddleware
