import * as actions from "../actions/auth"
import axios from "axios"
import config from "../config"
import {fromJS, Map, Stack} from "immutable"
import moment from "moment"

const parseCookie = () => {
    return document.cookie.split(";").reduce((res, c) => {
        const [key, val] = c
            .trim()
            .split("=")
            .map(decodeURIComponent)
        try {
            return Object.assign(res, {[key]: JSON.parse(val)})
        } catch (e) {
            return Object.assign(res, {[key]: val})
        }
    }, {})
}

const authMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "AUTH_CHECK":
                let c = parseCookie()
                let token = c["authtoken"]
                if (token) {
                    axios.defaults.headers.common = {
                        "X-Token": token,
                    }
                    let stack
                    try {
                        stack = Stack(fromJS(JSON.parse(window.sessionStorage.getItem("stack"))))
                    } catch (e) {
                        stack = Stack([])
                    }
                    axios
                        .get(`${config.apiEndpoint}login`)
                        .then(response => {
                            const user = fromJS(response.data.user)
                            const timeOffset = Math.round((moment(response.data.time).unix() - moment().unix()) / 3600)
                            if (
                                !store
                                    .getState()
                                    .auth.get("user")
                                    .equals(user)
                            ) {
                                store.dispatch(actions.preAuth(user, token, stack, timeOffset === -0 ? 0 : timeOffset))
                            }
                            action.resolve(user)
                        })
                        .catch(e => {
                            if (e.response && e.response.status && e.response.status === 403) {
                                action.reject("Login required")
                            } else {
                                action.reject("Internal error")
                            }
                            store.dispatch(actions.checked())
                        })
                } else {
                    store.dispatch(actions.checked())
                    action.reject("Login required")
                }

                break

            case "AUTH_LOGIN":
                axios
                    .post(`${config.apiEndpoint}login`, {email: action.email, password: action.password}, {withCredentials: true})
                    .then(response => {
                        const user = fromJS(response.data.user)
                        const timeOffset = Math.round((moment(response.data.time).unix() - moment().unix()) / 3600)
                        store.dispatch(actions.preAuth(user, response.data.token, Stack([]), timeOffset === -0 ? 0 : timeOffset))
                        action.resolve()
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            action.reject(error.response.data.msg)
                        } else {
                            action.reject("Network error, please try again later")
                        }
                    })
                break

            case "AUTH_BALANCE":
                axios
                    .get(`${config.apiEndpoint}user/balance`, {withCredentials: true})
                    .then(response => {
                        action.resolve(response.data.balance)
                        store.dispatch(actions.balanceLoaded(response.data.balance))
                    })
                    .catch(e => {
                        console.log(e)
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "AUTH_IMPERSONATE":
                axios
                    .get(`${config.apiEndpoint}impersonate/${action.user.get("id")}`, {withCredentials: true})
                    .then(response => {
                        const auth = store.getState().auth
                        const user = fromJS(response.data.user)
                        const prevuser = auth.get("user")
                        const stack = auth.get("stack").push(
                            Map({
                                user: prevuser.get("email"),
                                token: auth.get("token"),
                            }),
                        )
                        store.dispatch(actions.preAuth(user, response.data.token, stack))
                        action.resolve()
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            action.reject("Login or password is not correct")
                        } else {
                            action.reject("Network error, please try again later")
                        }
                    })
                break

            case "AUTH_LOGOUT":
                const stack = store.getState().auth.get("stack")
                if (stack.size > 0) {
                    const item = stack.peek()
                    document.cookie = `authtoken=${encodeURIComponent(item.get("token"))};path=/`
                    window.sessionStorage.setItem("stack", JSON.stringify(stack.pop().toJS()))
                    window.location.replace("/users/")
                    return
                }
                document.cookie = "authtoken=;path=/"
                axios.defaults.headers.common = {
                    "X-Token": "",
                }
                store.dispatch(actions.postLogout())
                break

            case "AUTH_PRE_AUTHORIZED":
                document.cookie = `authtoken=${encodeURIComponent(action.token)};path=/`
                axios.defaults.headers.common = {
                    "X-Token": action.token,
                }
                window.sessionStorage.setItem("stack", JSON.stringify(action.stack.toJS()))
                let theme = window.localStorage.getItem("theme")
                if (theme !== "dark") theme = "light"
                store.dispatch(actions.authorized(action.user, action.token, action.stack, action.timeOffset, theme))
                break

            case "AUTH_COLOR_THEME":
                window.localStorage.setItem("theme", action.theme)
                return next(action)

            default:
                return next(action)
        }
    }
})()

export default authMiddleware
