import React from "react"
import {Nav} from "react-bootstrap"

const Dropdown = props => {
    return (
        <div>
            <Nav.Link onClick={props.onChange}>
                <img src={props.icon} alt="" /> {props.title}
            </Nav.Link>
            {props.expanded && <div className="dropdown">{props.children}</div>}
        </div>
    )
}

export default Dropdown
