import React from "react"
import {connect} from "react-redux"
import {Map} from "immutable"

import {transactionsLoad} from "../actions/reports"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"
import {toast} from "react-toastify"
import iconDids from "../images/menu/dids.svg"

import Filters from "../filters/Filters"
import Transactions from "./Transactions"
import Paginator from "../Paginator"

const pageLength = 30

class TransactionsControlled extends React.PureComponent {
    state = {
        currentPage: 0,
        totalItems: 0,
        sortfield: "id",
        sortdir: "desc",
        data: null,
        filter: Map({}),
    }

    newTransactions = Map({
        id: 0,
        clid: "",
        did: "",
        notes: "",
        csv: null,
        mode: "list",
    })

    reload = state => {
        if (!state) state = this.state
        let page = state.currentPage
        let start = pageLength * page

        transactionsLoad(this.state.filter, start, pageLength, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data, total} = r
                const totalPages = Math.ceil(total / pageLength)
                if (page > totalPages) {
                    page = totalPages
                }

                this.setState({
                    currentPage: page,
                    totalItems: total,
                    data: data,
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    componentDidMount = () => {
        this.reload()
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            () => {
                this.reload()
            },
        )
    }

    onSetPage = page => {
        this.reload({
            currentPage: page,
            sortfield: this.state.sortfield,
            sortdir: this.state.sortdir,
        })
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            currentPage: 0,
            sortfield: field,
            sortdir: dir,
        })
    }

    render() {
        if (this.state.data === null) return ""
        return (
            <div>
                <Title title="Transactions" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters
                            icon={iconDids}
                            enabled={
                                this.props.restricted
                                    ? ["date1", "date2", "transactiontypes"]
                                    : ["date1", "date2", "transactiontypes", "users"]
                            }
                            filter={this.state.filter}
                            onChange={this.onChangeFilter}
                        />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <Transactions restricted={this.props.restricted} data={this.state.data} timeOffset={this.props.timeOffset} />

                <div className="paging">
                    {this.state.totalItems > pageLength && (
                        <Paginator
                            currentPage={this.state.currentPage}
                            totalItems={this.state.totalItems}
                            pageLength={pageLength}
                            onSetPage={this.onSetPage}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        timeOffset: state.auth.get("timeOffset"),
        restricted: state.auth.get("user").get("role") !== "admin",
    }
}

export default connect(mapStateToProps)(TransactionsControlled)
