import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import ClientEdit from "./ClientEdit"
import SpinnerButton from "../SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "../actions/clients"
import Icon from "../Icon"
import iconUsers from "../images/menu/users.svg"
import {Map, Set} from "immutable"
import ContactEditControlled from "../contacts/ContactEditControlled"

class ClientEditControlled extends Component {
    newContact = Map({
        id: 0,
        title: "",
        number: "",
        contactuserid: null,
        maxcalls: 0,
        password: "<hidden>",
        dest_type: "hangup",
        siptrunk: "",
        mode: "did",
    })

    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            contacts: props.contacts,
            client: props.client.set("password", "").set(
                "contacts",
                props.contacts.filter(contact => contact.get("clientuserid") === props.client.get("id")),
            ),
            errors: Set([]),
            editContact: null,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.contacts !== prevProps.contacts) {
            this.setState({
                contacts: this.props.contacts,
                client: this.state.client.set(
                    "contacts",
                    this.props.contacts.filter(contact => contact.get("clientuserid") === this.props.client.get("id")),
                ),
            })
        }
    }

    onSaveClient = () => {
        this.setState({saving: true})
        actions
            .save(this.state.client, this.props.dispatch)
            .then(() => {
                toast.success("Client saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose(true)
            })
            .catch(error => {
                console.log(error)
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = client => {
        return Set([])
    }

    onChange = client => {
        this.setState({client: client, errors: this.validate(client)})
    }

    onEditContact = contact => {
        this.setState({
            editContact: contact ? contact : this.newContact.set("clientuserid", this.state.client.get("id")),
        })
    }

    render() {
        if (this.state.editContact !== null)
            return (
                <ContactEditControlled contact={this.state.editContact} onClose={() => this.setState({editContact: null})} nochangeclient />
            )

        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconUsers} alt="" style={{marginRight: "20px"}} />
                            {this.state.client.get("id") === 0 && <span>New Client</span>}
                            {this.state.client.get("id") !== 0 && <span>Edit Client</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ClientEdit
                        client={this.state.client}
                        errors={this.state.errors}
                        onChange={this.onChange}
                        onEditContact={this.onEditContact}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveClient} active={this.state.saving} disabled={this.state.errors.size > 0} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.contacts.get("data"),
    }
}

export default connect(mapStateToProps)(ClientEditControlled)
