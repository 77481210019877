import React, {Component} from "react"
import {Form, Row, Col} from "react-bootstrap"
import {Map, List} from "immutable"
import TemplateTypes from "./data/QuestionTypes"

class TemplateEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            template: props.template,
        }

        this.qtypes = Map(TemplateTypes)
    }

    componentDidUpdate = prevProps => {
        if (this.props.template !== prevProps.template) {
            this.setState({
                template: this.props.template,
            })
        }
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeConstraintsField = e => {
        this.changeConstraintsFieldValue(e.target.name, e.target.value)
    }

    changeConstraintsFieldValue = (name, value) => {
        if (["min", "max", "target_min", "target_max"].includes(name)) {
            value = parseInt(value, 10)
            if (isNaN(value)) value = ""
        }
        this.changeFieldValue("constraints", this.state.template.get("constraints").set(name, value))
    }

    changeFieldValue = (field, value) => {
        if (["attempts"].includes(field)) {
            value = parseInt(value, 10)
            if (isNaN(value)) value = ""
        }
        if (["questions", "retries"].includes(field) && typeof value === "string") {
            value = List(value.split("\n"))
        }
        const template = this.state.template.set(field, value)
        this.props.onChange(template)
    }

    onBlur = e => {
        const v = this.state.template
            .get(e.target.name)
            .map(v =>
                v
                    .trim()
                    .replace(/^"/, "")
                    .replace(/",*$/, ""),
            )
            .filter(v => !!v)
        this.changeFieldValue(e.target.name, v)
    }

    render() {
        return (
            <Form>
                {!this.state.template.get("id") && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Type
                        </Form.Label>
                        <Col sm="10">
                            {this.qtypes.keySeq().map(k => (
                                <Form.Check
                                    inline
                                    key={k}
                                    name="type"
                                    value={k}
                                    type="radio"
                                    label={this.qtypes.get(k)}
                                    onChange={this.changeField}
                                    checked={this.state.template.get("type") === k}
                                />
                            ))}
                        </Col>
                    </Form.Group>
                )}
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Name
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Template Name"
                            value={this.state.template.get("name")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Templates
                        <br />
                        <sub>line by line</sub>
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            as="textarea"
                            name="questions"
                            placeholder="Questions"
                            value={this.state.template.get("questions").join("\n")}
                            rows={this.state.template.get("questions").size + 1}
                            onChange={this.changeField}
                            onBlur={this.onBlur}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Retries
                        <br />
                        <sub>line by line</sub>
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            as="textarea"
                            name="retries"
                            placeholder="Retries"
                            value={this.state.template.get("retries").join("\n")}
                            rows={this.state.template.get("retries").size + 1}
                            onChange={this.changeField}
                            onBlur={this.onBlur}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Attempts
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="attempts"
                            placeholder="Attempts"
                            value={this.state.template.get("attempts")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                {this.state.template.get("type") === "numeric" && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Constraints
                            </Form.Label>
                            <Col sm="10">
                                <Row>
                                    <Col sm="3">
                                        <Form.Group>
                                            <Form.Label>Min</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="min"
                                                placeholder="Min"
                                                value={this.state.template.get("constraints").get("min")}
                                                title="The lowest possible accepted value. If lower than this, the user will be reprompted"
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group>
                                            <Form.Label>Max</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="max"
                                                placeholder="Max"
                                                value={this.state.template.get("constraints").get("max")}
                                                title="The greatest possible accepted value. If greater than this, the user will be reprompted"
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group>
                                            <Form.Label>Target Min</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="target_min"
                                                placeholder="Target Min"
                                                value={this.state.template.get("constraints").get("target_min")}
                                                title="The lowest value in the target range of the template. If lower than this, the template will be marked as failed."
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group>
                                            <Form.Label>Target Max</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="target_max"
                                                placeholder="Target Max"
                                                value={this.state.template.get("constraints").get("target_max")}
                                                title="The greatest value that answers the template correctly. If greater than this, the template will be marked as failed."
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}

                {this.state.template.get("type") === "text" && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Constraints
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="regex_pattern"
                                    placeholder="Reg-ex pattern"
                                    value={this.state.template.get("constraints").get("regex_pattern")}
                                    title="The reg-ex pattern used to validate the value. If the input does not match this pattern, than the user will be reprompted"
                                    onChange={this.changeConstraintsField}
                                />
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}

                {this.state.template.get("type") === "yesno" && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Constraints
                            </Form.Label>
                            <Col sm="10">
                                <Form.Check
                                    inline
                                    name="accept_yes"
                                    label="YES considered as valid response"
                                    type="checkbox"
                                    checked={this.state.template.get("constraints").get("accept_yes")}
                                    onChange={e => this.changeConstraintsFieldValue(e.target.name, e.target.checked)}
                                />
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}
            </Form>
        )
    }
}
export default TemplateEdit
