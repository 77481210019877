import React, {Component} from "react"
import {Button} from "react-bootstrap"
import {connect} from "react-redux"
import RoutingGroups from "./RoutingGroups"
import RoutingGroupEditControlled from "./RoutingGroupEditControlled"
import {Map, List} from "immutable"
import * as actions from "../actions/routinggroups"
import Icon from "../Icon"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"
import DeleteModal from "../DeleteModal"

class RoutingGroupsControlled extends Component {
    state = {
        editor: null,
    }

    newRoutingGroup = Map({
        id: 0,
        userid: 0,
        title: "",
        type: "roundrobin",
        dest_type: "hangup",
        status: "active",
        contacts: List([]),
    })

    reload = () => {
        this.props.dispatch(actions.load())
    }

    onDelete = item => {
        this.setState({deleting: item})
    }

    onCloseDelete = reload => {
        this.setState({deleting: null})
    }

    onEdit = routinggroup => {
        this.setState({editor: routinggroup})
    }

    onCloseEdit = reload => {
        this.setState({editor: null})
        if (reload === true) {
            this.reload()
        }
    }

    render() {
        return (
            <div>
                <Title title="Routing Groups" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newRoutingGroup)}>
                            <Icon icon="plus" /> Add Routing Group
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.props.loaded && (
                    <RoutingGroups
                        restricted={this.props.user.get("role") !== "admin"}
                        data={this.props.data}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        onReload={this.reload}
                    />
                )}
                {this.state.editor && <RoutingGroupEditControlled routinggroup={this.state.editor} onClose={this.onCloseEdit} />}
                {this.state.deleting && (
                    <DeleteModal
                        id={this.state.deleting.get("id")}
                        title={this.state.deleting.get("title")}
                        type="routinggroup"
                        onClose={this.onCloseDelete}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        data: state.routinggroups.get("data"),
        loaded: state.routinggroups.get("loaded"),
    }
}

export default connect(mapStateToProps)(RoutingGroupsControlled)
