import initialState from "./initial"
import {Set, List, fromJS} from "immutable"

const usersReducer = (state = initialState.users, action) => {
    if (action.type === "USERS_LOADING") {
        return state.set("loading", true).set("loaderror", "")
    }
    if (action.type === "USERS_LOADERROR") {
        return state
            .set("data", List([]))
            .set("loaded", false)
            .set("loading", false)
            .set("loaderror", action.msg)
    }
    if (action.type === "USERS_LOADED") {
        state = state
            .set("data", fromJS(action.data).map(u => u.set("trunks", Set(u.get("trunks")))))
            .set("loaded", true)
            .set("loading", false)
            .set("loaderror", "")
        return state
    }
    return state
}

export default usersReducer
