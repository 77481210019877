const prepare = value => {
    if (value === undefined) return ""
    if (typeof value !== "string") return value.toString()
    return value
}

export const isFloat = value => {
    return prepare(value).match(/^\d+(\.\d+){0,1}$/)
}

export const isSignedFloat = value => {
    return prepare(value).match(/^-{0,1}\d+(\.\d+){0,1}$/)
}

export const isSignedInt = value => {
    return prepare(value).match(/^-{0,1}\d+$/)
}

export const isInt = value => {
    return prepare(value).match(/^\d+$/)
}

export const isNonEmpty = value => {
    return prepare(value) !== ""
}
