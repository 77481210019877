import React, {Component} from "react"
import {connect} from "react-redux"
import DidReport from "./DidReport"
import {List, Map, Set} from "immutable"
import {toast} from "react-toastify"
import {didsReportLoad} from "../../actions/reports"
import * as loading from "../../actions/loading"
import Title from "../../Title"
import Filters from "../../filters/Filters"
import ReloadIcon from "../../ReloadIcon"
import iconEarnings from "../../images/menu/livecalls.svg"
import moment from "moment"
import {push} from "connected-react-router"

class DidReportControlled extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: Map({
                date1: moment()
                    .startOf("isoWeek")
                    .toDate(),
            }),
            currentPage: 0,
            totalItems: 0,
            sortfield: "did",
            sortdir: "asc",
            data: List([]),
            checked: Set([]),
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            groups: props.groups,
        }
    }

    onFilter = fields => {
        this.onChangeFilter(this.state.filter.merge(fields))
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            this.reload,
        )
    }

    saveFilter = did => {
        const filter = {
            did: did,
        }
        window.sessionStorage.setItem("cdrs-filter", JSON.stringify(filter))
    }

    onSelect = did => {
        this.saveFilter(did)
        this.props.dispatch(push(`/cdrs/`))
    }

    reload = state => {
        if (!state) state = this.state

        this.props.dispatch(loading.start())

        didsReportLoad(this.state.filter, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data} = r
                this.props.dispatch(loading.end())

                this.setState({
                    data: data,
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                })
            })
            .catch(e => {
                toast.error("Error loading report data", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount = () => {
        this.reload()
    }

    onSetPage = page => {
        this.reload({
            sortfield: this.state.sortfield,
            sortdir: this.state.sortdir,
        })
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            sortfield: field,
            sortdir: dir,
        })
    }

    render() {
        const restricted = this.props.user.get("role") !== "admin"
        const filters = this.props.user.get("role") === "admin" ? ["date1", "date2", "users"] : ["date1", "date2"]

        return (
            <>
                <Title title="DIDs Report" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters
                            icon={iconEarnings}
                            enabled={filters}
                            filter={this.state.filter}
                            onChange={this.onChangeFilter}
                            users={this.props.users}
                        />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <DidReport
                    restricted={restricted}
                    data={this.state.data}
                    onChangeSort={this.onChangeSort}
                    sortfield={this.state.sortfield}
                    sortdir={this.state.sortdir}
                    onSelect={this.onSelect}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        users: state.users.get("data"),
        timeOffset: state.auth.get("timeOffset"),
        groups: state.groups,
    }
}

export default connect(mapStateToProps)(DidReportControlled)
