import React from "react"
import {Table, Button} from "react-bootstrap"
import Icon from "../Icon"
import {formatPhone} from "../tools"

class Clients extends React.PureComponent {
    numbers = id => {
        return this.props.contacts
            .filter(contact => contact.get("clientuserid") === id)
            .map(contact => (
                <div key={contact.get("id")}>
                    <div>
                        {contact.get("title")} - {contact.get("mode") === "did" && formatPhone(contact.get("number"))}
                        {contact.get("mode") === "sip" && contact.get("siptrunk")}
                    </div>
                </div>
            ))
    }

    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th className="d-none d-md-table-cell">Email</th>
                        <th>Name</th>
                        <th>Phone Numbers</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={6}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((row, key) => (
                        <tr key={row.get("id")}>
                            <td>{row.get("email")}</td>
                            <td>{row.get("contactname")}</td>
                            <td>{this.numbers(row.get("id"))}</td>
                            <td className="float-right">
                                <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                    <Icon icon="pencil-alt" /> Edit
                                </Button>{" "}
                                <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Clients
