import React from "react"
import {connect} from "react-redux"
import Users from "./Users"
import {Map, Set} from "immutable"
import LoadError from "./LoadError"
import {toast} from "react-toastify"
import Title from "./Title"
import * as actions from "./actions/users"
import {Button} from "react-bootstrap"
import ReloadIcon from "./ReloadIcon"
import Icon from "./Icon"
import UserEditControlled from "./UserEditControlled"
import UserFundControlled from "./UserFundControlled"
import {impersonate} from "./actions/auth"
import {replace} from "connected-react-router"

class UsersControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            edit: null,
            fund: null,
        }

        this.newUser = Map({
            id: 0,
            role: "user",
            clienttype: "statistics",
            opt_blacklist_check: "disabled",
            opt_ivr_enabled: 0,
            opt_sms_enabled: 0,
            billinginc: 60,
            billinginctf: 60,
            billingincsip: 60,
            ratein: 0,
            rateout: 0,
            priceorder: 0,
            pricecancel: 0,
            pricemonthly: 0,
            pricesms: 0,
            pricesmsin: 0,
            rateintf: 0,
            rateouttf: 0,
            rateoutsip: 0,
            priceordertf: 0,
            pricecanceltf: 0,
            pricemonthlytf: 0,
            notificationlimit: 0,
            suspensionlimit: 0,
            trunks: Set([1]),
        })
    }

    reload = () => {
        this.props.dispatch(actions.load())
    }

    componentDidMount() {
        this.reload()
    }

    onEdit = user => {
        this.setState({
            edit: user,
        })
    }

    onFund = user => {
        this.setState({
            fund: user,
        })
    }

    onDelete = user => {
        if (!window.confirm("Delete user?")) {
            return
        }
        this.props.onDelete(user)
    }

    onDelete = user => {
        if (!window.confirm("Delete user?")) return
        actions
            .del(user, this.props.dispatch)
            .then(() => {
                toast.success("User deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(actions.load())
            })
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onImpersonate = user => {
        if (!window.confirm("Login?")) return
        impersonate(user, this.props.dispatch)
            .then(() => {
                this.props.dispatch(replace("/"))
            })
            .catch(error => {
                this.setState({active: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    render() {
        return (
            <div>
                <Title title="Users" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newUser)}>
                            <Icon icon="plus" /> Add User
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.props.loaded && (
                    <Users
                        data={this.props.data}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                        onFund={this.onFund}
                        user={this.props.user}
                        onImpersonate={this.onImpersonate}
                        onReload={this.reload}
                    />
                )}
                {this.props.loaderror && <LoadError message={this.props.loaderror} onReload={this.reload} />}
                {this.state.edit !== null && <UserEditControlled user={this.state.edit} onClose={() => this.setState({edit: null})} />}
                {this.state.fund !== null && <UserFundControlled user={this.state.fund} onClose={() => this.setState({fund: null})} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loaded: state.users.get("loaded"),
        loaderror: state.users.get("loaderror"),
        data: state.users.get("data"),
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(UsersControlled)
