import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import UserFund from "./UserFund"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "./actions/transactions"
import {load as usersLoad} from "./actions/users"
import Icon from "./Icon"
import iconUsers from "./images/menu/users.svg"
import {Map, Set} from "immutable"
import {isSignedFloat} from "./validators"

class UserFundControlled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            transaction: Map({
                userid: props.user.get("id"),
                amount: 0,
                type: "topup",
                note: "",
            }),
            errors: Set([]),
        }
    }

    onSave = () => {
        this.setState({saving: true})
        actions
            .save(this.state.transaction.set("data", Map({note: this.state.transaction.get("note")})), this.props.dispatch)
            .then(() => {
                toast.success("Funds Added", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
                this.props.dispatch(usersLoad())
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = transaction => {
        const fields = ["amount"]
        let errors = Set([])
        for (var field of fields) {
            const value = transaction.has(field) ? transaction.get(field) : ""
            if (field === "amount") {
                if (!isSignedFloat(value)) {
                    errors = errors.add(field)
                }
            }
        }
        return errors
    }

    onChange = transaction => {
        this.setState({transaction: transaction, errors: this.validate(transaction)})
    }

    render() {
        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconUsers} alt="" style={{marginRight: "20px"}} />
                            <span>Add Funds</span>
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <UserFund transaction={this.state.transaction} errors={this.state.errors} onChange={this.onChange} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton
                        onClick={this.onSave}
                        active={this.state.saving}
                        disabled={this.state.errors.size > 0}
                        regularText="Add"
                        activeText="Adding..."
                        icon="dollar-sign"
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect()(UserFundControlled)
