import React, {PureComponent} from "react"
import {connect} from "react-redux"
import Dids from "./Dids"
import LoadError from "./LoadError"
import Loading from "./Loading"
import Filters from "./filters/Filters"
import {toast} from "react-toastify"
import * as actions from "./actions/dids"
import * as groupsActions from "./actions/groups"
import DidsOrder from "./DidsOrder"
import DidsOrderConfirm from "./DidsOrderConfirm"
import DidEditControlled from "./DidEditControlled"
import DidGroupEditControlled from "./DidGroupEditControlled"
import DidsAddControlled from "./DidsAddControlled"
import {Button, Col, Row} from "react-bootstrap"
import Paginator from "./Paginator"
import {replace} from "connected-react-router"
import {Set, fromJS} from "immutable"
import DidsChangeRoutingModal from "./DidsChangeRoutingModal"
import Icon from "./Icon"
import DidsChangeTagsModal from "./DidsChangeTagsModal"
import DidsTransferModal from "./DidsTransferModal"
import ReloadIcon from "./ReloadIcon"
import iconDids from "./images/menu/dids.svg"

class DidsControlled extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            buying: false,
            bulkadd: false,
            edit: null,
            editGroup: null,
            deleting: false,
            confirmModal: null,
            changeRoutingModal: false,
            transferModal: false,
            confirmData: null,
            checked: Set([]),
        }
    }

    reload = () => {
        this.props.dispatch(actions.load())
    }

    componentDidUpdate = prevProps => {
        if (this.props.filter !== prevProps.filter) {
            this.reload()
        }
    }

    hasTelnyx = () => {
        const user = this.props.user
        if (!user.get("trunks")) return false
        for (let id of user.get("trunks")) {
            if (id === 2) return true
        }
        return false
    }
    
    componentDidMount() {
        let filter = window.sessionStorage.getItem("dids-filter")
        if (filter) {
            window.sessionStorage.removeItem("dids-filter")
            let f = JSON.parse(filter)
            this.onChangeFilter(this.props.filter.merge(fromJS(f)))
        } else {
            this.reload()
        }
    }

    onBuyForm = () => {
/*
        if (this.hasTelnyx()) {
            alert("Sorry, DIDs ordering is temporary unavailable")
            return
        }
*/
        if (this.props.balance <= this.props.user.get("suspensionlimit")) {
            toast.error("You do not have enough funds. Please top up", {
                position: toast.POSITION.TOP_RIGHT,
            })
            return
        }
        this.setState({buying: true})
    }

    onBuySingle = () => {
/*
        if (this.hasTelnyx()) {
            alert("Sorry, DIDs ordering is temporary unavailable")
            return
        }
*/
        if (this.props.balance <= this.props.user.get("suspensionlimit")) {
            toast.error("You do not have enough funds. Please top up", {
                position: toast.POSITION.TOP_RIGHT,
            })
            return
        }
        this.props.dispatch(replace("/dids/buy"))
    }

    onAddForm = () => {
        this.setState({bulkadd: true})
    }

    onEdit = did => {
        this.setState({edit: did})
    }

    onEditGroup = group => {
        this.setState({editGroup: group})
    }

    onChangeRoutingDialog = () => {
        this.setState({changeRoutingModal: true})
    }

    onChangeRouting = routing => {
        actions
            .changeRouting(this.state.checked, routing, this.props.dispatch)
            .then(response => {
                const size = this.state.checked.size
                this.setState({checked: Set([]), changeRoutingModal: false})
                toast(`Routing updated for ${response.affected} of ${size} DIDs`, {
                    type: response.affected === size ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_RIGHT,
                })
                if (response.affected > 0) {
                    this.reload()
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChangeTagsDialog = () => {
        this.setState({changeTagsModal: true})
    }

    onChangeTags = group => {
        actions
            .changeGroup(this.state.checked, group, this.props.dispatch)
            .then(response => {
                const size = this.state.checked.size
                this.setState({checked: Set([]), changeTagsModal: false})
                toast(`Groups updated for ${response.affected} of ${size} DIDs`, {
                    type: response.affected === size ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_RIGHT,
                })
                if (response.affected > 0) {
                    this.reload()
                }
                this.props.dispatch(groupsActions.load())
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onDelete = () => {
        if (!window.confirm("Delete?")) {
            return false
        }

        this.setState({deleting: false})

        actions
            .del(this.state.checked, this.props.dispatch)
            .then(() => {
                this.setState({checked: Set([])})
                toast.success("Deletion started", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })

        return true
    }

    onBuyConfirm = data => {
        this.setState({
            buying: false,
            confirmModal: true,
            confirmData: data,
        })
    }

    onCloseConfirm = () => {
        this.setState({confirmModal: false})
    }

    onBuy = () => {
        this.setState({confirmModal: false})
        const data = this.state.confirmData
        actions
            .buy(data.dids, data.perstate, data.routing, data.group, data.features, data.type, this.props.dispatch)
            .then(() => {
                toast.success("New job created", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(replace("/jobs"))
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChangeFilter = filter => {
        this.setState({checked: Set([])}, () => this.props.dispatch(actions.setFilter(filter)))
    }

    onExportCSV = () => {
        const csv = this.props.alldata
            .map(item => {
                let desc = item.get("description").split(", ")
                return [item.get("did"), desc[0], desc[1]].join(",")
            })
            .toJS()
            .join("\r\n")

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        const type = isSafari ? "application/csv" : "text/csv"
        const blob = new Blob([csv], {type: type})

        const link = document.createElement("a")
        link.download = "dids.csv"
        link.href = window.URL.createObjectURL(blob)
        link.onclick = () => document.body.removeChild(link)
        document.body.appendChild(link)
        link.click()
    }

    onChangeSort = field => {
        const dir = field !== this.props.sortfield ? "asc" : this.props.sortdir === "asc" ? "desc" : "asc"
        this.props.dispatch(actions.loading())
        this.props.dispatch(actions.setSort(field, dir))
    }

    onSetPage = page => {
        this.props.dispatch(actions.setPage(page))
    }

    setChecked = checked => {
        this.setState({checked: checked})
    }

    setCheckedAll = checked => {
        let s = Set([])
        if (checked) {
            this.props.alldata.forEach(item => {
                s = s.add(item.get("id"))
            })
        }
        this.setChecked(s)
    }

    onTransferShow = () => {
        this.setState({transferModal: true})
    }

    onTransfer = userid => {
        actions
            .transfer(this.state.checked, userid, this.props.dispatch)
            .then(response => {
                const size = this.state.checked.size
                this.setState({checked: Set([]), transferModal: false})
                toast(`${response.affected} of ${size} DIDs transferred`, {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.reload()
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    render() {
        const restricted = this.props.user.get("role") !== "admin"
        const enabledFilters = restricted
            ? ["did", "status", "state", "city", "notes", "group", "orderedafter"]
            : ["did", "status", "state", "city", "notes", "group", "transferred", "orderedafter"]

        return (
            <div>
                <div className="actionBar">
                    <div className="float-right">
                        <Filters icon={iconDids} enabled={enabledFilters} filter={this.props.filter} onChange={this.onChangeFilter} />
                        <Button variant="success" className="addnew" onClick={this.onBuyForm}>
                            <Icon icon="shopping-cart" /> Buy DIDs
                        </Button>{" "}
                        <Button variant="success" className="addnew" onClick={this.onBuySingle}>
                            <Icon icon="shopping-cart" /> Buy Single DID
                        </Button>{" "}
                        {!this.props.stack.isEmpty() && (
                            <>
                                <Button variant="success" className="bulkaddnew" onClick={this.onAddForm}>
                                    <Icon icon="plus" /> Bulk Add
                                </Button>{" "}
                            </>
                        )}
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.state.buying && <DidsOrder onBuy={this.onBuyConfirm} onClose={() => this.setState({buying: false})} />}
                {this.state.bulkadd && <DidsAddControlled onBulkAdd={this.onBulkAdd} onClose={() => this.setState({bulkadd: false})} />}
                {this.props.loading && <Loading />}
                {this.props.loaded && (
                    <Dids
                        restricted={this.props.user.get("role") !== "admin"}
                        data={this.props.data}
                        groups={this.props.groups}
                        onEdit={this.onEdit}
                        onEditGroup={this.onEditGroup}
                        checked={this.state.checked}
                        setChecked={this.setChecked}
                        setCheckedAll={this.setCheckedAll}
                        onDelete={this.onDelete}
                        onReload={this.reload}
                        sortfield={this.props.sortfield}
                        sortdir={this.props.sortdir}
                        onChangeSort={this.onChangeSort}
                        timeOffset={this.props.timeOffset}
                    />
                )}
                {this.props.loaderror && <LoadError message={this.props.loaderror} onReload={this.reload} />}
                {this.state.changeRoutingModal && (
                    <DidsChangeRoutingModal
                        onChange={this.onChangeRouting}
                        onClose={() => this.setState({changeRoutingModal: false})}
                        isAdmin={this.props.user.get("role") === "admin"}
                        groups={this.props.groups}
                        contacts={this.props.contacts}
                    />
                )}
                {this.state.changeTagsModal && (
                    <DidsChangeTagsModal
                        onChange={this.onChangeTags}
                        onClose={() => this.setState({changeTagsModal: false})}
                        isAdmin={this.props.user.get("role") === "admin"}
                    />
                )}
                {this.state.transferModal && (
                    <DidsTransferModal onChange={this.onTransfer} onClose={() => this.setState({transferModal: false})} />
                )}
                {this.state.confirmModal && (
                    <DidsOrderConfirm data={this.state.confirmData} onClose={this.onCloseConfirm} onBuy={this.onBuy} />
                )}
                {this.state.edit !== null && (
                    <DidEditControlled
                        did={this.state.edit}
                        bots={this.props.bots && this.props.bots.filter(bot => bot.get("userid") === this.state.edit.get("userid"))}
                        onClose={() => this.setState({edit: null})}
                    />
                )}
                {this.state.editGroup !== null && (
                    <DidGroupEditControlled
                        didgroup={this.state.editGroup}
                        onClose={() => {
                            this.setState({editGroup: null})
                            this.reload()
                        }}
                    />
                )}
                <Row>
                    <Col sm={12}>
                        <div className="actionBar">
                            {this.props.data.size > 0 && (
                                <>
                                    <Button onClick={this.onDelete} disabled={this.state.checked.count() === 0}>
                                        Delete {this.state.checked.count() > 0 && <span>({this.state.checked.count()})</span>}
                                    </Button>{" "}
                                    <Button onClick={this.onChangeRoutingDialog} disabled={this.state.checked.count() === 0}>
                                        Change Routing
                                    </Button>{" "}
                                    <Button onClick={this.onChangeTagsDialog} disabled={this.state.checked.count() === 0}>
                                        Change Groups
                                    </Button>
                                    {!restricted && (
                                        <Button onClick={this.onTransferShow} disabled={this.state.checked.count() === 0}>
                                            Transfer
                                        </Button>
                                    )}
                                    <div className="float-right d-none d-md-block">
                                        <Button variant="secondary" className="download" onClick={this.onExportCSV}>
                                            Download CSV
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                        {this.props.totalItems > this.props.pageLength && (
                            <div className="paging">
                                <Paginator
                                    currentPage={this.props.currentPage}
                                    totalItems={this.props.totalItems}
                                    pageLength={this.props.pageLength}
                                    onSetPage={this.onSetPage}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.dids.get("loading"),
        loaded: state.dids.get("loaded"),
        data: state.dids.get("data"),
        alldata: state.dids.get("alldata"),
        filter: state.dids.get("filter"),
        sortfield: state.dids.get("sortfield"),
        sortdir: state.dids.get("sortdir"),
        currentPage: state.dids.get("currentPage"),
        totalItems: state.dids.get("totalItems"),
        pageLength: state.dids.get("pageLength"),
        loaderror: state.dids.get("loaderror"),
        user: state.auth.get("user"),
        balance: state.auth.get("balance"),
        timeOffset: state.auth.get("timeOffset"),
        bots: state.bots.get("data"),
        contacts: state.contacts.get("data"),
        groups: state.groups.get("data"),
        stack: state.auth.get("stack"),
    }
}

export default connect(mapStateToProps)(DidsControlled)
