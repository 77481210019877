import React from "react"
import {Form, Col, Row} from "react-bootstrap"
import GroupField from "./GroupField"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"

class DidEdit extends React.PureComponent {
    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        if (field === "botid" || field === "smsendpointid") {
            if (value) {
                value = parseInt(value, 10)
            } else {
                value = null
            }
        }

        let did = this.props.did.set(field, value)
        if (field === "destination_type") {
            did = did.set("destination_value", "")
        }

        this.props.onChange(did)
    }

    render() {
        const hasSms = this.props.did.get("features") && this.props.did.get("features").contains("sms")

        return (
            <Form>
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        name="notes"
                        placeholder="Notes"
                        value={this.props.did.get("notes")}
                        onChange={this.changeField}
                    />
                </Form.Group>
                {this.props.enableGroups && (
                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                            Group
                        </Form.Label>
                        <Col sm={9}>
                            <GroupField group={this.props.did.get("group")} groups={this.props.groups} onChange={this.changeField} />
                        </Col>
                    </Form.Group>
                )}
                {!this.props.did.get("group") && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            Route To
                        </Form.Label>
                        <Col sm="4">
                            <RoutingTypeSelect
                                type="did"
                                name="destination_type"
                                value={this.props.did.get("destination_type")}
                                onChange={this.changeField}
                            />
                        </Col>
                        <Col sm="5">
                            <RoutingValueSelect
                                name="destination_value"
                                type={this.props.did.get("destination_type")}
                                value={this.props.did.get("destination_value")}
                                onChange={this.changeField}
                            />
                        </Col>
                    </Form.Group>
                )}
                {this.props.enableBots && this.props.bots.size > 0 && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            Virtual Assistant
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control as="select" name="botid" value={this.props.did.get("botid") || ""} onChange={this.changeField}>
                                <option value="">Select Virtual Assistant</option>
                                {this.props.bots.map(bot => (
                                    <option key={bot.get("id")} value={bot.get("id")}>
                                        {bot.get("name")} ({bot.get("token")})
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                )}
                {hasSms && !this.props.did.get("group") && this.props.smsendpoints.size > 0 && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            SMS Action
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                as="select"
                                name="smsendpointid"
                                value={this.props.did.get("smsendpointid")}
                                onChange={this.changeField}
                            >
                                {!this.props.did.get("smsendpointid") && <option value="">Select SMS Endpoint</option>}
                                {this.props.smsendpoints.map(ep => (
                                    <option key={ep.get("id")} value={ep.get("id")}>
                                        {ep.get("name")}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                )}
            </Form>
        )
    }
}
export default DidEdit
