import React from "react"
import {Table} from "react-bootstrap"
import {formatTime, formatPhone} from "../../tools"

class UserEarnings extends React.PureComponent {
    onSelect = (e, did) => {
        e.preventDefault()
        this.props.onSelect(did)
    }

    render() {
        const data = this.props.data

        const total = data.reduce(
            (acc, v) => {
                return {
                    num: acc.num + v.get("num"),
                    uniq: acc.uniq + v.get("uniq"),
                    duration: acc.duration + v.get("duration"),
                }
            },
            {
                num: 0,
                duration: 0,
                uniq: 0,
            },
        )

        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Number</th>
                        <th className="right">Calls</th>
                        <th className="right">Unique Calls</th>
                        <th className="right">Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {data.size === 0 && (
                        <tr>
                            <td colSpan={5}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {data.map((item, key) => (
                        <tr key={item.get("number")}>
                            <td>{item.get("title")}</td>
                            <td>{formatPhone(item.get("number"))}</td>
                            <td className="right">{item.get("num")}</td>
                            <td className="right">{item.get("uniq")}</td>
                            <td className="right" title={`${item.get("duration")} seconds`}>
                                {formatTime(item.get("duration"))}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={2}>TOTAL</td>
                        <td className="right">{total.num}</td>
                        <td className="right">{total.uniq}</td>
                        <td className="right" title={`${total.duration} seconds`}>
                            {formatTime(total.duration)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }
}

export default UserEarnings
