export function infoLoad(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_INFO_LOAD",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function transactionsLoad(filter, start, limit, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_TRANSACTIONS_LOAD",
            filter: filter,
            start: start,
            limit: limit,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function didsReportLoad(filter, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_DIDS_LOAD",
            filter: filter,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function smsLoad(filter, sortfield, sortdir, dispatch, limit, start) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_SMS_LOAD",
            filter: filter,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
            limit,
            start,
        })
    })
}

export function spendingLoad(filter, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_SPENDING_LOAD",
            filter: filter,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function cdrsLoad(filter, start, limit, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_CDRS_LOAD",
            filter: filter,
            start: start,
            limit: limit,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function cdrsCSV(filter, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_CDRS_CSV_LINK",
            filter: filter,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function cdrsLoadGrouped(filter, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_CDRS_GROUPED_LOAD",
            filter: filter,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function cdrsStatsLoad(filter, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_CDRS_STATS_LOAD",
            filter: filter,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function earningsLoad(filter, start, limit, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_EARNINGS_LOAD",
            filter: filter,
            start: start,
            limit: limit,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function userEarningsLoad(filter, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_USEREARNINGS_LOAD",
            filter: filter,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function liveCallsLoad(filter, start, limit, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_LIVECALLS_LOAD",
            filter: filter,
            start: start,
            limit: limit,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function liveCallsKill(data, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_LIVECALLS_KILL",
            data: data,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function ivrLogsLoad(filter, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "REPORT_IVRLOGS_LOAD",
            filter: filter,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}
