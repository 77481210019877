import React from "react"
import {Form} from "react-bootstrap"

const make = (title, items) => {
    return {
        title: title,
        Format: value => (value !== "all" && items[value] !== undefined ? items[value] : ""),
        Input: props => {
            const value = props.value ? props.value : "all"
            return Object.keys(items).map(name => (
                <Form.Check
                    inline
                    key={name}
                    type="radio"
                    onChange={() => props.onChange(name, name === "all" ? "" : items[name])}
                    value={name}
                    label={items[name]}
                    checked={value === name}
                />
            ))
        },
    }
}

export default make
