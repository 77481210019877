import React, {Component} from "react"
import statesMap from "./states"
import {Map, Set} from "immutable"
import {Modal, Button, Form, InputGroup, Table, Row, Col} from "react-bootstrap"
//import {Typeahead} from "react-bootstrap-typeahead"
//import * as actions from "./actions/ratecenters"
import {connect} from "react-redux"
import GroupField from "./GroupField"
import "../node_modules/react-bootstrap-typeahead/css/Typeahead-bs4.min.css"
import Icon from "./Icon"
import iconDids from "./images/menu/dids.svg"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"
import config from "./config"

const states = Map(statesMap).sort()

class DidsOrder extends Component {
    constructor(props) {
        super(props)

        let features = Set([])

        props.user.get("trunks").forEach(trunk => {
            features = features.union(config.trunks[trunk.toString()].features.reduce((m, ft) => {
                if (ft !== 'sms' || !!props.user.get('opt_sms_enabled'))
                    m.push(ft)
                return m
            }, []))
        })

        this.state = {
            mode: "random",
            perstate: Map({}),
            rcs: [],
            dids: "",
            fixeddids: "",
            isLoading: false,
            routing: Map({type: "", value: ""}),
            group: null,
            features: features,
            allfeatures: features,
            type: "regular",
        }
    }

    onAddState = e => {
        this.setState({
            mode: "specified",
            perstate: this.state.perstate.set(e.target.value, 0),
        })
    }

    onChangeStateDelta = (state, delta) => {
        const st = this.state.perstate.get(state)
        let val = parseInt(st, 10)
        if (isNaN(val)) {
            val = 0
        }
        val += parseInt(delta, 10)
        if (val < 0) val = 0
        const perstate = this.state.perstate.set(state, val)
        const dids = perstate.reduce((sum, v) => sum + parseInt(v, 10), 0)
        this.setState({
            mode: "specified",
            perstate: perstate,
            dids: dids,
            fixeddids: "",
        })
    }

    onChangeState = e => {
        let val = parseInt(e.target.value, 10)
        if (isNaN(val)) val = ""
        if (val < 0) val = -val
        const perstate = this.state.perstate.set(e.target.name, val)
        const dids = perstate.reduce((sum, v) => sum + parseInt(v, 10), 0)
        this.setState({
            mode: "specified",
            perstate: perstate,
            dids: isNaN(dids) ? "" : dids,
            fixeddids: "",
        })
    }

    onChangeDids = e => {
        let val = parseInt(e.target.value, 10)
        if (isNaN(val)) val = ""
        if (val < 0) val = -val
        this.setState({
            mode: "random",
            dids: val,
            fixeddids: "",
            perstate: Map({}),
        })
    }

    onChangeRoutingType = e => {
        this.setState({
            routing: this.state.routing.set("type", e.target.value).set("value", ""),
        })
    }

    onChangeRoutingValue = e => {
        this.setState({
            routing: this.state.routing.set("value", e.target.value),
        })
    }

    onChangeFixedDids = e => {
        let val = parseInt(e.target.value, 10)
        if (isNaN(val)) val = ""
        if (val < 0) val = -val

        let perstate = Map({})

        let dids = 0
        for (var state in states.toJS()) {
            perstate = perstate.set(state, val)
            dids += val
        }

        this.setState({
            mode: "fixed",
            perstate: perstate,
            dids: dids,
            fixeddids: val,
        })
    }

    onDeleteState = state => {
        const perstate = this.state.perstate.delete(state)
        const dids = perstate.size ? perstate.reduce((sum, v) => sum + v, 0) : 0
        this.setState({
            mode: "specified",
            perstate: perstate,
            dids: dids ? dids : "",
            fixeddids: "",
        })
    }

    onChangeMode = e => {
        this.setState({mode: e.target.value})
    }

    onChangeType = e => {
        this.setState({type: e.target.value})
    }

    onChangeGroup = e => {
        this.setState({
            group: e.target.value,
            routing: this.state.routing.set("type", "").set("value", ""),
        })
    }

    onChangeFeatures = e => {
        let features = this.state.features
        if (e.target.checked) {
            features = features.add(e.target.value)
        } else {
            features = features.remove(e.target.value)
        }
        this.setState({features: features})
    }

    onBuy = () => {
        this.props.onBuy({
            dids: this.state.dids,
            perstate: this.state.perstate.toJS(),
            routing: this.state.routing,
            group: this.state.group,
            features: this.state.features,
            type: this.state.type,
        })
    }

    render() {
        let disabled = !(this.state.dids > 0)
        if (
            ((this.state.routing.get("type") === "ivr" || this.state.routing.get("type") === "contact") &&
                !this.state.routing.get("value")) ||
            this.state.features.count() === 0
        ) {
            disabled = true
        }
        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            Buy DIDs
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <React.Fragment>
                            <div>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2">
                                        Group
                                    </Form.Label>
                                    <Col sm="10">
                                        <GroupField group={this.state.group} groups={this.props.groups} onChange={this.onChangeGroup} />
                                    </Col>
                                </Form.Group>
                                {!!this.props.user.get("opt_ivr_enabled") && !this.state.group && (
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">
                                            Route To
                                        </Form.Label>
                                        <Col sm="4">
                                            <RoutingTypeSelect
                                                type="did"
                                                name="type"
                                                value={this.state.routing.get("type")}
                                                onChange={this.onChangeRoutingType}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <RoutingValueSelect
                                                name="dest_value"
                                                type={this.state.routing.get("type")}
                                                value={this.state.routing.get("value")}
                                                onChange={this.onChangeRoutingValue}
                                            />
                                        </Col>
                                    </Form.Group>
                                )}
                                {this.state.allfeatures.count() > 1 && (
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">
                                            Features
                                        </Form.Label>
                                        <Col sm="10">
                                            {this.state.allfeatures.map(feature => (
                                                <Form.Check
                                                    key={feature}
                                                    inline
                                                    label={feature}
                                                    value={feature}
                                                    type="checkbox"
                                                    checked={this.state.features.has(feature)}
                                                    onChange={this.onChangeFeatures}
                                                />
                                            ))}
                                        </Col>
                                    </Form.Group>
                                )}

                                <Form.Group as={Row}>
                                    <Form.Label column sm="2">
                                        DID Type
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Check
                                            key="regular"
                                            inline
                                            label="Regular"
                                            value="regular"
                                            type="radio"
                                            checked={this.state.type === "regular"}
                                            onChange={this.onChangeType}
                                        />
                                        <Form.Check
                                            key="tollfree"
                                            inline
                                            label="Toll-Free"
                                            value="tollfree"
                                            type="radio"
                                            checked={this.state.type === "tollfree"}
                                            onChange={this.onChangeType}
                                        />
                                    </Col>
                                </Form.Group>
                            </div>
                            <hr />
                        </React.Fragment>
                        <div>
                            <label>
                                <Form.Check
                                    type="radio"
                                    inline
                                    checked={this.state.mode === "random"}
                                    value="random"
                                    onChange={this.onChangeMode}
                                />
                                {this.state.type === "regular" && "DIDs from Random States"}
                                {this.state.type === "tollfree" && "Toll-Free DIDs"}
                            </label>
                        </div>

                        <div style={{marginLeft: "25px"}}>
                            <Form.Control type="text" placeholder="DIDs amount" value={this.state.dids} onChange={this.onChangeDids} />
                        </div>

                        {this.state.type === "regular" && (
                            <>
                                <hr />
                                <div>
                                    <label>
                                        <Form.Check
                                            type="radio"
                                            inline
                                            checked={this.state.mode === "fixed"}
                                            value="fixed"
                                            onChange={this.onChangeMode}
                                        />
                                        Fixed Quantity Per State
                                    </label>
                                </div>

                                <div style={{marginLeft: "25px"}}>
                                    <Form.Control
                                        type="text"
                                        placeholder="DIDs amount"
                                        value={this.state.fixeddids}
                                        onChange={this.onChangeFixedDids}
                                    />
                                </div>

                                <hr />
                                <div>
                                    <label>
                                        <Form.Check
                                            type="radio"
                                            inline
                                            checked={this.state.mode === "specified"}
                                            value="specified"
                                            onChange={this.onChangeMode}
                                        />
                                        DIDs from Specified States
                                    </label>
                                </div>
                                <div style={{paddingLeft: "25px"}}>
                                    <Form.Control as="select" onChange={this.onAddState} value="" style={{marginBottom: "5px"}}>
                                        <option>Select State</option>
                                        {states.keySeq().map(key => (
                                            <option key={key} value={key} disabled={this.state.perstate.has(key)}>
                                                {states.get(key)}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {this.state.mode === "specified" && this.state.perstate.count() > 0 && (
                                        <Table striped size="sm">
                                            <tbody>
                                                {this.state.perstate
                                                    .keySeq()
                                                    .sort()
                                                    .map(state => (
                                                        <tr key={state}>
                                                            <td style={{verticalAlign: "middle"}}>{statesMap[state]}</td>
                                                            <td width="200px">
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <Button
                                                                            variant="outline-secondary"
                                                                            className="icon-minus"
                                                                            onClick={() => this.onChangeStateDelta(state, -1)}
                                                                        >
                                                                            <Icon icon="minus" />
                                                                        </Button>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={state}
                                                                        placeholder="DIDs amount"
                                                                        value={this.state.perstate.get(state)}
                                                                        onChange={this.onChangeState}
                                                                        className="plusminusfield"
                                                                    />
                                                                    <InputGroup.Append>
                                                                        <Button
                                                                            variant="outline-secondary"
                                                                            className="icon-plus"
                                                                            onClick={() => this.onChangeStateDelta(state, 1)}
                                                                        >
                                                                            <Icon icon="plus" />
                                                                        </Button>
                                                                    </InputGroup.Append>
                                                                </InputGroup>
                                                            </td>
                                                            <td style={{textAlign: "right"}}>
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    name={state}
                                                                    onClick={() => this.onDeleteState(state)}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                            </>
                        )}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="info" className="continue" disabled={disabled} onClick={this.onBuy}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        groups: state.groups.get("data"),
    }
}

export default connect(mapStateToProps)(DidsOrder)
