import React from "react"
import {connect} from "react-redux"
import {Badge, Button} from "react-bootstrap"
import {formatMoney} from "./tools"
import Icon from "./Icon"
import {balance} from "./actions/auth"
import {push} from "connected-react-router"

const updateInterval = 60 // seconds

class Balance extends React.PureComponent {
    state = {
        loading: false,
        balance: null,
    }

    componentDidMount = () => {
        this.reload()
        this.timer = setInterval(this.reload, updateInterval * 1000)
    }

    componentWillUnmount = () => {
        if (this.timer) clearInterval(this.timer)
    }

    reload = e => {
        if (e !== undefined) {
            this.setState({loading: true})
        }
        balance(this.props.dispatch).then(balance => this.setState({balance: balance, loading: false}))
    }

    onPayment = () => {
        this.props.dispatch(push("/topup/"))
    }

    render() {
        const balance = this.state.balance
        if (balance === null) return ""

        return (
            <>
                <span className="balance">Balance: </span>
                <Badge
                    variant={
                        balance < this.props.suspensionlimit ? "danger" : balance < this.props.notificationlimit ? "warning" : "success"
                    }
                >
                    {formatMoney(balance)}
                </Badge>{" "}
                <span className="balance">
                    {this.state.loading ? <Icon icon="spinner" spin /> : <Icon icon="sync-alt" onClick={this.reload} />}
                </span>{" "}
                <Button variant="success" className="pad-left" onClick={this.onPayment}>
                    Top Up
                </Button>
            </>
        )
    }
}

export default connect()(Balance)
