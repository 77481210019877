export function load() {
    return {
        type: "GROUPS_LOAD",
    }
}

export function loaded(data) {
    return {
        type: "GROUPS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "GROUPS_LOADERROR",
        msg: msg,
    }
}

export function usage(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_USAGE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, newrouting, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_DELETE",
            newrouting: newrouting,
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function status(id, status, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_STATUS",
            id: id,
            status: status,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function unlink(id, items, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_UNLINK",
            id: id,
            items: items,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function save(didgroup, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_SAVE",
            didgroup: didgroup,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function loadReport(range, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_REPORT_LOAD",
            range: range,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function loadCalls(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GROUPS_CALLS_LOAD",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}
