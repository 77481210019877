import * as actions from "../actions/users"
import * as authActions from "../actions/auth"
import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"
import {convertTypes} from "../tools"

const usersMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "USERS_LOAD":
                store.dispatch(loading.start())
                store.dispatch(actions.loading())
                axios
                    .get(`${config.apiEndpoint}users`)
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        console.log(e)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Network error"))
                    })

                break

            case "USERS_SAVE":
                const types = {
                    ratein: "decimal",
                    rateout: "decimal",
                    priceorder: "decimal",
                    pricecancel: "decimal",
                    pricemonthly: "decimal",
                    rateintf: "decimal",
                    rateouttf: "decimal",
                    rateoutsip: "decimal",
                    priceordertf: "decimal",
                    pricecanceltf: "decimal",
                    pricemonthlytf: "decimal",
                    pricesms: "decimal",
                    pricesmsin: "decimal",
                    servicefeepaypal: "decimal",
                    servicefeestripe: "decimal",
                    notificationlimit: "int",
                    suspensionlimit: "int",
                    mintopup: "int",
                }
                const user = convertTypes(action.user, types)
                const noid = user.delete("id").toJS()
                const req = user.has("id")
                    ? axios.put(`${config.apiEndpoint}user/${user.get("id")}`, noid)
                    : axios.post(`${config.apiEndpoint}user`, noid)
                req.then(response => {
                    action.resolve(response.data.id)
                    authActions.check(store.dispatch)
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            case "USERS_DELETE":
                {
                    const user = action.user.toJS()
                    axios
                        .delete(`${config.apiEndpoint}user/${user.id}`)
                        .then(response => {
                            action.resolve()
                        })
                        .catch(e => {
                            if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                            else action.reject(e.message)
                        })
                }
                break

            case "USERS_RENEW_APIKEY":
                axios
                    .get(`${config.apiEndpoint}user/${action.id}/renewapikey`)
                    .then(response => {
                        store.dispatch(authActions.apiKey(response.data))
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "USERS_RENEW_SMS_APIKEY":
                axios
                    .get(`${config.apiEndpoint}user/renewsmsapikey`)
                    .then(response => {
                        store.dispatch(authActions.smsApiKey(response.data))
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default usersMiddleware
