import React, {Component} from "react"
import {connect} from "react-redux"
import IvrLogs from "./IvrLogs"
import {List, Map} from "immutable"
import {toast} from "react-toastify"
import {ivrLogsLoad} from "../../actions/reports"
import * as loading from "../../actions/loading"
import Title from "../../Title"
import Filters from "../../filters/Filters"
import ReloadIcon from "../../ReloadIcon"
import iconIvrs from "../../images/menu/ivrs.svg"

class IvrLogsControlled extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: Map({}),
            sortfield: "id",
            sortdir: "desc",
            data: List([]),
            opts: [],
        }
    }

    onFastFilter = fields => {
        this.onChangeFilter(this.state.filter.merge(fields).filter(f => f !== ""))
    }

    onChangeFilter = filter => {
        this.setState(
            {
                filter: filter,
            },
            this.reload,
        )
    }

    reload = state => {
        if (!state) state = this.state

        this.props.dispatch(loading.start())

        ivrLogsLoad(this.state.filter, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data} = r
                this.props.dispatch(loading.end())

                let opts = Map({})
                let tmp = Map({})
                data.forEach(item => {
                    const id = item.get("didid") + "-" + item.get("ivrid")
                    const optid = item.get("opt")
                    const optkey = "opt" + optid
                    const total = opts.has(optid) ? opts.get(optid) : 0
                    let mitem = tmp.has(id) ? tmp.get(id) : item
                    let num = (mitem.has(optkey) ? item.get(optkey) : 0) + item.get("number")
                    tmp = tmp.set(id, mitem.set(optkey, num))
                    opts = opts.set(optid, total + item.get("number"))
                })

                this.setState({
                    opts: opts
                        .toArray()
                        .map(i => parseInt(i, 10))
                        .sort(),
                    totals: opts,
                    data: tmp.valueSeq(),
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                })
            })
            .catch(e => {
                toast.error("Error loading IVR logs", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount = () => {
        this.reload()
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            sortfield: field,
            sortdir: dir,
        })
    }

    render() {
        return (
            <>
                <Title title="IVR logs" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters
                            icon={iconIvrs}
                            enabled={["date1", "date2", "did", "ivr", "email"]}
                            filter={this.state.filter}
                            onChange={this.onChangeFilter}
                        />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <IvrLogs
                    restricted={this.props.user.get("role") !== "admin"}
                    data={this.state.data}
                    opts={this.state.opts}
                    totals={this.state.totals}
                    filter={this.state.filter}
                    onReload={this.reload}
                    onFilter={this.onFastFilter}
                    onChangeSort={this.onChangeSort}
                    sortfield={this.state.sortfield}
                    sortdir={this.state.sortdir}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(IvrLogsControlled)
