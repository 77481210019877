import axios from "axios"
import config from "../config"
import {fromJS} from "immutable"

const stripeMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "STRIPE_LOAD_CARDS":
                axios
                    .get(config.apiEndpoint + "stripe/cards")
                    .then(response => action.resolve(fromJS(response.data)))
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "STRIPE_AUTHORIZE":
                axios
                    .post(config.apiEndpoint + `stripe/authorize`, {
                        token: action.token,
                        amount: action.amount,
                    })
                    .then(() => action.resolve())
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "STRIPE_ATTACH_CARD":
                axios
                    .post(config.apiEndpoint + "stripe/card", {token: action.token})
                    .then(() => action.resolve())
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })

                break

            case "STRIPE_SETUPINTENT":
                axios
                    .get(config.apiEndpoint + "stripe/setupintent")
                    .then(response => action.resolve(response.data.clientsecret))
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })

                break

            case "STRIPE_PAYMENTINTENT":
                axios
                    .post(config.apiEndpoint + "stripe/paymentintent", {
                        paymentmethod: action.paymentmethod,
                        amount: Math.round(action.amount * 100),
                        netamount: Math.round(action.netamount * 100),
                    })
                    .then(response => action.resolve(response.data.clientsecret))
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "STRIPE_DELETE":
                axios
                    .delete(config.apiEndpoint + `stripe/card/${action.token}`)
                    .then(() => action.resolve())
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default stripeMiddleware
