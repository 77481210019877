import {Map, List, Stack} from "immutable"
import {load} from "../LocalStorage"

export default {
    loading: 0,
    page: "",
    auth: Map({
        checked: false,
        authorized: false,
        token: "",
        user: Map({}),
        stack: Stack([]),
        error: "",
        theme: "light",
        timeOffset: false,
    }),
    dids: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        alldata: List([]),
        data: List([]),
        filter: Map({
            number: "",
            state: "",
            city: "",
            status: "all",
            notes: "",
            group: "",
        }),
        sortfield: load("dids-sortfield", "dateadded"),
        sortdir: load("dids-sortdir", "desc"),
        currentPage: 0,
        totalPages: 0,
        pageLength: 50,
    }),
    users: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    contacts: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    jobs: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    ivrs: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    routinggroups: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    bots: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    templates: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    recordings: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
    groups: Map({
        loaded: false,
        loading: false,
        loaderror: "",
        data: List([]),
    }),
}
