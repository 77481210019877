import React, {Component} from "react"
import {connect} from "react-redux"
import LiveCalls from "./LiveCalls"
import {List, Map, Set, fromJS} from "immutable"
import {toast} from "react-toastify"
import {liveCallsLoad, liveCallsKill} from "../../actions/reports"
import * as loading from "../../actions/loading"
import Paginator from "../../Paginator"
import Title from "../../Title"
import {Button} from "react-bootstrap"
import Filters from "../../filters/Filters"
import ReloadIcon from "../../ReloadIcon"
import iconLiveCalls from "../../images/menu/livecalls.svg"

const pageLength = 50

class LiveCallsControlled extends Component {
    constructor(props) {
        super(props)

        this.filters = ["direction", "did", "destination", "email", "group"]

        this.state = {
            filter: Map({}),
            currentPage: 0,
            totalItems: 0,
            sortfield: "id",
            sortdir: "desc",
            data: List([]),
            delta: 0,
            checked: Set([]),
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            groups: props.groups,
        }
    }

    onFilter = fields => {
        this.onChangeFilter(this.state.filter.merge(fields))
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            this.reload,
        )
    }

    reload = state => {
        if (!state) state = this.state
        let page = state.currentPage

        this.props.dispatch(loading.start())

        let start = pageLength * page

        liveCallsLoad(this.state.filter, start, pageLength, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data, total} = r
                const totalPages = Math.ceil(total / pageLength)
                if (page > totalPages) {
                    page = totalPages
                }

                this.props.dispatch(loading.end())

                this.setState({
                    currentPage: page,
                    totalItems: total,
                    data: data,
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                    delta: 0,
                })
            })
            .catch(e => {
                toast.error("Error loading live calls", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount = () => {
        this.timer1 = setInterval(() => this.setState({delta: this.state.delta + 1}), 1000)
        this.timer2 = setInterval(() => this.reload(), 15000)

        let filter = window.sessionStorage.getItem("livecalls-filter")
        if (filter) {
            window.sessionStorage.removeItem("livecalls-filter")
            let f = JSON.parse(filter)
            this.onChangeFilter(this.state.filter.merge(fromJS(f)))
        } else {
            this.reload()
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer1)
        clearInterval(this.timer2)
    }

    onSetPage = page => {
        this.reload({
            currentPage: page,
            sortfield: this.state.sortfield,
            sortdir: this.state.sortdir,
        })
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            currentPage: 0,
            sortfield: field,
            sortdir: dir,
        })
    }

    setChecked = checked => {
        this.setState({checked: checked})
    }

    onKillSelected = () => {
        liveCallsKill(this.state.checked.toJS(), this.props.dispatch)
            .then(() => {
                this.setState({checked: Set([])})
                setTimeout(() => this.reload(), 500)
            })
            .catch(e => console.log(e))
    }

    render() {
        return (
            <>
                <Title title="Live Calls" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters icon={iconLiveCalls} enabled={this.filters} filter={this.state.filter} onChange={this.onChangeFilter} />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <LiveCalls
                    restricted={this.state.user.get("role") !== "admin"}
                    data={this.state.data}
                    checked={this.state.checked}
                    filter={this.state.filter}
                    onReload={this.reload}
                    onFilter={this.onFilter}
                    onChangeSort={this.onChangeSort}
                    sortfield={this.state.sortfield}
                    sortdir={this.state.sortdir}
                    delta={this.state.delta}
                    setChecked={this.setChecked}
                    timeOffset={this.props.timeOffset}
                />
                <div className="actionBar">
                    <div className="buttons">
                        <Button onClick={this.onKillSelected} disabled={this.state.checked.count() === 0}>
                            Kill Selected
                        </Button>
                    </div>
                    <div className="paging">
                        {this.state.totalItems > pageLength && (
                            <Paginator
                                currentPage={this.state.currentPage}
                                totalItems={this.state.totalItems}
                                pageLength={pageLength}
                                onSetPage={this.onSetPage}
                            />
                        )}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        timeOffset: state.auth.get("timeOffset"),
        groups: state.groups,
    }
}

export default connect(mapStateToProps)(LiveCallsControlled)
