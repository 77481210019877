import React from "react"
import {Table, Button} from "react-bootstrap"
import Icon from "../Icon"
import RoutingDestination from "../RoutingDestination"
import {formatPhone} from "../tools"

class Contacts extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Destination</th>
                        <th>Title</th>
                        <th className="d-none d-md-table-cell">Call Limits</th>
                        <th>Active</th>
                        {!this.props.nochangeclient && <th className="d-none d-md-table-cell">Client</th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={5}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((row, key) => (
                        <tr key={row.get("id")}>
                            <td>
                                {row.get("mode") === "did" && formatPhone(row.get("number"))}
                                {row.get("mode") === "sip" && row.get("siptrunk").replace("{did}@", "")}
                            </td>
                            <td>{row.get("title")}</td>
                            <td className="d-none d-md-table-cell">
                                {row.get("maxcalls") > 0 && (
                                    <>
                                        <strong>
                                            <RoutingDestination type={row.get("dest_type")} value={row.get("dest_value")} />
                                        </strong>{" "}
                                        after {row.get("maxcalls")} calls
                                    </>
                                )}
                                {row.get("maxcalls") === 0 && <span>∞</span>}
                            </td>
                            <td>
                                {row.get("status") === "active" && <span className="icon-success" title="Yes"></span>}
                                {row.get("status") === "inactive" && <span className="icon-danger" title="Yes"></span>}
                            </td>
                            {!this.props.nochangeclient && <td>{row.get("clientname") || "(unassigned)"}</td>}
                            <td className="float-right">
                                {row.get("status") === "active" && (
                                    <Button
                                        size="sm"
                                        variant="info"
                                        className="btn-pause"
                                        onClick={() => this.props.onChangeStatus(row, "inactive")}
                                        title="Pause"
                                    >
                                        <Icon icon="pause" /> Pause
                                    </Button>
                                )}
                                {row.get("status") === "inactive" && (
                                    <Button
                                        size="sm"
                                        variant="info"
                                        className="btn-play"
                                        onClick={() => this.props.onChangeStatus(row, "active")}
                                        title="Activate"
                                    >
                                        <Icon icon="play" /> Activate
                                    </Button>
                                )}{" "}
                                <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                    <Icon icon="pencil-alt" /> Edit
                                </Button>{" "}
                                <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Contacts
