import React from "react"
import {Button, Row, Col, Form} from "react-bootstrap"
import {connect} from "react-redux"
import DidGroups from "./DidGroups"
import Title from "./Title"
import DidGroupEditControlled from "./DidGroupEditControlled"
import {Map, List} from "immutable"
import LoadError from "./LoadError"
import {toast} from "react-toastify"
import * as actions from "./actions/groups"
import {push} from "connected-react-router"
import Icon from "./Icon"
import ReloadIcon from "./ReloadIcon"
import DeleteModal from "./DeleteModal"
import iconToday from "./images/icons/today.svg"
import iconWeek from "./images/icons/week.svg"
import iconMonth from "./images/icons/month.svg"

class DidGroupsControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            loaderror: "",
            data: List([]),
            editor: null,
            deleting: null,
            type: "today",
        }
    }

    newDidGroup = Map({
        id: 0,
        name: "",
        dest_type: "",
        dest_value: "",
        status: "active",
    })

    reload = () => {
        actions
            .loadReport(this.state.type, this.props.dispatch)
            .then(response => {
                this.setState({data: response, loaded: true, loaderror: ""})
            })
            .catch(error => {
                this.setState({loaded: false, loaderror: error})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    componentDidMount() {
        this.reload()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.type !== prevState.type) {
            this.reload()
        }
    }

    onDelete = didgroup => {
        this.setState({deleting: didgroup})
    }

    onEdit = didgroup => {
        this.setState({editor: didgroup})
    }

    onEditClose = reload => {
        if (reload === true) {
            this.reload()
        }
        this.setState({editor: null})
    }

    saveFilter = (name, didgroup, type, direction) => {
        let filter = {
            group: didgroup.get("id").toString(),
            type: type,
        }
        if (direction !== undefined) {
            filter.direction = direction
        }
        window.sessionStorage.setItem(name, JSON.stringify(filter))
    }

    onDids = didgroup => {
        this.saveFilter("dids-filter", didgroup)
        this.props.dispatch(push(`/dids/`))
    }

    onLiveCalls = (didgroup, direction) => {
        this.saveFilter("livecalls-filter", didgroup, "", direction)
        this.props.dispatch(push(`/livecalls/`))
    }

    onToday = (didgroup, direction) => {
        this.saveFilter("cdrs-filter", didgroup, "today", direction)
        this.props.dispatch(push(`/cdrs/`))
    }

    onWeek = (didgroup, direction) => {
        this.saveFilter("cdrs-filter", didgroup, "week", direction)
        this.props.dispatch(push(`/cdrs/`))
    }

    onTotal = (didgroup, direction) => {
        this.saveFilter("cdrs-filter", didgroup, "", direction)
        this.props.dispatch(push(`/cdrs/`))
    }

    onChangeStatus = (didgroup, status) => {
        actions
            .status(didgroup.get("id"), status, this.props.dispatch)
            .then(() => {
                toast.success("DID Group status changed", {
                    position: toast.POSITION.TOP_RIGHT,
                })

                const data = this.state.data.map(item => (item.get("id") === didgroup.get("id") ? item.set("status", status) : item))
                this.setState({data: data})
            })
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    render() {
        const types = {
            today: (
                <>
                    Today Calls <img src={iconToday} alt="" />
                </>
            ),
            week: (
                <>
                    This Week Calls <img src={iconWeek} alt="" />
                </>
            ),
            total: (
                <>
                    All Calls <img src={iconMonth} alt="" />
                </>
            ),
        }

        return (
            <div>
                <Title title="DID Groups" />
                <Row>
                    <Col sm={12}>
                        <div className="actionBar">
                            {Object.keys(types).map(type => (
                                <Form.Check
                                    key={type}
                                    inline
                                    type="radio"
                                    label={types[type]}
                                    checked={this.state.type === type}
                                    onChange={() => this.setState({type: type})}
                                />
                            ))}
                            <div className="float-right">
                                <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newDidGroup)}>
                                    <Icon icon="plus" /> New DID Group
                                </Button>
                                <ReloadIcon onClick={this.reload} />
                            </div>
                        </div>
                    </Col>
                </Row>
                {this.state.loaded && (
                    <DidGroups
                        data={this.state.data}
                        onEdit={this.onEdit}
                        onDids={this.onDids}
                        onLiveCalls={this.onLiveCalls}
                        onToday={this.onToday}
                        onWeek={this.onWeek}
                        onTotal={this.onTotal}
                        onDelete={this.onDelete}
                        onReload={this.reload}
                        enableRouting={!!this.props.user.get("opt_ivr_enabled")}
                        onChangeStatus={this.onChangeStatus}
                        type={this.state.type}
                    />
                )}
                {this.state.loaderror && <LoadError message={this.state.loaderror} onReload={this.reload} />}
                {this.state.editor && (
                    <DidGroupEditControlled didgroup={this.state.editor} onClose={this.onEditClose} onReload={this.reload} />
                )}
                {this.state.deleting && (
                    <DeleteModal
                        id={this.state.deleting.get("id")}
                        title={this.state.deleting.get("name")}
                        type="group"
                        onDeleted={this.reload}
                        onClose={() => this.setState({deleting: null})}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(DidGroupsControlled)
