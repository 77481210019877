import React from "react"
import {connect} from "react-redux"
import PayPalButton from "./PayPalButton"
import Title from "./Title"
import {Form, Col, Row, Tabs, Tab, Button, Alert} from "react-bootstrap"
import {toast} from "react-toastify"
import {check as checkAuth} from "./actions/auth"
import * as actions from "./actions/stripe"
import {formatMoney, formatCardBrand} from "./tools"
import {isFloat} from "./validators"
import StripeForm from "./StripeForm"
import StripePayButton from "./StripePayButton"
import SpinnerButton from "./SpinnerButton"
import {Elements, StripeProvider} from "react-stripe-elements"
import config from "./config"

class PaymentControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            complete: false,
            type: "manual",
            defaultcardid: null,
            originaldefaultcardid: null,
            method: "stripe",
            amount: "100.00",
            amountvalue: 100,
            mintopup: 0,
            servicefeepaypal: 3,
            servicefeestripe: 3,
            error: false,
            cards: null,
            newcard: false,
        }
    }

    recalc = () => {
        const fee = this.state.method === "paypal" ? this.state.servicefeepaypal : this.state.servicefeestripe
        const amount = this.state.amount
        const amountvalue = parseFloat(amount)
        const totalamount = Math.round(amountvalue * (1 + fee / 100) * 100) / 100
        const error = !isFloat(amount) || amountvalue === 0 || amountvalue < this.state.mintopup
        this.setState({
            amount: amount,
            amountvalue: amountvalue,
            totalamount: totalamount,
            error: error,
        })
    }

    onSelectTab = key => {
        this.setState(
            {
                complete: false,
                type: key,
                defaultcardid: this.state.originaldefaultcardid,
                method: "stripe",
            },
            this.recalc,
        )
    }

    changeField = e => {
        this.setState({[e.target.name]: e.target.value}, this.recalc)
    }

    onSuccess = () => {
        toast.success("Payment Successful", {
            position: toast.POSITION.TOP_RIGHT,
        })
        this.setState({complete: true})
    }

    onFailure = msg => {
        toast.error(msg ? msg : "Payment failed", {
            position: toast.POSITION.TOP_RIGHT,
        })
    }

    reloadCards = () => {
        actions.loadCards(this.props.dispatch).then(cards => {
            this.setState({cards: cards})
        })
    }

    componentDidMount = () => {
        this.reloadCards()
        checkAuth(this.props.dispatch)
            .then(user => {
                const amountvalue = Math.max(
                    this.state.amountvalue,
                    user.get("mintopup"),
                    user.get("notificationlimit"),
                    user.get("autopaymentamount"),
                )
                this.setState({
                    defaultcardid: user.get("stripepaymentmethodid"),
                    originaldefaultcardid: user.get("stripepaymentmethodid"),
                    manual_method: "stripe",
                    auto_method: "stripe",
                    mintopup: Math.max(user.get("mintopup"), user.get("notificationlimit")),
                    servicefeepaypal: user.get("servicefeepaypal"),
                    servicefeestripe: user.get("servicefeestripe"),
                    amount: amountvalue.toFixed(2),
                    amountvalue: amountvalue,
                    totalamount: Math.round(amountvalue * (1 + user.get("servicefeestripe") / 100) * 100) / 100,
                })
            })
            .catch(e => {
                console.log(e)
                this.props.onClose()
            })
    }

    changeDefaultCard = id => {
        this.setState({defaultcardid: id})
    }

    onAuthorize = () => {
        this.setState({active: true})
        actions
            .authorize(this.state.defaultcardid, this.state.amountvalue, this.props.dispatch)
            .then(() => {
                this.setState({active: false, complete: true})
                toast.success("Automatic Payments Authorized", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
            .catch(e => {
                this.setState({active: false})
                console.log(e)
            })
    }

    onDelete = (e, id) => {
        e.stopPropagation()
        if (!window.confirm("Delete card?")) return
        actions
            .remove(id, this.props.dispatch)
            .then(() => {
                toast.success("Card Deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.reloadCards()
            })
            .catch(e => console.log(e))
    }

    onNewCardClose = reload => {
        if (reload === true) {
            toast.success("New Card Added", {
                position: toast.POSITION.TOP_RIGHT,
            })
            this.reloadCards()
        }
        this.setState({newcard: false})
    }

    render() {
        if (this.state.cards === null) return ""

        const defaultCard = this.state.cards.filter(card => card.get("id") === this.state.defaultcardid).get(0)
        const fee = this.state.method === "paypal" ? this.state.servicefeepaypal : this.state.servicefeestripe

        return (
            <StripeProvider apiKey={config.stripeApiKey}>
                <Elements>
                    <>
                        <Title title="Top-Up Balance" />
                        <Tabs activeKey={this.state.type} id="payments" onSelect={this.onSelectTab}>
                            <Tab eventKey="manual" title="Manual one time top-up"></Tab>
                            <Tab eventKey="auto" title="Automated top-ups"></Tab>
                        </Tabs>

                        <Row>
                            <Col sm="6">
                                <Form.Group>
                                    <Form.Label>Payment method</Form.Label>
                                    <Form.Control as="select" name="method" value={this.state.method} onChange={this.changeField}>
                                        <option key="stripe" value="stripe">
                                            Credit Card
                                        </option>
                                        {this.state.type === "manual" && (
                                            <option key="paypal" value="paypal">
                                                PayPal
                                            </option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Top Up Amount, USD</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        placeholder="Amount"
                                        value={this.state.amount}
                                        onChange={this.changeField}
                                    />
                                    <div style={{padding: "10px"}}>
                                        {!this.state.error && this.state.amountvalue >= this.state.mintopup && (
                                            <>
                                                {this.state.type === "manual" && (
                                                    <>
                                                        You will be charged total <strong>{formatMoney(this.state.totalamount)}</strong> (
                                                        {formatMoney(this.state.amount)} + {fee}% Service Fee)
                                                    </>
                                                )}
                                                {this.state.type === "auto" && (
                                                    <>
                                                        Your card will be automatically charged for{" "}
                                                        <strong>{formatMoney(this.state.totalamount)}</strong> (
                                                        {formatMoney(this.state.amount)} + {fee}% Service Fee) when your balance will run
                                                        below <strong>{formatMoney(this.props.user.get("notificationlimit"))}</strong>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {isFloat(this.state.amount) && this.state.amountvalue < this.state.mintopup && (
                                            <>
                                                Minimal Top-Up Amount is <strong>{formatMoney(this.state.mintopup)}</strong>
                                            </>
                                        )}
                                        {!isFloat(this.state.amount) && <>Please enter payment amount</>}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.method === "stripe" && (
                            <>
                                <Row>
                                    <Col sm={12}>
                                        {!defaultCard && (
                                            <Alert variant="info">
                                                <strong>Warning!</strong> {this.state.cards.size > 0 && <>Select</>}
                                                {this.state.cards.size > 0 || <>Add</>} payment method to continue
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    {this.state.cards.keySeq().map(key => (
                                        <Col sm={3} key={key}>
                                            <div
                                                key={key}
                                                className="creditcard"
                                                onClick={() => this.changeDefaultCard(this.state.cards.get(key).get("id"))}
                                            >
                                                <Row>
                                                    <Col sm={2}>
                                                        <Form.Check
                                                            type="radio"
                                                            onChange={() => this.changeDefaultCard(this.state.cards.get(key).get("id"))}
                                                            checked={this.state.cards.get(key).get("id") === this.state.defaultcardid}
                                                        />
                                                    </Col>
                                                    <Col sm={10}>
                                                        <strong style={{fontSize: "110%"}}>
                                                            {formatCardBrand(this.state.cards.get(key).get("brand"))}
                                                        </strong>
                                                        <div>
                                                            <div>•••• {this.state.cards.get(key).get("last4")}</div>
                                                            <div>Exp Date: {this.state.cards.get(key).get("expdate")}</div>
                                                            {this.state.cards.get(key).get("id") ===
                                                                this.props.user.get("stripepaymentmethodid") && (
                                                                <div>
                                                                    AutoRecharge at{" "}
                                                                    <strong>{formatMoney(this.props.user.get("notificationlimit"))}</strong>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <Button
                                                            className="float-right"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={e => this.onDelete(e, this.state.cards.get(key).get("id"))}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ))}
                                    <Col sm={3}>
                                        <div key="new" className="creditcard new" onClick={() => this.setState({newcard: true})}>
                                            <Row>
                                                <Col sm={2}>
                                                    <Form.Check type="radio" checked={false} onChange={() => {}} />
                                                </Col>
                                                <Col sm={10}>
                                                    <div style={{fontSize: "120%"}}>Add new credit card...</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Form.Group as={Row} className={this.state.error ? "error" : ""}>
                            <Col sm={6}>
                                {this.state.complete && <Alert variant="success">Complete!</Alert>}
                                {!this.state.complete && (
                                    <>
                                        {this.state.method === "paypal" && (
                                            <PayPalButton
                                                amount={this.state.amountvalue}
                                                totalamount={this.state.totalamount}
                                                onSuccess={this.onSuccess}
                                                onFailure={this.onFailure}
                                                hidden={this.state.error}
                                            />
                                        )}

                                        {this.state.method === "stripe" && (
                                            <>
                                                {!!defaultCard && !this.state.complete && (
                                                    <>
                                                        {this.state.type === "manual" && (
                                                            <StripePayButton
                                                                disabled={!!this.state.error}
                                                                paymentmethodid={this.state.defaultcardid}
                                                                netamount={this.state.amountvalue}
                                                                amount={this.state.totalamount}
                                                                onSuccess={this.onSuccess}
                                                                onFailure={this.onFailure}
                                                            >
                                                                Pay
                                                                {formatMoney(this.state.totalamount)} from •••• {defaultCard.get("last4")}
                                                            </StripePayButton>
                                                        )}
                                                        {this.state.type === "auto" && (
                                                            <SpinnerButton
                                                                block
                                                                paymentmethodid={this.state.defaultcardid}
                                                                netamount={this.state.amountvalue}
                                                                amount={this.state.totalamount}
                                                                regularText={`Authorize ${formatMoney(
                                                                    this.state.totalamount,
                                                                )} from •••• ${defaultCard.get("last4")}`}
                                                                activeText="Authorizing..."
                                                                icon=""
                                                                active={this.state.active}
                                                                onClick={this.onAuthorize}
                                                                disabled={!!this.state.error}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Form.Group>
                        {this.state.newcard && <StripeForm user={this.props.user} onClose={this.onNewCardClose} />}
                    </>
                </Elements>
            </StripeProvider>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(PaymentControlled)
