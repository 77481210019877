import React from "react"
import config from "./config"
import axios from "axios"
import {Map} from "immutable"
import Spinner from "./Spinner"

class PayPalButton extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            overlay: false,
            hidden: props.hidden,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.hidden !== prevProps.hidden) {
            this.setState({hidden: this.props.hidden})
        }
    }

    componentDidMount = () => {
        const options = Map({
            "client-id": config.paypalClientId,
            "disable-funding": "credit,card",
        })

        const url = "https://www.paypal.com/sdk/js?" + options.map((v, k) => `${k}=${v}`).join("&")
        this.load(url).then(() => {
            window.paypal
                .Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    custom_id: this.props.amount.toString(),
                                    amount: {
                                        value: this.props.totalamount,
                                    },
                                },
                            ],
                        })
                    },
                    onApprove: (data, actions) => {
                        this.setState({overlay: true})
                        axios
                            .post(config.apiEndpoint + "paypal/capture", {orderid: data.orderID})
                            .then(r => {
                                this.setState({overlay: false})
                                this.props.onSuccess()
                            })
                            .catch(e => {
                                this.setState({overlay: false})
                                console.log(e)
                                this.props.onFailure()
                            })
                    },
                    onCancel: () => this.props.onFailure("Payment Canceled"),
                    onError: err => {
                        console.log(err)
                        this.props.onFailure()
                    },
                    style: {
                        height: 40,
                        size: "small",
                    },
                })
                .render("#paypal-button-container")
        })
    }

    load = (url, callback) => {
        const existingScript = document.getElementById("paypal")
        if (!existingScript) {
            return new Promise(resolve => {
                const script = document.createElement("script")
                script.src = url
                document.body.appendChild(script)

                script.onload = () => {
                    resolve()
                }
            })
        } else {
            Promise.resolve()
        }
    }

    render() {
        return (
            <>
                {this.state.overlay && (
                    <div>
                        Processing the payment... <Spinner />
                        <div className="disable-all"></div>
                    </div>
                )}
                <div id="paypal-button-container" style={this.state.hidden ? {display: "none"} : null}></div>
            </>
        )
    }
}

export default PayPalButton
