import React, {useState} from "react"
import {Typeahead} from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "react-bootstrap-typeahead/css/Typeahead-bs4.min.css"
import config from "../config"

const types = config.transactionTypes

const sort = (a, b) => {
    a = a.label.toLowerCase()
    b = b.label.toLowerCase()
    return a < b ? -1 : a > b ? 1 : 0
}

const TransactionTypesSelector = props => {
    const [tags, setTags] = useState(types.filter(t => props.selected.includes(t.id)))

    return (
        <Typeahead
            id="transtype-select"
            selected={tags}
            onChange={selected => {
                setTags(selected)
                const value = selected.map(item => item.id)
                const title = selected.map(item => item.label).join(", ")
                props.onChange(value, title)
            }}
            options={types.sort(sort)}
            placeholderText="Select Types"
            multiple
        />
    )
}

const filter = {
    title: "Types",
    Format: value => {
        if (!value) return ""
        return types
            .filter(u => value.includes(u.id))
            .map(u => u.label)
            .join(", ")
    },
    Input: props => {
        const value = props.value === "" ? [] : props.value
        return <TransactionTypesSelector selected={value} onChange={props.onChange} />
    },
}

export default filter
