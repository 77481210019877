import React from "react"
import {Button} from "react-bootstrap"
import Icon from "./Icon"
import QuestionTypes from "./data/QuestionTypes"

class Template extends React.PureComponent {
    state = {
        collapsed: true,
    }

    render() {
        const template = this.props.template

        return (
            <tr>
                <td style={{width: "70px"}}>{QuestionTypes[template.get("type")]}</td>
                <td style={{maxWidth: "150px", width: "150px", wordWrap: "break-word"}}>{template.get("name")}</td>
                <td>
                    {template.get("questions").size > 0 && template.get("questions").get(0)}
                    {template.get("questions").size > 1 && (
                        <React.Fragment>
                            {" "}
                            <Icon
                                size="lg"
                                icon={this.state.collapsed ? "chevron-circle-right" : "chevron-circle-down"}
                                onClick={() => this.setState({collapsed: !this.state.collapsed})}
                            />
                            {!this.state.collapsed &&
                                template
                                    .get("questions")
                                    .filter((v, k) => k > 0)
                                    .map((q, k) => <div key={k}>{q}</div>)}
                        </React.Fragment>
                    )}
                </td>
                <td style={{width: "150px", textAlign: "right"}}>
                    <Button size="sm" variant="info" onClick={this.props.onEdit}>
                        <Icon icon="pencil-alt" /> Edit
                    </Button>{" "}
                    <Button size="sm" variant="danger" onClick={this.props.onDelete}>
                        Delete
                    </Button>
                </td>
            </tr>
        )
    }
}

export default Template
