import React from "react"
import {formatMoney} from "../../tools"
import {Button} from "react-bootstrap"

class EarningsRow extends React.PureComponent {
    render() {
        const row = this.props.row
        return (
            <tr>
                <td>{row.get("email")}</td>
                <td>{row.get("minutes_in")}</td>
                <td>{formatMoney(row.get("rate_in"), 4)}</td>
                <td>{formatMoney(row.get("price_in"), 2)}</td>
                <td>{row.get("minutes_out")}</td>
                <td>{formatMoney(row.get("rate_out"), 4)}</td>
                <td>{formatMoney(row.get("price_out"), 2)}</td>
                <td>{formatMoney(row.get("price_in") + row.get("price_out"), 2)}</td>
                <td className="right">
                    <Button variant="info" size="sm" onClick={() => this.props.onSelect(row.get("email"))}>
                        View CRDs
                    </Button>
                </td>
            </tr>
        )
    }
}

export default EarningsRow
