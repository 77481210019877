import React from "react"
import {Form, Row, Col, Alert, Button} from "react-bootstrap"
import RoutingTypeSelect from "../RoutingTypeSelect"
import RoutingValueSelect from "../RoutingValueSelect"

class ContactEdit extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            enableCallsLimit: props.contact.get("maxcalls") > 0,
        }
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let contact = this.props.contact.set(field, value)
        if (field === "dest_type") {
            contact = contact.set("dest_value", "")
        }
        if (field === "mode" && value === "sip" && !this.props.contact.get("sipusername")) {
            contact = contact.set("sipusername", "{did}").set("sipport", "5060")
        }
        this.props.onChange(contact)
    }

    changePwd = () => {
        this.setState({changepwd: true})
        this.changeFieldValue("password", "")
    }

    onChangeEnableCallsLimit = e => {
        this.setState({
            enableCallsLimit: e.target.checked,
        })
        if (!e.target.checked) {
            this.changeFieldValue("maxcalls", "0")
        }
    }

    changeUsernameDefault = () => {
        this.changeFieldValue("sipusername", "{did}")
    }

    render() {
        const contact = this.props.contact
        const asterisk = `[trunk-in]
host=159.203.145.108
disallow=all
allow=ulaw
context=trunkinbound
dtmfmode=rfc2833
type=peer
insecure=port,invite
nat=yes
canreinvite=no`

        return (
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                        Contact Title
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            name="title"
                            placeholder="Title"
                            defaultValue={contact.get("title")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>

                {this.props.user.get("clienttype") === "wholesale" && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">
                            Connection Type
                        </Form.Label>
                        <Col sm="8">
                            <Form.Check
                                inline
                                label="DID"
                                type="radio"
                                name="mode"
                                value="did"
                                defaultChecked={contact.get("mode") === "did"}
                                onChange={this.changeField}
                            />

                            <Form.Check
                                inline
                                label="SIP"
                                type="radio"
                                name="mode"
                                value="sip"
                                defaultChecked={contact.get("mode") === "sip"}
                                onChange={this.changeField}
                            />
                        </Col>
                    </Form.Group>
                )}

                {contact.get("mode") === "did" && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">
                            Phone Number
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name="number"
                                placeholder="Phone Number"
                                value={contact.get("number")}
                                onChange={this.changeField}
                            />
                        </Col>
                    </Form.Group>
                )}
                {contact.get("mode") === "sip" && (
                    <>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Destination #
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control
                                    type="text"
                                    name="sipusername"
                                    placeholder="Destination Username"
                                    value={contact.get("sipusername")}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm="2">
                            <Button onClick={this.changeUsernameDefault} variant="primary" size="block">Default</Button>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Destination IP
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="sipip"
                                    placeholder="Destination IP"
                                    value={contact.get("sipip")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Destination Port
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="sipport"
                                    placeholder="Destination Port"
                                    value={contact.get("sipport")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                    </>
                )}
                {this.props.clients && this.props.clients.size > 0 && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">
                            Client
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control
                                as="select"
                                name="clientuserid"
                                value={contact.get("clientuserid") || 0}
                                onChange={this.changeField}
                            >
                                {!contact.get("clientuserid") && <option>Select Client</option>}
                                {this.props.clients.map(client => (
                                    <option key={client.get("id")} value={client.get("id")}>
                                        {client.get("contactname")}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                )}

                <Form.Group as={Row}>
                    <Form.Label column sm="4">&nbsp;</Form.Label>
                    <Col sm="8">
                        <Form.Check
                            inline
                            type="checkbox"
                            checked={this.state.enableCallsLimit}
                            onChange={this.onChangeEnableCallsLimit}
                            label="Enable Call Limits"
                        />
                    </Col>
                </Form.Group>

                {this.state.enableCallsLimit && (
                    <>
                        <Form.Group as={Row} className={this.props.errors.has("maxcalls") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Max Simultaneous Calls
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="maxcalls"
                                    placeholder="Max Simultaneous Calls"
                                    defaultValue={contact.get("maxcalls")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Fallback Action
                            </Form.Label>
                            <Col sm={4}>
                                <RoutingTypeSelect
                                    type="contact"
                                    name="dest_type"
                                    value={contact.get("dest_type")}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm={4}>
                                <RoutingValueSelect
                                    name="dest_value"
                                    type={contact.get("dest_type")}
                                    value={contact.get("dest_value")}
                                    onChange={this.changeField}
                                    exclude={contact.get("id") ? [contact.get("id")] : []}
                                />
                            </Col>
                        </Form.Group>
                    </>
                )}

                {contact.get("mode") === "sip" && (
                    <>
                        <Alert variant="info">
                            SIP and RTP media will arrive from 159.203.145.108. UDP 10000 through 65535 ports range is used. For SIP
                            signaling, port UDP 5060 is used.
                            <div style={{paddingTop: "20pt", paddingLeft: "20pt"}}>
                                You can configure trunk on your asterisk based pbx in the following way:
                                <br />
                                <br />
                                <pre>{asterisk}</pre>
                            </div>
                        </Alert>
                    </>
                )}
            </Form>
        )
    }
}
export default ContactEdit
