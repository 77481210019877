import * as actions from "../actions/dids"
import axios from "axios"
import config from "../config"
import Immutable, {List, fromJS} from "immutable"
import * as loading from "../actions/loading"

const didsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "DIDS_LOAD":
                {
                    const f = store.getState().dids.get("filter")

                    let filter = f ? JSON.stringify(f.filter(v => !!v).toJS()) : ""
                    store.dispatch(actions.loading())
                    axios
                        .get(`${config.apiEndpoint}dids?filter=` + encodeURIComponent(filter))
                        .then(response => {
                            let data = List([])
                            for (var item of response.data) {
                                data = data.push(fromJS(item))
                            }
                            store.dispatch(actions.loaded(data))
                        })
                        .catch(e => {
                            console.log(e)
                            store.dispatch(actions.loaderror("Error loading"))
                        })
                }
                break

            case "DIDS_FILTERED_LOAD":
                {
                    const f = action.filter
                    let filter = f ? JSON.stringify(f.filter(v => !!v).toJS()) : ""
                    store.dispatch(loading.start())
                    axios
                        .get(`${config.apiEndpoint}dids?filter=` + encodeURIComponent(filter))
                        .then(response => {
                            store.dispatch(loading.end())
                            action.resolve(fromJS(response.data))
                        })
                        .catch(e => {
                            store.dispatch(loading.end())
                            if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                            else action.reject(e.message)
                        })
                }
                break

            case "DIDS_SAVE":
                const did = action.did.toJS()
                const noid = action.did.delete("id").toJS()
                axios
                    .put(`${config.apiEndpoint}dids/${did.id}`, noid)
                    .then(response => {
                        store.dispatch(actions.saved(fromJS(response.data)))
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_BULK_ADD":
                axios
                    .post(`${config.apiEndpoint}dids`, action.data)
                    .then(response => {
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}dids`, {data: {ids: action.ids}})
                    .then(response => {
                        store.dispatch(actions.deleted(action.ids))
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_CHANGE_ROUTING":
                axios
                    .post(`${config.apiEndpoint}dids/routing`, {ids: action.ids, routing: action.routing})
                    .then(response => {
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_TRANSFER":
                axios
                    .post(`${config.apiEndpoint}dids/transfer`, {ids: action.ids, userid: action.userid})
                    .then(response => {
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_CHANGE_GROUP":
                axios
                    .post(`${config.apiEndpoint}dids/groups`, {
                        ids: action.ids,
                        group: action.group,
                    })
                    .then(response => {
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_BUY":
                let perstate = action.perstate
                if (action.didtype === "tollfree") {
                    perstate = {TF: action.dids}
                }

                axios
                    .post(`${config.apiEndpoint}job`, {
                        action: "order",
                        dids: action.dids,
                        group: action.group,
                        perstate: perstate,
                        routing: action.routing,
                        features: action.features,
                        tollfree: action.didtype === "tollfree",
                    })
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "DIDS_SEARCH":
                {
                    store.dispatch(loading.start())
                    const q = action.form
                        .filter(v => !!v)
                        .map((v, k) => k + "=" + (Immutable.isSet(v) ? v.join(",") : encodeURIComponent(v)))
                        .join("&")

                    axios
                        .get(`${config.apiEndpoint}number/search?${q}`)
                        .then(response => {
                            store.dispatch(loading.end())
                            action.resolve(fromJS(response.data))
                        })
                        .catch(e => {
                            store.dispatch(loading.end())
                            if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                            else action.reject(e.message)
                        })
                }
                break

            case "DIDS_ORDER_SINGLE":
                axios
                    .get(`${config.apiEndpoint}number/order?number=${action.number}`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default didsMiddleware
