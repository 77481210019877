import React, {Component} from "react"
import {Route, Switch} from "react-router"
import {connect} from "react-redux"

import Logout from "./Logout"
import UsersControlled from "./UsersControlled"
import ContactsControlled from "./contacts/ContactsControlled"
import ClientsControlled from "./clients/ClientsControlled"
import DidsControlled from "./DidsControlled"
import DidGroupsControlled from "./DidGroupsControlled"
import JobsControlled from "./JobsControlled"
import IvrsControlled from "./IvrsControlled"
import RoutingGroupsControlled from "./routinggroups/RoutingGroupsControlled"
import IvrLogsControlled from "./reports/ivrlog/IvrLogsControlled"
import CdrsControlled from "./CdrsControlled"
import EarningsControlled from "./reports/earnings/EarningsControlled"
import UserEarningsControlled from "./reports/userearnings/UserEarningsControlled"
import CdrsGroupedControlled from "./CdrsGroupedControlled"
import BotsControlled from "./BotsControlled"
import DidsReportControlled from "./reports/didreport/DidReportControlled"
import SpendingControlled from "./reports/spending/SpendingControlled"
import TemplatesControlled from "./TemplatesControlled"
import LiveCallsControlled from "./reports/livecalls/LiveCallsControlled"
import BlacklistControlled from "./blacklist/BlacklistControlled"
import TransactionsControlled from "./transactions/TransactionsControlled"
import PaymentControlled from "./PaymentControlled"
import SmsEndpointsControlled from "./smsendpoints/SmsEndpointsControlled"
import SmsControlled from "./sms/SmsControlled"
import DidsOrderSingleControlled from "./DidsOrderSingleControlled"
import Home from "./Home"

class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/users/" component={UsersControlled} />
                <Route path="/contacts/" component={ContactsControlled} />
                <Route path="/clients/" component={ClientsControlled} />
                <Route path="/dids/groups/" component={DidGroupsControlled} />
                <Route path="/dids/buy" component={DidsOrderSingleControlled} />
                <Route path="/dids/" component={DidsControlled} />
                <Route path="/blacklist/" component={BlacklistControlled} />
                <Route path="/jobs/" component={JobsControlled} />
                <Route path="/ivrs/" component={IvrsControlled} />
                <Route path="/ivrlogs/" component={IvrLogsControlled} />
                <Route path="/routinggroups/" component={RoutingGroupsControlled} />
                <Route path="/cdrs/" component={CdrsControlled} />
                <Route path="/routinggroups/" component={RoutingGroupsControlled} />
                <Route path="/livecalls/" component={LiveCallsControlled} />
                <Route path="/virtualassistants/templates/" component={TemplatesControlled} />
                <Route path="/virtualassistants/" component={BotsControlled} />
                <Route path="/reports/group/" component={CdrsGroupedControlled} />
                <Route path="/reports/dids/" component={DidsReportControlled} />
                <Route path="/reports/spending/" component={SpendingControlled} />
                <Route path="/topup/" component={PaymentControlled} />
                <Route exact path="/sms/" component={SmsEndpointsControlled} />
                <Route path="/sms/inbound/" render={props => <SmsControlled {...props} direction="in" />} />
                <Route path="/sms/outbound/" render={props => <SmsControlled {...props} direction="out" />} />
                <Route
                    path="/reports/earnings/"
                    component={this.props.user.get("role") === "admin" ? EarningsControlled : UserEarningsControlled}
                />
                <Route path="/transactions/" component={TransactionsControlled} />
                <Route path="/logout/" component={Logout} />
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(Routes)
