import React from "react"
import {connect} from "react-redux"
import {Button, Modal} from "react-bootstrap"

import * as actions from "../actions/blacklist"
import SpinnerButton from "../SpinnerButton"
import Icon from "../Icon"
import iconDids from "../images/menu/dids.svg"

import BlacklistEdit from "./BlacklistEdit"
import {toast} from "react-toastify"

class BlacklistEditControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            blacklist: props.blacklist,
        }
    }

    onSaveBlacklist = () => {
        this.setState({saving: true})
        actions
            .save(this.state.blacklist, this.props.dispatch)
            .then(() => {
                toast.success("Blacklist Item Saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onReload()
                this.props.onClose()
            })
            .catch(error => {
                console.log(error)
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChange = blacklist => {
        this.setState({blacklist: blacklist})
    }

    render() {
        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{height: "46px", marginRight: "20px"}} />
                            {this.state.blacklist.get("id") === 0 && <span>New Blacklist Items</span>}
                            {this.state.blacklist.get("id") !== 0 && <span>Edit Blacklist Item</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <BlacklistEdit blacklist={this.state.blacklist} onChange={this.onChange} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveBlacklist} active={this.state.saving} />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect()(BlacklistEditControlled)
