import React from "react"
import {Form, Row, Col, Button} from "react-bootstrap"

class BlacklistEdit extends React.PureComponent {
    constructor(props) {
        super(props)
        this.fileRef = React.createRef()
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let blacklist = this.props.blacklist.set(field, value)
        if (field === "csv") {
            blacklist = blacklist.set("mode", value === null ? "list" : "file")
        }
        this.props.onChange(blacklist)
    }

    render() {
        const blacklist = this.props.blacklist
        const isNew = !blacklist.get("id")
        const clidsLabel = isNew ? "Caller IDs" : "Caller ID"
        const clidsSublabel = isNew ? "(line by line)" : ""
        return (
            <Form>
                {isNew && !blacklist.get("csv") && (
                    <div style={{textAlign: "right", marginBottom: "10px"}}>
                        <input
                            style={{display: "none"}}
                            ref={this.fileRef}
                            type="file"
                            name="file"
                            onChange={e => this.changeFieldValue("csv", e.target.files[0])}
                            accept=".txt,.csv"
                        />
                        <Button variant="link" onClick={() => this.fileRef.current.click()}>
                            Upload CSV
                        </Button>
                    </div>
                )}
                {blacklist.get("csv") && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            CSV file
                        </Form.Label>
                        <Col sm="10">
                            <div style={{lineHeight: "32px"}}>
                                {blacklist.get("csv").name} ({Math.round(blacklist.get("csv").size / 1024)} Kb){" "}
                                <Button variant="danger" onClick={() => this.changeFieldValue("csv", null)}>
                                    Delete
                                </Button>
                            </div>
                        </Col>
                    </Form.Group>
                )}
                {!blacklist.get("csv") && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            {clidsLabel}{" "}
                            {!!clidsSublabel && (
                                <div>
                                    <sup>{clidsSublabel}</sup>
                                </div>
                            )}
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                as={isNew ? "textarea" : "input"}
                                rows={5}
                                type="text"
                                name="clid"
                                placeholder={clidsLabel}
                                defaultValue={blacklist.get("clid")}
                                onChange={this.changeField}
                            />
                        </Col>
                    </Form.Group>
                )}
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Notes
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            as="textarea"
                            type="text"
                            name="notes"
                            placeholder="Notes"
                            defaultValue={blacklist.get("notes")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
            </Form>
        )
    }
}
export default BlacklistEdit
