export function start() {
    return {
        type: "LOADING_START",
    }
}

export function end() {
    return {
        type: "LOADING_END",
    }
}
