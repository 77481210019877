import React, {Component} from "react"
import {Button, Form, Row, Col} from "react-bootstrap"
import Icon from "../Icon"
import Contacts from "../contacts/Contacts"

class ClientEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            client: props.client,
            changepwd: props.client.get("id") === 0 ? true : false,
        }
    }

    componentDidUpdate = nextProps => {
        if (this.props.client !== nextProps.client) {
            this.setState({
                client: this.props.client,
            })
        }
    }

    changePwd = () => {
        this.setState({changepwd: true})
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        const client = this.state.client.set(field, value)
        this.props.onChange(client)
    }

    changeContact = (key, value) => {
        const contacts = this.state.client.get("contacts").set(key, value)
        const client = this.state.client.set("contacts", contacts)
        this.props.onChange(client)
    }

    render() {
        return (
            <Form>
                <Form.Group as={Row} className={this.props.errors.has("contactname") ? "error" : ""}>
                    <Form.Label column sm="3">
                        Contact Name
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            name="contactname"
                            placeholder="Contact Name"
                            defaultValue={this.state.client.get("contactname")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Email
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            defaultValue={this.state.client.get("email")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Password
                    </Form.Label>
                    <Col sm="9">
                        {!this.state.changepwd && (
                            <Button className="changebt" onClick={this.changePwd}>
                                Change
                            </Button>
                        )}
                        {this.state.changepwd && (
                            <Form.Control name="password" type="text" placeholder="Password" onChange={this.changeField} />
                        )}
                    </Col>
                </Form.Group>
                {this.state.client.get("id") > 0 && (
                    <>
                        <hr />
                        <Form.Group as={Row}>
                            <Col sm={12}>
                                <Button variant="success" className="addnew" onClick={() => this.props.onEditContact()}>
                                    <Icon icon="plus" /> Add Contact
                                </Button>
                            </Col>
                        </Form.Group>
                        <Contacts data={this.state.client.get("contacts")} onEdit={this.props.onEditContact} nochangeclient />
                    </>
                )}
            </Form>
        )
    }
}
export default ClientEdit
