import React from "react"
import Icon from "./Icon"
import {Button} from "react-bootstrap"

const ReloadIcon = props => (
    <Button variant="outline-secondary refresh" onClick={() => props.onClick()} title="Refresh">
        <Icon icon="sync-alt" />
    </Button>
)

export default ReloadIcon
