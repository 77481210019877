import React from "react"
import {connect} from "react-redux"
import {Form} from "react-bootstrap"
import {store} from "../data/store"

const mapStateToProps = state => {
    return {
        groups: state.groups.get("data"),
    }
}

const filter = {
    title: "DID Group",
    Format: value => {
        const group = store
            .getState()
            .groups.get("data")
            .filter(g => g.get("id") === parseInt(value, 10))
        if (group.size === 1) return group.get(0).get("name")
        return ""
    },
    Input: connect(mapStateToProps)(props => {
        if (!props.groups) return ""
        return (
            <Form.Control
                as="select"
                name="state"
                value={props.value}
                onChange={e =>
                    props.onChange(
                        e.target.value,
                        props.groups
                            .filter(g => g.get("id") === parseInt(e.target.value, 10))
                            .get(0)
                            .get("name"),
                    )
                }
            >
                <option value="">Select Group</option>
                {props.groups.map(group => (
                    <option value={group.get("id")} key={group.get("id")}>
                        {group.get("name")}
                    </option>
                ))}
            </Form.Control>
        )
    }),
}

export default filter
