import React, {Component} from "react"
import {Button, Form, Row, Col, Table} from "react-bootstrap"
import Question from "./Question"
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc"
import Icon from "./Icon"

const DragHandle = SortableHandle(({style}) => <span style={{...style, ...{cursor: "move"}}}>{"::::"}</span>)

const TableBodySortable = SortableContainer(({children, displayRowCheckbox}) => <tbody>{children}</tbody>)

const QuestionSortable = SortableElement(({data, ...other}) => {
    return <Question {...other} handle=<DragHandle /> />
})

class BotEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bot: props.bot,
        }
    }

    componentDidUpdate = prevProps => {
        if (prevProps.bot !== this.props.bot) {
            this.setState({
                bot: this.props.bot,
            })
        }
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let bot = this.state.bot.set(field, value)
        this.props.onChange(bot)
    }

    onMenuItemDelete = i => {
        if (!window.confirm("Delete?")) return
        const items = this.state.bot.get("items").delete(i)
        this.props.onChange(this.state.bot.set("items", items))
    }

    onMenuItemChange = (i, value) => {
        const items = this.state.bot.get("items").set(i, value)
        this.props.onChange(this.state.bot.set("items", items))
    }

    formatFilename = filename => {
        return filename
            .replace(/\.([^.]+)$/, "")
            .replace(/[_-]+/g, " ")
            .trim()
    }

    onSelectFile = e => {
        this.changeFieldValue("newrecording_file", e.target.files[0])
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let questions = this.state.bot.get("questions")
        if (newIndex < 0) return
        const sourceItem = questions.get(oldIndex)
        const targetItem = questions.get(newIndex)
        questions = questions.delete(oldIndex)
        const targetIndex = questions.indexOf(targetItem) + (oldIndex < newIndex ? 1 : 0)
        questions = questions.insert(targetIndex, sourceItem)
        this.changeFieldValue("questions", questions)
    }

    render() {
        const bot = this.state.bot

        return (
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                        Name
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Virtual Assistant Name"
                            defaultValue={bot.get("name")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                        Assistant Token
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            name="token"
                            placeholder="Virtual Assistant Token"
                            defaultValue={bot.get("token")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                        Non Quality Message
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            name="nonqualitymessage"
                            placeholder="Non Quality Message"
                            defaultValue={bot.get("nonqualitymessage")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <hr />
                <Form.Group as={Row}>
                    <Col sm="12">
                        <Button variant="success" className="addnew" onClick={this.props.onQuestionAdd}>
                            <Icon icon="plus" /> Add Question
                        </Button>
                    </Col>
                </Form.Group>
                {bot.has("questions") && bot.get("questions").filter(q => q.get("status") !== "deleted").size > 0 && (
                    <Table striped>
                        <thead>
                            <tr>
                                <th style={{width: "5%"}} />
                                <th>Type</th>
                                <th>ID</th>
                                <th>Question(s)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <TableBodySortable
                            onSortStart={(_, event) => event.preventDefault()}
                            onSortEnd={this.onSortEnd}
                            useDragHandle
                            helperClass="sortableHelper"
                        >
                            {bot.get("questions").map((item, key) => (
                                <QuestionSortable
                                    index={key}
                                    id={key}
                                    key={key}
                                    question={item}
                                    onEdit={() => this.props.onQuestionEdit(key)}
                                    onDelete={() => this.props.onQuestionDelete(key)}
                                />
                            ))}
                        </TableBodySortable>
                    </Table>
                )}
            </Form>
        )
    }
}
export default BotEdit
