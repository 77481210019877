import React from "react"
import {Table} from "react-bootstrap"
import Template from "./Template"

class Templates extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Question(s)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={4}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((item, key) => (
                        <Template
                            index={key}
                            id={key}
                            key={item.get("id")}
                            template={item}
                            onEdit={() => this.props.onEdit(item)}
                            onDelete={() => this.props.onDelete(item)}
                        />
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Templates
