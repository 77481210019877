import React from "react"
import {Form} from "react-bootstrap"
import config from "./config"

const RoutingTypeSelect = React.memo(props => {
    let destinations
    switch (props.type) {
        case "did":
        case "group":
            destinations = Object.values(config.destinations.did)
            break
        case "group_disabled":
            destinations = Object.values(config.destinations.group_disabled)
            break
        case "ivr":
            destinations = Object.values(config.destinations.ivr)
            break
        case "contact":
            destinations = Object.values(config.destinations.contact)
            break
        case "routinggroup":
            destinations = Object.values(config.destinations.routinggroup)
            break
        case "routinggroupdefault":
            destinations = Object.values(config.destinations.routinggroup_default)
            break
        default:
            alert("Unknown routing type: " + props.type)
    }

    const value = props.value !== undefined ? props.value : ""

    return (
        <Form.Control as="select" name={props.name} value={value} onChange={props.onChange}>
            {!props.value && !props.allowEmpty && (
                <option disabled value="">
                    Select Destination
                </option>
            )}
            {!props.value && !!props.allowEmpty && <option value="">None</option>}
            {destinations.map(destination => (
                <option value={destination.value} key={destination.value}>
                    {destination.title}
                </option>
            ))}
        </Form.Control>
    )
})

export default RoutingTypeSelect
