export function load() {
    return {
        type: "DIDS_LOAD",
    }
}

export function loadFiltered(filter, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_FILTERED_LOAD",
            filter: filter,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function loading() {
    return {
        type: "DIDS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "DIDS_LOADED",
        data: data,
    }
}

export function saved(did) {
    return {
        type: "DIDS_SAVED",
        did: did,
    }
}

export function loaderror(msg) {
    return {
        type: "DIDS_LOADERROR",
        msg: msg,
    }
}

export function sort(sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_SORT",
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function save(did, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_SAVE",
            did: did,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function bulkadd(data, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_BULK_ADD",
            data: data,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(ids, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_DELETE",
            ids: ids,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function changeRouting(ids, routing, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_CHANGE_ROUTING",
            ids: ids,
            routing: routing,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function transfer(ids, userid, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_TRANSFER",
            ids: ids,
            userid: userid,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function changeGroup(ids, group, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_CHANGE_GROUP",
            ids: ids,
            group: group,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function deleted(ids) {
    return {
        type: "DIDS_DELETED",
        ids: ids,
    }
}

export function buy(dids, perstate, routing, group, features, type, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_BUY",
            dids: dids,
            perstate: perstate,
            routing: routing,
            group: group,
            features: features,
            didtype: type,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function setSort(sortfield, sortdir) {
    return {
        type: "DIDS_SET_SORT",
        sortfield: sortfield,
        sortdir: sortdir,
    }
}

export function setFilter(filter) {
    return {
        type: "DIDS_SET_FILTER",
        filter: filter,
    }
}

export function setPage(page) {
    return {
        type: "DIDS_SET_PAGE",
        page: page,
    }
}

export function search(form, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_SEARCH",
            form: form,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function orderSingle(number, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "DIDS_ORDER_SINGLE",
            number: number,
            resolve: resolve,
            reject: reject,
        })
    })
}
