import React, {Component} from "react"
import {Modal, Button} from "react-bootstrap"
import Icon from "./Icon"
import iconDids from "./images/menu/dids.svg"

class DidsOrderConfirm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: props.data,
        }
    }

    render() {
        const perstateKeys = Object.keys(this.state.data.perstate)
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            Order Confirmation
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        You are about to purchase {this.state.data.dids} DIDs
                        {perstateKeys.length > 0 && (
                            <React.Fragment>
                                {" "}
                                from the following states:
                                <ul>
                                    {perstateKeys.sort().map(state => (
                                        <li key={state}>
                                            {state}: {this.state.data.perstate[state]}
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )}
                        <br />
                        Options:
                        <ul>
                            {this.state.data.type === "tollfree" && <li>Toll-Free</li>}
                            <li>Features: {this.state.data.features.join(", ")}</li>
                        </ul>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="info" className="continue" onClick={this.props.onBuy}>
                        Purchase Now
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DidsOrderConfirm
