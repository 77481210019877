import React, {Component} from "react"
import {connect} from "react-redux"
import Login from "./Login"
import {Map} from "immutable"
import {toast} from "react-toastify"
import * as actions from "./actions/auth"
import {replace} from "connected-react-router"

class LoginControlled extends Component {
    constructor(props) {
        super(props)

        let email = props.search.startsWith("?email=") ? props.search.substr(7) : ""

        this.state = {
            active: false,
            credentials: Map({
                email: email,
                password: "",
            }),
        }
    }

    onChange = credentials => {
        this.setState({credentials: credentials})
    }

    onLogin = () => {
        this.setState({active: true})
        actions
            .login(this.state.credentials.get("email"), this.state.credentials.get("password"), this.props.dispatch)
            .then(() => {
                this.props.dispatch(replace("/"))
            })
            .catch(error => {
                this.setState({active: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    render() {
        return <Login credentials={this.state.credentials} onChange={this.onChange} onLogin={this.onLogin} active={this.state.active} />
    }
}

const mapStateToProps = state => {
    return {
        authorized: state.auth.get("authorized"),
        user: state.auth.get("user"),
        search: state.router.location.search,
    }
}

export default connect(mapStateToProps)(LoginControlled)
