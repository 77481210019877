import React, {Component} from "react"
import {Form, Row, Col, InputGroup, Button} from "react-bootstrap"
import {Map, List, is} from "immutable"
import QuestionTypes from "./data/QuestionTypes"
import {Typeahead} from "react-bootstrap-typeahead"
import "../node_modules/react-bootstrap-typeahead/css/Typeahead-bs4.min.css"
import Icon from "./Icon"

class QuestionEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            question: props.question,
            templates: props.templates,
            template: [],
        }

        this.qtypes = Map(QuestionTypes)
    }

    componentDidUpdate = prevProps => {
        if (this.props.question !== prevProps.question) {
            let state = {question: this.props.question}
            if (this.props.question !== this.state.question) {
                state["template"] = []
            }
            this.setState(state)
        }
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeConstraintsField = e => {
        this.changeConstraintsFieldValue(e.target.name, e.target.value)
    }

    changeConstraintsFieldValue = (name, value) => {
        if (["min", "max", "target_min", "target_max"].includes(name)) {
            value = parseInt(value, 10)
        }
        this.changeFieldValue("constraints", this.state.question.get("constraints").set(name, value))
    }

    changeFieldValue = (field, value) => {
        if (["attempts"].includes(field)) {
            value = parseInt(value, 10)
        }
        if (["questions", "retries"].includes(field) && typeof value === "string") {
            value = List(value.split("\n"))
        }
        const question = this.state.question.set(field, value)
        this.props.onChange(question)
    }

    onBlur = e => {
        const v1 = this.state.question.get(e.target.name)
        const v2 = v1
            .map(v =>
                v
                    .trim()
                    .replace(/^"/, "")
                    .replace(/",*$/, ""),
            )
            .filter(v => !!v)

        if (!is(v1, v2)) {
            this.changeFieldValue(e.target.name, v2)
        }
    }

    onTemplateSelect = template => {
        if (!template) {
            this.setState({
                template: [],
            })
            return
        }
        const t = this.state.templates.filter(t => t.get("id") === template["id"]).get(0)
        let question = this.state.question
            .set("type", t.get("type"))
            .set("questions", t.get("questions"))
            .set("retries", t.get("retries"))
            .set("attempts", t.get("attempts"))
            .set("constraints", t.get("constraints"))
        this.setState({
            template: [template],
            question: question,
        })
        this.props.onChange(question)
    }

    render() {
        return (
            <Form>
                {!this.state.question.get("id") && (
                    <>
                        {this.state.question.get("position") === undefined && (
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                    Template
                                </Form.Label>
                                <Col sm="10">
                                    <InputGroup>
                                        <Typeahead
                                            placeholder="Select from template"
                                            style={{flex: "1 1 auto"}}
                                            id={`typeahead`}
                                            selected={this.state.template}
                                            onChange={e => this.onTemplateSelect(e[0])}
                                            options={this.state.templates.map(t => t.set("label", t.get("name"))).toJS()}
                                        />
                                        <InputGroup.Append>
                                            <Button variant="secondary" onClick={() => this.setState({template: []})}>
                                                <Icon icon="times" />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        )}
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Type
                            </Form.Label>
                            <Col sm="10">
                                {this.qtypes.keySeq().map(k => (
                                    <Form.Check
                                        inline
                                        key={k}
                                        name="type"
                                        value={k}
                                        type="radio"
                                        label={this.qtypes.get(k)}
                                        onChange={this.changeField}
                                        checked={this.state.question.get("type") === k}
                                    />
                                ))}
                            </Col>
                        </Form.Group>
                    </>
                )}
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        ID
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Question ID"
                            value={this.state.question.get("name")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Questions
                        <br />
                        <sub>line by line</sub>
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            as="textarea"
                            name="questions"
                            placeholder="Questions"
                            value={this.state.question.get("questions").join("\n")}
                            rows={this.state.question.get("questions").size + 1}
                            onChange={this.changeField}
                            onBlur={this.onBlur}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Retries
                        <br />
                        <sub>line by line</sub>
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            as="textarea"
                            name="retries"
                            placeholder="Retries"
                            value={this.state.question.get("retries").join("\n")}
                            rows={this.state.question.get("retries").size + 1}
                            onChange={this.changeField}
                            onBlur={this.onBlur}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Attempts
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="attempts"
                            placeholder="Attempts"
                            value={this.state.question.get("attempts")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                {this.state.question.get("type") === "numeric" && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Constraints
                            </Form.Label>
                            <Col sm="10">
                                <Row>
                                    <Col sm="3">
                                        <Form.Group>
                                            <Form.Label>Min</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="min"
                                                placeholder="Min"
                                                value={this.state.question.get("constraints").get("min")}
                                                title="The lowest possible accepted value. If lower than this, the user will be reprompted"
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group>
                                            <Form.Label>Max</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="max"
                                                placeholder="Max"
                                                value={this.state.question.get("constraints").get("max")}
                                                title="The greatest possible accepted value. If greater than this, the user will be reprompted"
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group>
                                            <Form.Label>Target Min</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="target_min"
                                                placeholder="Target Min"
                                                value={this.state.question.get("constraints").get("target_min")}
                                                title="The lowest value in the target range of the question. If lower than this, the question will be marked as failed."
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group>
                                            <Form.Label>Target Max</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="target_max"
                                                placeholder="Target Max"
                                                value={this.state.question.get("constraints").get("target_max")}
                                                title="The greatest value that answers the question correctly. If greater than this, the question will be marked as failed."
                                                onChange={this.changeConstraintsField}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}

                {this.state.question.get("type") === "text" && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Constraints
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="regex_pattern"
                                    placeholder="Reg-ex pattern"
                                    value={this.state.question.get("constraints").get("regex_pattern")}
                                    title="The reg-ex pattern used to validate the value. If the input does not match this pattern, than the user will be reprompted"
                                    onChange={this.changeConstraintsField}
                                />
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}

                {this.state.question.get("type") === "yesno" && (
                    <React.Fragment>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Constraints
                            </Form.Label>
                            <Col sm="10">
                                <Form.Check
                                    inline
                                    name="accept_yes"
                                    label="YES considered as valid response"
                                    type="checkbox"
                                    checked={this.state.question.get("constraints").get("accept_yes")}
                                    onChange={e => this.changeConstraintsFieldValue(e.target.name, e.target.checked)}
                                />
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}
            </Form>
        )
    }
}
export default QuestionEdit
