import React from "react"
import {formatMoney, formatPhone} from "../../tools"
import {Button} from "react-bootstrap"

class EarningsRow extends React.PureComponent {
    onSelect = () => {
        this.props.onSelect(this.props.row.get("did"))
    }

    render() {
        const row = this.props.row
        return (
            <tr>
                <td>{formatPhone(row.get("did"))}</td>
                {!this.props.restricted && <td className="d-none d-md-table-cell">{row.get("email")}</td>}
                <td className="right">{row.get("minutes_in")}</td>
                <td className="right">{formatMoney(row.get("price_in"), 2)}</td>
                <td className="right">{row.get("minutes_out")}</td>
                <td className="right">{formatMoney(row.get("price_out"), 2)}</td>
                <td className="right">{formatMoney(row.get("price_in") + row.get("price_out"), 2)}</td>
                <td className="right">
                    <Button variant="info" size="sm" onClick={this.onSelect}>
                        View CDRs
                    </Button>
                </td>
            </tr>
        )
    }
}

export default EarningsRow
