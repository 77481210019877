export function load() {
    return {
        type: "CONTACTS_LOAD",
    }
}

export function loading() {
    return {
        type: "CONTACTS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "CONTACTS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "CONTACTS_LOADERROR",
        msg: msg,
    }
}

export function save(contact, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CONTACTS_SAVE",
            contact: contact,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function usage(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CONTACTS_USAGE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function status(id, status, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CONTACTS_STATUS",
            id: id,
            status: status,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, newrouting, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CONTACTS_DELETE",
            id: id,
            newrouting: newrouting,
            resolve: resolve,
            reject: reject,
        })
    })
}
