import React, {PureComponent} from "react"
import {Button, Modal} from "react-bootstrap"
import IvrEdit from "./IvrEdit"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "./actions/ivrs"
import {load as loadRecordings} from "./actions/recordings"
import Icon from "./Icon"
import iconIvrs from "./images/menu/ivrs.svg"

class IvrEditControlled extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            ivr: props.ivr.set("newrecording_name", "").set("dnc_newrecording_name", ""),
        }
    }

    error = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
        })
    }

    onSaveIvr = () => {
        this.setState({saving: true})
        actions
            .save(this.state.ivr, this.props.dispatch)
            .then(() => {
                toast.success("IVR saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
                this.props.dispatch(loadRecordings())
                this.props.dispatch(actions.load())
            })
            .catch(r => {
                const error = r[0]
                const rid = r[1]
                const dncrid = r[2]

                let ivr = this.state.ivr

                if (rid || dncrid) {
                    this.props.dispatch(loadRecordings())
                }

                if (rid) {
                    ivr = ivr.set("recording", rid)
                }

                if (dncrid) {
                    ivr = ivr.set("dncrecording", dncrid)
                }

                this.setState({saving: false, ivr: ivr})

                this.error(error)
            })
    }

    onChange = ivr => {
        this.setState({ivr: ivr})
    }

    render() {
        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconIvrs} alt="" style={{marginRight: "20px"}} />
                            {this.state.ivr.get("id") === 0 && <span>New IVR</span>}
                            {this.state.ivr.get("id") !== 0 && <span>Edit IVR</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <IvrEdit
                        ivr={this.state.ivr}
                        ivrs={this.props.ivrs.filter(
                            ivr => ivr.get("id") !== this.state.ivr.get("id") && ivr.get("userid") === this.state.ivr.get("userid"),
                        )}
                        recordings={this.props.recordings}
                        onChange={this.onChange}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveIvr} active={this.state.saving} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = ownProps.ivr.get("userid") || ownProps.user.get("id")
    return {
        recordings: state.recordings.get("data").filter(r => r.get("userid") === user),
        ivrs: state.ivrs.get("data"),
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(IvrEditControlled)
