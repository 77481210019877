import React, {Component} from "react"
import {Route, Switch} from "react-router"

import LoginControlled from "./LoginControlled"
import Logout from "./Logout"

class RoutesUnauth extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={LoginControlled} />
                <Route path="/login/" component={LoginControlled} />
                <Route path="/logout/" component={Logout} />
                <Route component={LoginControlled} />
            </Switch>
        )
    }
}

export default RoutesUnauth
