import React, {Component} from "react"
import {Table, Button, Badge} from "react-bootstrap"
import {List} from "immutable"
import config from "./config"
import Icon from "./Icon"
import {formatMoney} from "./tools"

class Users extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            checked: List([]),
        }
    }

    onCheckAll = e => {
        let checked = e.target.checked ? this.state.data.map(item => item.get("id")) : []
        this.setState({
            checked: List(checked),
        })
    }

    onCheck = id => {
        let checked = this.state.checked
        let i = checked.indexOf(id)
        if (i < 0) checked = checked.push(id)
        else {
            checked = checked.delete(i)
        }
        this.setState({
            checked: checked,
        })
    }

    componentDidUpdate = nextProps => {
        if (this.props.data !== nextProps.data) {
            this.setState({
                data: this.props.data,
            })
        }
    }

    formatBlacklist(value, id) {
        const titles = {disabled: "CheckDisabled", check: "Check Enabled", autoremove: "Autoremove Enabled"}
        return <span title={titles[value]} className={`blacklist-${value}`} />
    }

    render() {
        return (
            <React.Fragment>
                <Table striped>
                    <thead>
                        <tr>
                            <th className="d-none d-md-table-cell">ID</th>
                            <th>Email</th>
                            <th className="right">Balance</th>
                            {!!config.enableIVR && <th className="center">IVR</th>}
                            {!!config.enableIVR && <th className="center">VA</th>}
                            <th className="center">SMS</th>
                            <th className="center d-none d-md-table-cell">Blacklists</th>
                            <th className="d-none d-md-table-cell">Role</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((row, key) => (
                            <tr key={key}>
                                <td className="d-none d-md-table-cell">{row.get("id")}</td>
                                <td>{row.get("email")}</td>
                                <td className="right">
                                    {row.get("role") !== "admin" && (
                                        <Badge
                                            variant={
                                                row.get("balance") < row.get("suspensionlimit")
                                                    ? "danger"
                                                    : row.get("balance") < row.get("notificationlimit")
                                                    ? "warning"
                                                    : "success"
                                            }
                                        >
                                            {formatMoney(row.get("balance"))}
                                        </Badge>
                                    )}
                                </td>
                                {!!config.enableIVR && (
                                    <td className="center">
                                        {!!row.get("opt_ivr_enabled") && <span className="icon-success" title="Yes"></span>}
                                    </td>
                                )}
                                {!!config.enableIVR && (
                                    <td className="center">
                                        {!!row.get("opt_bots_enabled") && <span className="icon-success" title="Yes"></span>}
                                    </td>
                                )}
                                <td className="center">
                                    {!!row.get("opt_sms_enabled") && <span className="icon-success" title="Yes"></span>}
                                </td>

                                <td className="center d-none d-md-table-cell">
                                    {this.formatBlacklist(row.get("opt_blacklist_check"), row.get("id"))}
                                </td>
                                <td className="d-none d-md-table-cell">{row.get("role")}</td>
                                <td className="float-right">
                                    {this.props.user.get("id") !== row.get("id") && (
                                        <Button
                                            title={`Login as ${row.get("email")}`}
                                            variant="info"
                                            className="loginas d-none d-md-inline"
                                            size="sm"
                                            onClick={() => this.props.onImpersonate(row)}
                                        >
                                            Login
                                        </Button>
                                    )}{" "}
                                    {row.get("role") !== "admin" && (
                                        <>
                                            <Button variant="info" size="sm" onClick={() => this.props.onFund(row)}>
                                                <Icon icon="dollar-sign" /> Fund
                                            </Button>{" "}
                                        </>
                                    )}
                                    <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                        <Icon icon="pencil-alt" /> Edit
                                    </Button>{" "}
                                    <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

export default Users
