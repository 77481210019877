import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import FiltersEditor from "./FiltersEditor"
import Icon from "../Icon"

class FiltersModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: props.filter,
            filters: props.filters,
            titles: props.titles,
        }
    }

    onApply = () => {
        this.props.onChange(this.state.filter, this.state.titles)
    }

    onChange = (filter, titles) => {
        this.setState({filter: filter, titles: titles})
    }

    render() {
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            {this.props.icon && <img src={this.props.icon} alt="" style={{marginRight: "20px"}} />}
                            <span>Filter</span>
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <FiltersEditor
                            filter={this.state.filter}
                            filters={this.state.filters}
                            titles={this.state.titles}
                            onChange={this.onChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="info" className="save" onClick={this.onApply}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default FiltersModal
