import React from "react"
import {Table} from "react-bootstrap"
import {fixTime, formatMoney} from "../tools"
import config from "../config"

const types = config.transactionTypes

class Transactions extends React.PureComponent {
    formatTransactionType = tx => {
        const t = types.filter(t => t.id === tx)
        if (t) return t[0].label
        return tx
    }

    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th className="d-none d-md-table-cell">ID</th>
                        <th>Type</th>
                        <th></th>
                        <th className="right">Amount</th>
                        {!this.props.restricted && <th>User</th>}
                        <th className="d-none d-lg-table-cell">Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={5}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((row, key) => (
                        <tr key={row.get("id")}>
                            <td className="d-none d-md-table-cell">{row.get("id")}</td>
                            <td>{this.formatTransactionType(row.get("type"))}</td>
                            <td>{row.get("data") && row.get("data").get("note")}</td>
                            <td className="right">{formatMoney(-row.get("amount"), 2)}</td>
                            {!this.props.restricted && <td>{row.get("email")}</td>}
                            <td className="d-none d-lg-table-cell">
                                {fixTime(row.get("dateadded"), this.props.timeOffset).format("L LT")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Transactions
