import * as actions from "../actions/bots"
import {save as saveRecording} from "../actions/recordings"
import axios from "axios"
import config from "../config"
import {Map, fromJS} from "immutable"
import * as loading from "../actions/loading"

const botsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "BOTS_LOAD":
                store.dispatch(loading.start())
                store.dispatch(actions.loading())
                axios
                    .get(`${config.apiEndpoint}bots`, {withCredentials: true})
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        const msg = e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message
                        console.log(msg)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading virtual assistants"))
                    })
                break
            case "BOTS_QUESTIONS_LOAD":
                axios
                    .get(`${config.apiEndpoint}bot/${action.botid}/questions`, {withCredentials: true})
                    .then(response => {
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break
            case "BOTS_QUESTIONS_SAVE":
                axios
                    .put(`${config.apiEndpoint}bot/${action.botid}/questions`, action.questions, {withCredentials: true})
                    .then(() => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break
            case "BOTS_SAVE":
                const bot = action.bot

                const saveBOT = (bot, resolve, reject) => {
                    const noid = bot.delete("id").toJS()
                    const id = bot.get("id")
                    const req = id ? axios.put(`${config.apiEndpoint}bot/${id}`, noid) : axios.post(`${config.apiEndpoint}bot`, noid)
                    req.then(response => {
                        resolve(response.data.id)
                    }).catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                }

                if (!bot.get("recording") && bot.get("newrecording_file")) {
                    saveRecording(
                        Map({
                            userid: bot.get("userid"),
                            name: bot.get("newrecording_name"),
                            file: bot.get("newrecording_file"),
                        }),
                        store.dispatch,
                    )
                        .then(response => {
                            saveBOT(
                                bot
                                    .set("recording", response.id)
                                    .delete("newrecording_file")
                                    .delete("newrecording_name"),
                                action.resolve,
                                action.reject,
                            )
                        })
                        .catch(e => {
                            action.reject(e)
                        })
                    return
                } else {
                    saveBOT(bot, action.resolve, action.reject)
                }

                break
            case "BOTS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}bot/${action.id}`)
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default botsMiddleware
