import React from "react"
import {connect} from "react-redux"
import {Button, Modal} from "react-bootstrap"

import * as actions from "../actions/smsendpoints"
import SpinnerButton from "../SpinnerButton"
import Icon from "../Icon"
import iconSmsEndpoints from "../images/menu/clients.svg"

import SmsEndpointEdit from "./SmsEndpointEdit"
import {toast} from "react-toastify"
import {Set} from "immutable"

class SmsEndpointEditControlled extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            saving: false,
            errors: this.validate(this.props.smsendpoint),
            smsendpoint: this.props.smsendpoint,
        }
    }

    onSaveSmsEndpoint = () => {
        this.setState({saving: true})
        actions
            .save(this.state.smsendpoint, this.props.dispatch)
            .then(() => {
                toast.success("SMS Endpoint Saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose(true)
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = smsendpoint => {
        let errors = Set([])
        if (!smsendpoint.get("name")) {
            errors = errors.add("name")
        }
        if (!smsendpoint.get("url")) {
            errors = errors.add("url")
        }
        return errors
    }

    onChange = smsendpoint => {
        this.setState({smsendpoint: smsendpoint, errors: this.validate(smsendpoint)})
    }

    render() {
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconSmsEndpoints} alt="" style={{height: "46px", marginRight: "20px"}} />
                            {this.state.smsendpoint.get("id") === 0 && <span>New SMS Endpoint</span>}
                            {this.state.smsendpoint.get("id") !== 0 && <span>Edit SMS Endpoint</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <SmsEndpointEdit
                        smsendpoint={this.state.smsendpoint}
                        errors={this.state.errors}
                        onChange={this.onChange}
                        user={this.props.user}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveSmsEndpoint} active={this.state.saving} disabled={this.state.errors.size > 0} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(SmsEndpointEditControlled)
