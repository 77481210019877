export function load() {
    return {
        type: "TEMPLATES_LOAD",
    }
}

export function loading() {
    return {
        type: "TEMPLATES_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "TEMPLATES_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "TEMPLATES_LOADERROR",
        msg: msg,
    }
}

export function save(template, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "TEMPLATES_SAVE",
            template: template,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            id: id,
            type: "TEMPLATES_DELETE",
            resolve: resolve,
            reject: reject,
        })
    })
}
