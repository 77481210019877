import React, {Component} from "react"
import statesMap from "./states"
import {Map, Set} from "immutable"
import {Form, Row, Col} from "react-bootstrap"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import config from "./config"
import SpinnerButton from "./SpinnerButton"
import DidsOrderSingleList from "./DidsOrderSingleList"
import * as actions from "./actions/dids"
import {formatPhone} from "./tools"

const states = Map(statesMap).sort()

class DidsOrderSingleControlled extends Component {
    constructor(props) {
        super(props)

        let features = Set([])
        props.user.get("trunks").forEach(trunk => {
            features = features.union(config.trunks[trunk.toString()].features)
        })

        let form = Map({
            state: "",
            city: "",
            npa: "",
            type: "regular",
            features: features,
            group: null,
        })

        this.state = {
            searching: false,
            form: form,
            dids: null,
            isLoading: false,
            allfeatures: features,
            showCity: props.user.get("trunks").find(v => v === 1) === undefined,
        }
    }

    onChangeFeatures = e => {
        let features = this.state.form.get("features")
        if (e.target.checked) {
            features = features.add(e.target.value)
        } else {
            features = features.remove(e.target.value)
        }
        this.setState({form: this.state.form.set("features", features)})
    }

    onChangeField = e => {
        this.setState({form: this.state.form.set(e.target.name, e.target.value)})
    }

    onSearch = () => {
        this.setState({searching: true, dids: null})

        let form = this.state.form
        if (form.get("type") === "tollfree") {
            form = form.set("features", form.get("features").add("tollfree"))
        }

        actions
            .search(form.delete("type"), this.props.dispatch)
            .then(data => {
                this.setState({searching: false, dids: data})
            })
            .catch(error => {
                this.setState({searching: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onBuy = did => {
        if (!window.confirm("Order DID " + formatPhone(did.get("number")) + "?")) return
        const id = this.state.dids.findIndex(v => v === did)
        if (id === undefined) return
        this.setState({dids: this.state.dids.set(id, did.set("ordering", true))})
        actions
            .orderSingle(did.get("number"), this.props.dispatch)
            .then(() => {
                this.setState({dids: this.state.dids.set(id, did.set("ordering", false).set("ordered", true))})
            })
            .catch(error => {
                this.setState({dids: this.state.dids.set(id, did.set("ordering", false).set("ordered", false))})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    render() {
        const form = this.state.form
        //const valid = !!form.get("state") || !!form.get("npa") || !!form.get("city") || form.get("type") === "tollfree"

        return (
            <div>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        DID Type
                    </Form.Label>
                    <Col sm="10">
                        <Form.Check
                            name="type"
                            key="regular"
                            inline
                            label="Regular"
                            value="regular"
                            type="radio"
                            checked={form.get("type") === "regular"}
                            onChange={this.onChangeField}
                        />
                        <Form.Check
                            name="type"
                            key="tollfree"
                            inline
                            label="Toll-Free"
                            value="tollfree"
                            type="radio"
                            checked={form.get("type") === "tollfree"}
                            onChange={this.onChangeField}
                        />
                    </Col>
                </Form.Group>

                {form.get("type") === "regular" && (
                    <>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                State
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="select"
                                    name="state"
                                    onChange={this.onChangeField}
                                    value={form.get("state")}
                                    style={{marginBottom: "5px"}}
                                >
                                    <option value="">SELECT STATE</option>
                                    {states.keySeq().map(key => (
                                        <option key={key} value={key}>
                                            {states.get(key)}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        {this.state.showCity && (
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                    City
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        name="city"
                                        onChange={this.onChangeField}
                                        value={form.get("city")}
                                        style={{marginBottom: "5px"}}
                                        placeholder="Enter City"
                                    />
                                </Col>
                            </Form.Group>
                        )}

                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Area Code
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name="npa"
                                    onChange={this.onChangeField}
                                    value={form.get("npa")}
                                    style={{marginBottom: "5px"}}
                                    placeholder="Enter Area Code"
                                />
                            </Col>
                        </Form.Group>
                    </>
                )}
                {/*
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        Group
                    </Form.Label>
                    <Col sm="10">
                        <GroupField group={this.state.group} groups={this.props.groups} onChange={this.onChangeGroup} />
                    </Col>
                </Form.Group>
                */}
                {this.state.allfeatures.count() > 1 && (
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Features
                        </Form.Label>
                        <Col sm="10">
                            {this.state.allfeatures.map(feature => (
                                <Form.Check
                                    key={feature}
                                    inline
                                    label={feature}
                                    value={feature}
                                    type="checkbox"
                                    checked={form.get("features").has(feature)}
                                    onChange={this.onChangeFeatures}
                                />
                            ))}
                        </Col>
                    </Form.Group>
                )}
                <Form.Group as={Row}>
                    <Form.Label column sm="2"></Form.Label>
                    <Col sm="10">
                        <SpinnerButton
                            active={this.state.searching}
                            icon="search"
                            variant="success"
                            onClick={this.onSearch}
                            regularText="Search DIDs"
                            activeText="Searching..."
                        />
                    </Col>
                </Form.Group>

                {this.state.dids && <DidsOrderSingleList dids={this.state.dids} onBuy={this.onBuy} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        groups: state.groups.get("data"),
    }
}

export default connect(mapStateToProps)(DidsOrderSingleControlled)
