import React, {PureComponent} from "react"
import {Table, Form} from "react-bootstrap"
import Title from "./Title"
import DidsRow from "./DidsRow"
import Th from "./Th"

class Dids extends PureComponent {
    onCheckAll = e => {
        this.props.setCheckedAll(e.target.checked)
    }

    onCheck = id => {
        let checked = this.props.checked
        if (checked.includes(id)) {
            checked = checked.delete(id)
        } else {
            checked = checked.add(id)
        }

        this.props.setChecked(checked)
    }

    onDelete = () => {
        this.props.onDelete(this.props.checked)
    }

    render() {
        const data = this.props.data
        const all = data.size > 0 && data.reduce((c, item) => c + (this.props.checked.includes(item.get("id")) ? 1 : 0), 0) === data.size

        return (
            <React.Fragment>
                <Title title="DIDs" />
                <Table striped width="100%">
                    <thead>
                        <tr>
                            <th>
                                {" "}
                                <Form.Check type="checkbox" label="" checked={all} onChange={this.onCheckAll} />
                            </th>
                            <Th
                                field="did"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                DID
                            </Th>
                            <Th
                                field="description"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                                className="d-none d-md-table-cell"
                            >
                                Location
                            </Th>
                            <th className="d-none d-md-table-cell">Notes</th>
                            <th className="d-none d-md-table-cell">Destination</th>
                            {!this.props.restricted && (
                                <Th
                                    field="email"
                                    sortfield={this.props.sortfield}
                                    sortdir={this.props.sortdir}
                                    onChange={this.props.onChangeSort}
                                    className="d-none d-md-table-cell"
                                >
                                    Creator
                                </Th>
                            )}
                            <Th
                                field="datenextpayment"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                Days Left
                            </Th>
                            <Th
                                field="dateadded"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                Created
                            </Th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.size === 0 && (
                            <tr>
                                <td colSpan={8}>
                                    <center>No data</center>
                                </td>
                            </tr>
                        )}
                        {this.props.data.map((row, key) => (
                            <DidsRow
                                key={row.get("id")}
                                row={row}
                                checked={this.props.checked.contains(row.get("id"))}
                                onCheck={this.onCheck}
                                onEdit={this.props.onEdit}
                                onEditGroup={this.props.onEditGroup}
                                restricted={this.props.restricted}
                                timeOffset={this.props.timeOffset}
                                groups={this.props.groups}
                            />
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

export default Dids
