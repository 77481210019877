import React from "react"
import {Button, Form, Row, Col} from "react-bootstrap"
import {Map, List} from "immutable"
import RoutingGroupContactEdit from "./RoutingGroupContactEdit"
import Icon from "../Icon"
import DidsTable from "../DidsTable"
import RoutingTypeSelect from "../RoutingTypeSelect"
import RoutingValueSelect from "../RoutingValueSelect"

class RoutingGroupEdit extends React.PureComponent {
    types = {
        roundrobin: "Round Robin",
        sequential: "Sequential",
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeFieldValue = (field, value) => {
        let routinggroup = this.props.routinggroup.set(field, value)
        if (field === "dest_type") {
            routinggroup = routinggroup.set("dest_value", "")
        }
        this.props.onChange(routinggroup)
    }

    onMenuContactAdd = () => {
        const contacts = this.props.routinggroup.get("contacts").push(
            Map({
                contact: "",
                forwards: "",
                forwardsused: 0,
                status: "active",
                omitstates: List([]),
                mindutation: 0,
                mindurationtext: "0:00",
            }),
        )
        this.props.onChange(this.props.routinggroup.set("contacts", contacts))
    }

    onMenuContactDelete = i => {
        if (!window.confirm("Delete?")) return
        let contacts = this.props.routinggroup.get("contacts")
        let contact = contacts.get(i)
        if (contact.get("id") > 0) {
            contacts = contacts.set(i, contacts.get(i).set("status", "deleted"))
        } else {
            contacts = contacts.delete(i)
        }
        this.props.onChange(this.props.routinggroup.set("contacts", contacts))
    }

    onMenuContactChange = (i, value) => {
        const contacts = this.props.routinggroup.get("contacts").set(i, value)
        this.props.onChange(this.props.routinggroup.set("contacts", contacts))
    }

    render() {
        const routinggroup = this.props.routinggroup
        return (
            <Form>
                <Form.Group as={Row} className={this.props.errors.has("title") ? "error" : ""}>
                    <Form.Label column sm="3">
                        Name
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            name="title"
                            placeholder="Routing Group Name"
                            defaultValue={routinggroup.get("title")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        Type
                    </Form.Label>
                    <Col sm="9">
                        {Object.keys(this.types).map(type => (
                            <Form.Check
                                key={type}
                                inline
                                type="radio"
                                name="type"
                                value={type}
                                label={this.types[type]}
                                checked={routinggroup.get("type") === type}
                                onChange={this.changeField}
                            />
                        ))}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={this.props.errors.has("dest_type") ? "error" : ""}>
                    <Form.Label column sm="3">
                        Overflow Destination
                    </Form.Label>
                    <Col sm="4">
                        <RoutingTypeSelect
                            type="routinggroupdefault"
                            name="dest_type"
                            value={routinggroup.get("dest_type")}
                            onChange={this.changeField}
                        />
                    </Col>
                    <Col sm="5">
                        <RoutingValueSelect
                            name="dest_value"
                            type={routinggroup.get("dest_type")}
                            value={routinggroup.get("dest_value")}
                            onChange={this.changeField}
                        />
                    </Col>
                </Form.Group>
                <hr />
                <Form.Group as={Row}>
                    <Col sm={12}>
                        <Button variant="success" className="addnew" onClick={this.onMenuContactAdd}>
                            <Icon icon="plus" /> Add Contact
                        </Button>
                    </Col>
                </Form.Group>
                {routinggroup.get("contacts").size > 0 && (
                    <>
                        <Form.Group as={Row}>
                            <Col xs={11} sm={7}>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        Contact
                                    </Col>
                                    <Col className="d-none d-sm-block" sm={4}>
                                        Min. Duration
                                    </Col>
                                    <Col className="d-none d-sm-block" sm={4}>
                                        Omit States
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="d-none d-sm-block" sm={2}>
                                Daily Calls
                            </Col>
                            <Col className="d-none d-sm-block" sm={1}>
                                Today
                            </Col>
                        </Form.Group>
                        {routinggroup.get("contacts").map(
                            (contact, key) =>
                                contact.get("status") === "active" && (
                                    <Form.Group as={Row} key={key}>
                                        <RoutingGroupContactEdit
                                            contact={contact}
                                            contacts={this.props.contacts}
                                            onChange={value => this.onMenuContactChange(key, value)}
                                            onDelete={() => this.onMenuContactDelete(key)}
                                        />
                                    </Form.Group>
                                ),
                        )}
                    </>
                )}
                {!!routinggroup.get("id") && routinggroup.get("dids").size > 0 && (
                    <>
                        <legend>Related DIDs</legend>
                        <DidsTable
                            dids={routinggroup.get("dids")}
                            checked={this.props.checked}
                            onChangeChecked={this.props.onChangeChecked}
                            onDelete={this.props.onDelete}
                            hideGrouped
                        />
                    </>
                )}
            </Form>
        )
    }
}
export default RoutingGroupEdit
