import React from "react"

class Switch extends React.PureComponent {
    render() {
        return (
            <label className="switch">
                <input type="checkbox" onChange={this.props.onChange} checked={this.props.checked} />
                <span className="slider round"></span>
            </label>
        )
    }
}

export default Switch
