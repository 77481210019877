import * as actions from "../actions/recordings"
import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"

const recordingsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "RECORDINGS_LOAD":
                store.dispatch(actions.loading())
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}recordings`)
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        const msg = e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message
                        console.log(msg)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading recordings list"))
                    })
                break
            case "RECORDINGS_SAVE":
                const recording = action.recording.toJS()

                const form_data = new FormData()
                form_data.append("name", recording.name)
                form_data.append("file", recording.file)
                form_data.append("userid", recording.userid)

                const req = recording.id
                    ? axios.put(`${config.apiEndpoint}recording/${recording.id}`, form_data)
                    : axios.post(`${config.apiEndpoint}recording`, form_data)
                req.then(response => {
                    action.resolve(response.data)
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break
            case "RECORDINGS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}recording/${action.id}`)
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default recordingsMiddleware
