import React from "react"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import {Button, Modal, Col, Row, Form} from "react-bootstrap"
import Icon from "./Icon"
import SpinnerButton from "./SpinnerButton"

import * as actionsRoutingGroups from "./actions/routinggroups"
import * as actionsIvrs from "./actions/ivrs"
import * as actionsContacts from "./actions/contacts"
import * as actionsGroups from "./actions/groups"

import config from "./config"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"

class DeleteModalControlled extends React.PureComponent {
    state = {
        saving: false,
        valid: false,
        usage: null,
    }

    actions = type => {
        if (type === undefined) type = this.props.type
        if (type === "routinggroup") return actionsRoutingGroups
        if (type === "routinggroupdefault") return actionsRoutingGroups
        if (type === "ivr") return actionsIvrs
        if (type === "contact") return actionsContacts
        if (type === "group") return actionsGroups
        if (type === "group_disabled") return actionsGroups
        return ""
    }

    loadUsage = () => {
        this.actions()
            .usage(this.props.id, this.props.dispatch)
            .then(usage => {
                var tmp = {}
                var state = {}
                usage.forEach(item => {
                    const type = item.get("type")
                    let sub = tmp[type] !== undefined ? tmp[type] : []
                    sub.push(item.toJS())
                    tmp[type] = sub
                    state[`newtype_${type}`] = ""
                    if (type === "routinggroup") state[`newtype_${type}`] = "contact"
                })

                state.usage = tmp
                this.setState(state, this.validate)
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    componentDidMount = () => {
        this.loadUsage()
    }

    validate = () => {
        let valid = true
        for (var i in this.state) {
            if (i.startsWith("newtype_")) {
                const entity = i.substr(8)
                const type = this.state[i]
                const value = this.state[`newvalue_${entity}`]
                if (type !== "dnc" && type !== "replay" && type !== "hangup" && this.props.type !== "group" && !value) {
                    valid = false
                    break
                }
            }
        }
        this.setState({valid: valid})
    }

    onChange = e => {
        if (e.target.name.startsWith("newtype_")) {
            this.setState({[e.target.name.replace("type", "value")]: ""}, this.validate)
        }
        this.setState({[e.target.name]: e.target.value}, this.validate)
    }

    onDelete = () => {
        let newitems = {}
        for (var i in this.state) {
            if (i.startsWith("newtype_")) {
                const entity = i.substr(8)
                newitems[entity] = {
                    type: this.state[i],
                    value: this.state[`newvalue_${entity}`],
                }
            }
        }

        this.actions()
            .del(this.props.id, newitems, this.props.dispatch)
            .then(() => {
                toast.success(config.entities[this.props.type][0] + " deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                for (var entity in this.state.usage) {
                    const action = this.actions(entity)
                    if (action) this.props.dispatch(action.load())
                }
                this.props.dispatch(this.actions(this.props.type).load())
                if (this.props.onDeleted) this.props.onDeleted()
                this.props.onClose()
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    renderRow = type => {
        if (this.props.type === "group")
            return (
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        New Group:
                    </Form.Label>
                    <Col sm={4}>
                        <RoutingValueSelect
                            name={`newvalue_${type}`}
                            type="group"
                            value={this.state[`newvalue_${type}`]}
                            exclude={[this.props.id]}
                            onChange={this.onChange}
                            allowEmpty
                        />
                    </Col>
                </Form.Group>
            )

        if (type === "routinggroup")
            return (
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        New Action:
                    </Form.Label>
                    <Col sm={4}>
                        <RoutingValueSelect
                            name={`newvalue_${type}`}
                            type={this.state[`newtype_${type}`]}
                            value={this.state[`newvalue_${type}`]}
                            exclude={this.state[`newtype_${type}`] === this.props.type ? [this.props.id] : null}
                            onChange={this.onChange}
                        />
                    </Col>
                </Form.Group>
            )

        return (
            <>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">
                        New Action:
                    </Form.Label>
                    <Col sm={4}>
                        <RoutingTypeSelect
                            type={type}
                            name={`newtype_${type}`}
                            value={this.state[`newtype_${type}`]}
                            onChange={this.onChange}
                        />
                    </Col>
                    <Col sm={5}>
                        <RoutingValueSelect
                            name={`newvalue_${type}`}
                            type={this.state[`newtype_${type}`]}
                            value={this.state[`newvalue_${type}`]}
                            exclude={this.state[`newtype_${type}`] === this.props.type ? [this.props.id] : null}
                            onChange={this.onChange}
                        />
                    </Col>
                </Form.Group>
            </>
        )
    }

    render() {
        if (this.state.usage === null) return ""
        const props = this.props
        console.log(this.state.usage)
        return (
            <Modal show backdrop animation={false} onHide={props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={props.onClose} color="#CDCDCD" />
                        Delete {config.entities[props.type][0]}
                        <Icon icon="times" onClick={props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(this.state.usage).length === 0 && (
                        <div>
                            Delete {config.entities[props.type][0]} <strong>{props.title}</strong>?
                        </div>
                    )}
                    {Object.keys(this.state.usage).length > 0 && (
                        <>
                            <div style={{lineHeight: "30px"}}>
                                {config.entities[props.type][0]} <strong>{props.title}</strong> currently is an action in:
                            </div>
                            {Object.keys(this.state.usage).map(type => (
                                <React.Fragment key={type}>
                                    <Form.Group as={Row}>
                                        <Col sm={12} style={{lineHeight: "30px"}}>
                                            <strong>{config.entities[type][1]}</strong>:{" "}
                                            {this.state.usage[type].map(item => item.name).join(", ")}
                                        </Col>
                                    </Form.Group>
                                    {this.renderRow(type)}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton
                        onClick={this.onDelete}
                        active={this.state.saving}
                        disabled={!this.state.valid}
                        regularText="Delete"
                        activeText="Deleting…"
                        icon="trash-alt"
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        ivrs: state.ivrs.get("data"),
    }
}

export default connect(mapStateToProps)(DeleteModalControlled)
