import React, {Component} from "react"
import {CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from "react-stripe-elements"
import {Button, Form, Row, Col, Modal, Alert} from "react-bootstrap"
import {Map, Set} from "immutable"
import statesMap from "./states"
import countriesMap from "./countries"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import * as actions from "./actions/stripe"

const states = Map(statesMap).sort()
const countries = Map(countriesMap).sort()

class StripeForm extends Component {
    constructor(props) {
        super(props)
        this.style = {
            base: {
                color: "#979797",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: "auto",
                ":focus": {
                    color: "#495057",
                },
            },
            invalid: {
                color: "#979797",
            },
        }

        let errors = Set(["cardNumber", "cardExpiry", "cardCvc"])
        if (!props.user.get("contactname")) {
            errors = errors.add("name")
        }

        this.state = {
            name: props.user.get("contactname"),
            line1: this.props.user.get("address"),
            line2: "",
            city: this.props.user.get("city"),
            state: this.props.user.get("state"),
            zip: this.props.user.get("zip"),
            country: "US",
            errors: errors,
            saving: false,
        }
    }

    onSaveCard = () => {
        let token
        this.setState({saving: true})
        this.props.stripe
            .createToken()
            .then(response => {
                token = response.token.id
                return actions.setupintent(this.props.dispatch)
            })
            .then(clientSecret => {
                return this.props.stripe.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: {token: token},
                        billing_details: {
                            name: this.state.name,
                            address: {
                                city: this.state.city,
                                country: this.state.country,
                                line1: this.state.line1,
                                line2: this.state.line2,
                                postal_code: this.state.zip,
                                state: this.state.state,
                            },
                        },
                    },
                })
            })
            .then(result => {
                if (result.error) {
                    console.log(result.error)
                } else {
                    return actions.attachCard(result.setupIntent.payment_method, this.props.dispatch)
                }
            })
            .then(() => this.props.onClose(true))
            .catch(e => {
                this.setState({saving: false})
                console.log(e)
            })
    }

    changeField = e => {
        let errors = this.state.errors
        if (e.target.name === "name") {
            if (e.target.value) {
                errors = errors.remove("name")
            } else {
                errors = errors.add("name")
            }
            this.setState({errors: errors})
        }
        this.setState({[e.target.name]: e.target.value})
    }

    onChange = e => {
        let errors = this.state.errors
        if (e.complete) {
            errors = errors.remove(e.elementType)
        } else {
            errors = errors.add(e.elementType)
        }
        this.setState({errors: errors})
    }

    render() {
        return (
            <Modal show backdrop size="lg" animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>Add New Card</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Alert variant="info" style={{margin: "0 0 15px 0"}}>
                        Transfering funds by credit card is safe. Credit Card payments will be made securely through SSL-tunnel by Stripe
                        Payments. Your credit card details will not be processed by Caller Statistics at any point.
                    </Alert>

                    <div className="checkout">
                        <Form.Group as={Row} className={this.state.errors.has("name") ? "error" : ""}>
                            <Col sm={12}>
                                <Form.Label>Cardholder Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Cardholder Name"
                                    value={this.state.name}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={12}>
                                <Form.Label>Card Number</Form.Label>
                                <CardNumberElement onChange={this.onChange} style={this.style} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Label>Exp. Date</Form.Label>
                                <CardExpiryElement onChange={this.onChange} style={this.style} />
                            </Col>
                            <Col sm={6}>
                                <Form.Label>CVC</Form.Label>
                                <CardCvcElement onChange={this.onChange} style={this.style} />
                            </Col>
                        </Form.Group>
                        <h2>Billing Address</h2>
                        <Form.Group as={Row}>
                            <Col sm={4}>
                                <Form.Label>Street Address, line 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="line1"
                                    placeholder="Line 1"
                                    value={this.state.line1}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Street Address, line 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="line2"
                                    placeholder="Line 2"
                                    value={this.state.line2}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={this.state.city}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={4}>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="state"
                                    placeholder="State"
                                    value={this.state.state}
                                    onChange={this.changeField}
                                >
                                    <option>Select State</option>
                                    {states.keySeq().map(key => (
                                        <option key={key} value={key}>
                                            {states.get(key)}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Zip</Form.Label>
                                <Form.Control type="text" name="zip" placeholder="Zip" value={this.state.zip} onChange={this.changeField} />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="country"
                                    placeholder="Country"
                                    value={this.state.country}
                                    onChange={this.changeField}
                                >
                                    <option>Select Country</option>
                                    {countries.keySeq().map(key => (
                                        <option key={key} value={key}>
                                            {countries.get(key)}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveCard} active={this.state.saving} disabled={this.state.errors.size > 0} />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default injectStripe(connect()(StripeForm))
