import React, {Component} from "react"
import {Alert, Button} from "react-bootstrap"

class LoadError extends Component {
    render() {
        return (
            <Alert variant="danger">
                <Alert.Heading>{this.props.message}</Alert.Heading>
                <Button variant="outline-danger" onClick={() => this.props.onReload()}>
                    Try again
                </Button>
            </Alert>
        )
    }
}

export default LoadError
