import React from "react"
import {Form, Button, Badge} from "react-bootstrap"
import BlacklistStatus from "./BlacklistStatus"
import Moment from "react-moment"
import moment from "moment"
import Icon from "./Icon"
import {fixTime, formatPhone} from "./tools"
import RoutingDestination from "./RoutingDestination"

class DidsRow extends React.PureComponent {
    formatGroup = row => {
        let group = this.props.groups.filter(item => item.get("id") === row.get("groupid"))
        if (group.size === 0) return
        group = group.get(0)
        return (
            <>
                <Badge variant="info" className={`group-${group.get("status")}`} onClick={() => this.props.onEditGroup(group)}>
                    {group.get("name")}
                </Badge>{" "}
            </>
        )
    }

    formatDays = date => {
        let days = Math.floor(moment.duration(moment(date).diff(moment())).asDays())
        return days >= 0 ? days : 0
    }

    formatFeatures = features => {
        if (!features) return ""
        return features
            .sort()
            .reverse()
            .map(feature => {
                switch (feature) {
                    case "voice":
                        return (
                            <span key={feature} className="feature-icon" title="Voice">
                                <Icon icon="phone-alt" />
                            </span>
                        )
                    case "sms":
                        return (
                            <span key={feature} className="feature-icon" title="SMS">
                                <Icon icon="sms" />
                            </span>
                        )
                    default:
                        return ""
                }
            })
    }

    render() {
        const row = this.props.row
        const dateadded = fixTime(row.get("dateadded"), this.props.timeOffset)
        return (
            <tr style={["active", "transferred"].includes(row.get("status")) ? {} : {textDecoration: "line-through"}}>
                <td>
                    {["active", "transferred"].includes(row.get("status")) && (
                        <Form.Check
                            type="checkbox"
                            label=""
                            checked={this.props.checked}
                            onChange={() => this.props.onCheck(row.get("id"))}
                        />
                    )}
                </td>
                <td>
                    {this.formatFeatures(row.get("features"))} {formatPhone(row.get("did"))}
                    <BlacklistStatus>{row.get("spamstatus")}</BlacklistStatus>
                </td>
                <td className="d-none d-md-table-cell">
                    {row
                        .get("description")
                        .replace("TF, ", "")
                        .replace(/^\s*,/, "")}
                </td>
                {row.get("status") !== "transferred" && (
                    <>
                        <td className="d-none d-md-table-cell">
                            {row.get("group") && this.formatGroup(row)}
                            {row.get("notes")}
                        </td>
                        <td className="d-none d-md-table-cell">
                            <RoutingDestination type={row.get("destination_type")} value={row.get("destination_value")} />
                        </td>
                        {!this.props.restricted && <td className="d-none d-md-table-cell">{row.get("email")}</td>}
                        <td>{this.formatDays(row.get("datenextpayment"))}</td>
                    </>
                )}
                {row.get("status") === "transferred" && (
                    <>
                        <td colSpan={4} className="center">
                            {row.get("jobmsg")}
                        </td>
                    </>
                )}
                <td title={moment(dateadded).format("L LT")}>
                    <Moment format="L" local={true}>
                        {dateadded}
                    </Moment>
                </td>
                <td className="float-right">
                    {row.get("status") === "active" && (
                        <Button variant="info" size="sm" className="edit" onClick={() => this.props.onEdit(row)}>
                            <Icon icon="pencil-alt" /> Edit
                        </Button>
                    )}
                </td>
            </tr>
        )
    }
}

export default DidsRow
