import React from "react"
import {Button} from "react-bootstrap"
import {connect} from "react-redux"
import Icon from "./Icon"
import Ivrs from "./Ivrs"
import IvrEditControlled from "./IvrEditControlled"
import {Map, List} from "immutable"
import * as actions from "./actions/ivrs"
import ReloadIcon from "./ReloadIcon"
import Title from "./Title"
import DeleteModal from "./DeleteModal"

class IvrsControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            editor: null,
            deleting: null,
            currentPage: 0,
            filter: Map({}),
        }

        this.newIVR = Map({
            id: 0,
            userid: 0,
            name: "",
            recording: "",
            rawrecording: "",
            items: List([]),
        })
    }

    reload = () => {
        this.props.dispatch(actions.load())
    }

    onDelete = ivr => {
        this.setState({deleting: ivr})
    }

    onEdit = ivr => {
        this.setState({editor: ivr})
    }

    onSetPage = page => {
        this.setState({currentPage: page})
    }

    onChangeFilter = values => {
        this.setState({filter: this.state.filter.merge(values.filter(v => !!v))})
    }

    render() {
        return (
            <>
                <Title title="Ivrs" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button
                            variant="success"
                            className="addnew"
                            onClick={() => this.onEdit(this.newIVR.set("userid", this.props.user.get("id")))}
                        >
                            <Icon icon="plus" /> Add New IVR
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.props.loaded && (
                    <Ivrs
                        restricted={this.props.user.get("role") !== "admin"}
                        data={this.props.data}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        onReload={this.reload}
                    />
                )}
                {this.state.editor && (
                    <IvrEditControlled ivr={this.state.editor} recordings={this.props.recordings} onClose={() => this.onEdit(null)} />
                )}
                {this.state.deleting && (
                    <DeleteModal
                        id={this.state.deleting.get("id")}
                        title={this.state.deleting.get("name")}
                        type="ivr"
                        onClose={() => this.setState({deleting: null})}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        data: state.ivrs.get("data"),
        loaded: state.ivrs.get("loaded"),
        recordings: state.recordings.get("data"),
    }
}

export default connect(mapStateToProps)(IvrsControlled)
