export function save(transaction, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "TRANSACTIONS_SAVE",
            transaction: transaction,
            resolve: resolve,
            reject: reject,
        })
    })
}
