import React from "react"

const BlacklistStatus = props => {
    const colors = {spam: "danger", nospam: "success", probably: "warning"}
    const titles = {spam: "Blacklisted", nospam: "Good", probably: "Probably Spam"}
    const status = props.children
    return status !== null && colors[status] ? <span className={`spam-status icon-${colors[status]}`} title={titles[status]}></span> : ""
}

export default BlacklistStatus
