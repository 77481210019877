import React from "react"
import {connect} from "react-redux"
import Templates from "./Templates"
import {List, Map} from "immutable"
import {toast} from "react-toastify"
import {Button} from "react-bootstrap"
import TemplateEditControlled from "./TemplateEditControlled"
import Title from "./Title"
import * as actions from "./actions/templates"
import Icon from "./Icon"
import ReloadIcon from "./ReloadIcon"

class TemplatesControlled extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            edit: null,
            data: List([]),
        }

        this.newTemplate = Map({
            id: 0,
            name: "",
            type: null,
            questions: List([]),
            retries: List([]),
            constraints: Map({
                min: 0,
                max: 0,
                target_min: 0,
                target_max: 0,
            }),
            attempts: 2,
        })
    }

    reload = () => {
        this.props.dispatch(actions.load())
    }

    onDelete = template => {
        if (!window.confirm("Delete?")) return
        actions
            .del(template.get("id"), this.props.dispatch)
            .then(() => this.reload())
            .catch(e => {
                console.log(e)
                toast.error("Network error, please try again", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onEdit = template => {
        this.setState({edit: template})
    }

    onEditClose = reload => {
        if (reload) {
            this.reload()
        }
        this.setState({edit: null})
    }

    render() {
        return (
            <div>
                <Title title="Question Templates" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newTemplate)}>
                            <Icon icon="plus" /> Add Template
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.props.loaded && (
                    <Templates data={this.props.data} onEdit={this.onEdit} onDelete={this.onDelete} onReload={this.reload} />
                )}
                {this.state.edit !== null && <TemplateEditControlled template={this.state.edit} onClose={this.onEditClose} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.templates.get("data"),
        loaded: state.templates.get("loaded"),
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(TemplatesControlled)
