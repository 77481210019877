import React, {Component} from "react"
import {connect} from "react-redux"
import Earnings from "./Earnings"
import {List, Map, Set} from "immutable"
import {toast} from "react-toastify"
import {earningsLoad} from "../../actions/reports"
import * as loading from "../../actions/loading"
import Paginator from "../../Paginator"
import Title from "../../Title"
import Filters from "../../filters/Filters"
import ReloadIcon from "../../ReloadIcon"
import iconEarnings from "../../images/menu/livecalls.svg"
import moment from "moment"
import {push} from "connected-react-router"

const pageLength = 50

class EarningsControlled extends Component {
    constructor(props) {
        super(props)

        this.filters = ["date1", "date2", "users"]

        this.state = {
            filter: Map({
                date1: moment()
                    .startOf("isoWeek")
                    .toDate(),
            }),
            currentPage: 0,
            totalItems: 0,
            sortfield: "email",
            sortdir: "asc",
            data: List([]),
            delta: 0,
            checked: Set([]),
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            groups: props.groups,
        }
    }

    onFilter = fields => {
        this.onChangeFilter(this.state.filter.merge(fields))
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            this.reload,
        )
    }

    reload = state => {
        if (!state) state = this.state
        let page = state.currentPage

        this.props.dispatch(loading.start())

        let start = pageLength * page

        earningsLoad(this.state.filter, start, pageLength, state.sortfield, state.sortdir, this.props.dispatch)
            .then(r => {
                const {data, total} = r
                const totalPages = Math.ceil(total / pageLength)
                if (page > totalPages) {
                    page = totalPages
                }

                this.props.dispatch(loading.end())

                this.setState({
                    currentPage: page,
                    totalItems: total,
                    data: data,
                    sortfield: state.sortfield,
                    sortdir: state.sortdir,
                    delta: 0,
                })
            })
            .catch(e => {
                toast.error("Error loading report data", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount = () => {
        this.reload()
    }

    onSetPage = page => {
        this.reload({
            currentPage: page,
            sortfield: this.state.sortfield,
            sortdir: this.state.sortdir,
        })
    }

    onChangeSort = field => {
        const dir = field !== this.state.sortfield ? "asc" : this.state.sortdir === "asc" ? "desc" : "asc"
        this.reload({
            currentPage: 0,
            sortfield: field,
            sortdir: dir,
        })
    }

    saveFilter = email => {
        const filter = {
            email: email,
        }
        window.sessionStorage.setItem("cdrs-filter", JSON.stringify(filter))
    }

    onSelect = email => {
        this.saveFilter(email)
        this.props.dispatch(push(`/cdrs/`))
    }

    render() {
        return (
            <>
                <Title title="Earnings" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters
                            icon={iconEarnings}
                            enabled={this.filters}
                            filter={this.state.filter}
                            onChange={this.onChangeFilter}
                            users={this.props.users}
                        />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <Earnings
                    restricted={this.state.user.get("role") !== "admin"}
                    data={this.state.data}
                    filter={this.state.filter}
                    onReload={this.reload}
                    onFilter={this.onFilter}
                    onChangeSort={this.onChangeSort}
                    sortfield={this.state.sortfield}
                    sortdir={this.state.sortdir}
                    onSelect={this.onSelect}
                />
                <div className="paging">
                    {this.state.totalItems > pageLength && (
                        <Paginator
                            currentPage={this.state.currentPage}
                            totalItems={this.state.totalItems}
                            pageLength={pageLength}
                            onSetPage={this.onSetPage}
                        />
                    )}
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        users: state.users.get("data"),
        timeOffset: state.auth.get("timeOffset"),
        groups: state.groups,
    }
}

export default connect(mapStateToProps)(EarningsControlled)
