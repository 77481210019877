import React from "react"
import {Col, Row, Form} from "react-bootstrap"

class FiltersEditor extends React.Component {
    state = {}

    onChangeField = (name, value, title) => {
        const titleName = this.state.filters[name].title
        if (title === undefined) {
            title = value
        }
        this.props.onChange(
            this.state.filter.set(name, value),
            title !== undefined && title !== "" ? this.state.titles.set(titleName, title) : this.state.titles.remove(titleName),
        )
    }

    static getDerivedStateFromProps(props, state) {
        return {
            filter: props.filter,
            titles: props.titles,
            filters: props.filters,
        }
    }

    render() {
        return (
            <Form>
                {Object.keys(this.state.filters).map(key => {
                    const Filter = this.state.filters[key]
                    return (
                        <Form.Group as={Row} key={key}>
                            <Form.Label column sm={3}>
                                {Filter.Title && <Filter.Title />}
                                {!Filter.Title && Filter.title}
                            </Form.Label>
                            <Col sm={9}>
                                <Filter.Input
                                    value={this.state.filter.has(key) ? this.state.filter.get(key) : ""}
                                    onChange={(value, title) => this.onChangeField(key, value, title)}
                                />
                            </Col>
                        </Form.Group>
                    )
                })}
            </Form>
        )
    }
}

export default FiltersEditor
