import React from "react"
import Icon from "./Icon"
import {Map} from "immutable"
import {formatTime, fixTime, formatMoney, formatPhone} from "./tools"

class Cdr extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            fieldfilter: null,
        }
    }

    showFilter = fieldfield => {
        this.setState({fieldfilter: fieldfield})
    }

    hideFilter = () => {
        this.setState({fieldfilter: null})
    }

    onFilter = () => {
        let f = Map({}).set(this.state.fieldfilter, this.props.cdr.get(this.state.fieldfilter))
        this.props.onFilter(f)
    }

    onUnFilter = () => {
        let f = Map({}).set(this.state.fieldfilter, "")
        this.props.onFilter(f)
    }

    format = value => {
        const num = value.match(/[0-9]{10,11}/)
        if (num) {
            value = value.replace(num[0], formatPhone(num[0]))
        }
        return value
    }

    render() {
        const cdr = this.props.cdr
        const active = this.props.filter.get(this.state.fieldfilter) === cdr.get(this.state.fieldfilter)
        const value = cdr.get(this.state.fieldfilter)
        const calldate = fixTime(cdr.get("calldate"), this.props.timeOffset)

        const icon = (
            <div
                style={{position: "absolute", marginLeft: "-18px", marginTop: "-20px"}}
                title={active ? `Remove filter by ${value}` : `Filter by ${value}`}
            >
                {" "}
                <span className="fa-layers fa-fw">
                    <Icon icon="filter" inverse onClick={this.onFilter} />
                    {active && <Icon size="2x" icon="ban" color="red" style={{marginLeft: "-5px"}} onClick={this.onUnFilter} />}
                </span>
            </div>
        )

        return (
            <tr>
                <td className="d-none d-lg-table-cell">{cdr.get("id")}</td>
                {this.props.role !== "client" && <td>{cdr.get("direction") === "in" ? "In" : "Out"}</td>}
                {this.props.role !== "client" && (
                    <td onMouseEnter={() => this.showFilter("did")} onMouseLeave={this.hideFilter}>
                        {formatPhone(cdr.get("did"))}
                        {this.state.fieldfilter === "did" && icon}
                    </td>
                )}
                <td onMouseEnter={() => this.showFilter("src")} onMouseLeave={this.hideFilter} className="d-none d-lg-table-cell">
                    {formatPhone(cdr.get("src"))}
                    {this.state.fieldfilter === "src" && icon}
                </td>
                {this.props.role === "client" && <td>{formatPhone(cdr.get("userfield"))}</td>}
                {this.props.role !== "client" && <td>{this.format(cdr.get("dcontext"))}</td>}
                <td title={`${cdr.get("duration")} seconds`}>{formatTime(cdr.get("duration"))}</td>
                {this.props.role !== "client" && <td>{formatMoney(cdr.get("price"))}</td>}
                {this.props.role === "admin" && (
                    <td onMouseEnter={() => this.showFilter("email")} onMouseLeave={this.hideFilter} className="d-none d-lg-table-cell">
                        {cdr.get("email")}
                        {this.state.fieldfilter === "email" && icon}
                    </td>
                )}
                <td>{calldate.format("L LT")}</td>
            </tr>
        )
    }
}

export default Cdr
