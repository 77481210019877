import * as actions from "../actions/routinggroups"
import axios from "axios"
import config from "../config"
import {List, fromJS} from "immutable"
import * as loading from "../actions/loading"

const routingGroupsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "ROUTINGGROUPS_LOAD":
                store.dispatch(loading.start())
                store.dispatch(actions.loading())
                axios
                    .get(`${config.apiEndpoint}routinggroups`)
                    .then(response => {
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaded(response.data))
                    })
                    .catch(e => {
                        const msg = e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message
                        console.log(msg)
                        store.dispatch(loading.end())
                        store.dispatch(actions.loaderror("Error loading routing groups list"))
                    })
                break

            case "ROUTINGGROUPS_GET":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}routinggroup/${action.id}`)
                    .then(response => {
                        store.dispatch(loading.end())
                        let data = fromJS(response.data)
                        if (!data.has("contacts")) {
                            data = data.set("contacts", List([]))
                        }
                        action.resolve(data)
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "ROUTINGGROUPS_USAGE":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}routinggroup/${action.id}/usage`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "ROUTINGGROUPS_UNLINK":
                store.dispatch(loading.start())
                axios
                    .post(`${config.apiEndpoint}routinggroup/${action.id}/unlink`, action.items)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "ROUTINGGROUPS_DELETE":
                store.dispatch(loading.start())
                axios
                    .delete(`${config.apiEndpoint}routinggroup/${action.id}`, {
                        data: action.newrouting,
                    })
                    .then(() => {
                        store.dispatch(loading.end())
                        action.resolve()
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "ROUTINGGROUPS_SAVE":
                store.dispatch(loading.start())
                const routinggroup = action.routinggroup
                const noid = routinggroup.delete("id").toJS()
                const id = routinggroup.get("id")
                const req = id
                    ? axios.put(`${config.apiEndpoint}routinggroup/${id}`, noid)
                    : axios.post(`${config.apiEndpoint}routinggroup`, noid)
                req.then(response => {
                    store.dispatch(loading.end())
                    action.resolve()
                }).catch(e => {
                    store.dispatch(loading.end())
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            default:
                return next(action)
        }
    }
})()

export default routingGroupsMiddleware
