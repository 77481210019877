import React, {PureComponent} from "react"
import {Button, Modal} from "react-bootstrap"
import DidGroupEdit from "./DidGroupEdit"
import SpinnerButton from "./SpinnerButton"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import * as actions from "./actions/groups"
import * as didActions from "./actions/dids"
import {Map, List, Set} from "immutable"
import Icon from "./Icon"
import iconDids from "./images/menu/dids.svg"
import {load as loadSmsEndpoints} from "./actions/smsendpoints"

class DidGroupEditControlled extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            saving: false,
            didgroup: props.didgroup,
            disabled: this.isDisabled(props.didgroup, !!props.user.get("opt_ivr_enabled") || props.user.get("clienttype") === "wholesale"),
            ivrs: props.ivrs,
            contacts: props.contacts,
            routinggroups: props.routinggroups,
            dids: List([]),
            loaderror: "",
            checked: Set([]),
            enableRouting: !!props.user.get("opt_ivr_enabled") || props.user.get("clienttype") === "wholesale",
        }
    }

    reload = () => {
        if (!this.state.didgroup.get("id")) return
        didActions
            .loadFiltered(Map({group: this.state.didgroup.get("id").toString()}), this.props.dispatch)
            .then(response => this.setState({dids: response, loaderror: ""}))
            .catch(error => {
                this.setState({loaderror: error})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    isDisabled = (g, er) => {
        return !g.get("name") || (er && g.get("dest_type") !== "dnc" && !g.get("dest_value"))
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.didgroup !== prevState.didgroup) {
            this.setState({
                disabled: this.isDisabled(this.state.didgroup, this.state.enableRouting),
            })
        }
    }

    componentDidMount = () => {
        this.reload()
        loadSmsEndpoints(this.props.dispatch)
            .then(data => this.setState({smsendpoints: data}))
            .catch(e => {
                console.log(e)
                toast.error("Cannot load SMS endpoints", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
            })
    }

    onSaveDidGroup = () => {
        this.setState({saving: true})
        actions
            .save(this.state.didgroup, this.props.dispatch)
            .then(() => {
                toast.success("DID group saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose(true)
                this.props.dispatch(actions.load())
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onChange = didgroup => {
        this.setState({
            didgroup: didgroup,
        })
    }

    onChangeChecked = checked => {
        this.setState({checked: checked})
    }

    onDelete = items => {
        actions
            .unlink(this.state.didgroup.get("id"), items, this.props.dispatch)
            .then(response => {
                toast.success("Deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.reload()
                this.props.onReload()
            })
            .catch(error => {
                this.setState({loaderror: error})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onDeleteOne = id => {
        if (!window.confirm("Delete the DID from the group?")) return
        this.onDelete([id])
    }

    onDeleteChecked = () => {
        if (!window.confirm("Delete selected DIDs from the group?")) return
        this.onDelete(this.state.checked.toJS())
    }

    render() {
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            {this.state.didgroup.get("id") === 0 && <span>New DID group</span>}
                            {this.state.didgroup.get("id") !== 0 && <span>Edit DID group</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DidGroupEdit
                        item={this.state.didgroup}
                        onChange={this.onChange}
                        dids={this.state.dids}
                        ivrs={this.state.ivrs}
                        contacts={this.state.contacts}
                        routinggroups={this.state.routinggroups}
                        smsendpoints={this.state.smsendpoints}
                        checked={this.state.checked}
                        onChangeChecked={this.onChangeChecked}
                        onDelete={this.onDeleteOne}
                        enableRouting={this.state.enableRouting}
                    />
                </Modal.Body>

                <Modal.Footer>
                    {!!this.state.didgroup.get("id") && (
                        <Button
                            variant="danger"
                            className="mr-auto"
                            disabled={this.state.checked.size === 0}
                            onClick={this.onDeleteChecked}
                        >
                            Delete
                        </Button>
                    )}
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveDidGroup} active={this.state.saving} disabled={this.state.disabled} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.contacts.get("data"),
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(DidGroupEditControlled)
