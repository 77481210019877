import React from "react"
import {connect} from "react-redux"
import {Button} from "react-bootstrap"
import {Map} from "immutable"

import * as actions from "../actions/clients"
import Icon from "../Icon"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"

import Clients from "./Clients"
import ClientEditControlled from "./ClientEditControlled"
import ContactEditControlled from "../contacts/ContactEditControlled"
import DeleteModal from "../DeleteModal"
import {toast} from "react-toastify"

class ClientsControlled extends React.PureComponent {
    state = {
        clients: null,
        edit: null,
        editContact: null,
        deleting: null,
    }

    newClient = Map({
        id: 0,
        title: "",
        number: "",
        clientuserid: null,
        maxcalls: 0,
        password: "<hidden>",
        dest_type: "hangup",
    })

    newContact = Map({
        id: 0,
        title: "",
        number: "",
        contactuserid: null,
        maxcalls: 0,
        password: "<hidden>",
        dest_type: "hangup",
        siptrunk: "",
        mode: "did",
    })

    componentDidMount = () => {
        this.reload()
    }

    reload = () => {
        actions
            .load(this.props.dispatch)
            .then(response => this.setState({clients: response}))
            .catch(e => console.log(e))
    }

    onEdit = client => {
        this.setState({
            edit: client,
        })
    }

    onNewContact = clientuserid => {
        this.setState({
            editContact: this.newContact.set("clientuserid", clientuserid),
        })
    }

    onEditContact = contact => {
        this.setState({
            editContact: contact,
        })
    }

    onDelete = client => {
        if (!window.confirm("Delete?")) return
        actions
            .del(client.get("id"), this.props.dispatch)
            .then(() => {
                toast.success("Client deleted", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.reload()
            })
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    onClose = reload => {
        if (reload === true) this.reload()
        this.setState({edit: null})
    }

    render() {
        return (
            <div>
                <Title title="Clients" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newClient)}>
                            <Icon icon="plus" /> Add Client
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.state.clients !== null && (
                    <Clients
                        data={this.state.clients}
                        contacts={this.props.contacts}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                        onReload={this.reload}
                        onEditContact={this.onEditContact}
                        onNewContact={this.onNewContact}
                    />
                )}
                {this.state.edit !== null && <ClientEditControlled client={this.state.edit} onClose={this.onClose} />}
                {this.state.editContact !== null && (
                    <ContactEditControlled contact={this.state.editContact} onClose={() => this.setState({editContact: null})} />
                )}
                {this.state.deleting && (
                    <DeleteModal
                        id={this.state.deleting.get("id")}
                        title={this.state.deleting.get("title")}
                        type="client"
                        onClose={() => this.setState({deleting: null})}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.contacts.get("data"),
    }
}

export default connect(mapStateToProps)(ClientsControlled)
