import React, {Component} from "react"
import {connect} from "react-redux"
import {Button, Modal, Form, Col, Row, Alert} from "react-bootstrap"
import iconDids from "./images/menu/dids.svg"
import Icon from "./Icon"
import GroupField from "./GroupField"

class DidsChangeTagsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            group: null,
        }
    }

    onChange = () => {
        this.props.onChange(this.state.group)
    }

    onChangeField = e => {
        this.setState({group: e.target.value})
    }

    render() {
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            Bulk Change DID groups
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column xs="3">
                                    DID group
                                </Form.Label>
                                <Col xs="9">
                                    <GroupField group={this.state.group} groups={this.props.groups} onChange={this.onChangeField} />
                                </Col>
                            </Form.Group>
                        </Form>
                        {this.props.isAdmin && (
                            <Alert variant="warning">
                                <strong>Warning!</strong> DID groups will be changed only for DIDs created by you
                            </Alert>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="info" className="continue" onClick={this.onChange} disabled={this.props.groups.size === 0}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        groups: state.groups.get("data"),
    }
}

export default connect(mapStateToProps)(DidsChangeTagsModal)
