export function load() {
    return {
        type: "USERS_LOAD",
    }
}

export function loading() {
    return {
        type: "USERS_LOADING",
    }
}

export function loaded(data) {
    return {
        type: "USERS_LOADED",
        data: data,
    }
}

export function loaderror(msg) {
    return {
        type: "USERS_LOADERROR",
        msg: msg,
    }
}

export function save(user, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "USERS_SAVE",
            user: user,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(user, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "USERS_DELETE",
            user: user,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function renewSmsApiKey(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "USERS_RENEW_SMS_APIKEY",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function renewApiKey(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "USERS_RENEW_APIKEY",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}
