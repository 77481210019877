import React, {PureComponent} from "react"
import {Button, Form, Row, Col, Tabs, Tab} from "react-bootstrap"
import config from "./config"
import statesMap from "./states"
import {Map} from "immutable"

const states = Map(statesMap).sort()

class UserEdit extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            changepwd: props.user.get("id") === 0 ? true : false,
        }

        this.billingIncrements = [1, 2, 3, 4, 5, 6, 12, 18, 24, 30, 60]
    }

    componentDidUpdate = nextProps => {
        if (this.props.user !== nextProps.user) {
            this.setState({
                user: this.props.user,
            })
        }
    }

    changePwd = () => {
        this.setState({changepwd: true})
    }

    changeField = e => {
        this.changeFieldValue(e.target.name, e.target.value)
    }

    changeIntField = e => {
        this.changeFieldValue(e.target.name, parseInt(e.target.value, 10))
    }

    changeFieldValue = (field, value) => {
        this.props.onChange(this.props.user.set(field, value))
    }

    onChangeFieldBlacklistEnabled = e => {
        this.changeFieldValue("opt_blacklist_check", e.target.checked ? "check" : "disabled")
    }

    onChangeFieldIVREnabled = e => {
        this.changeFieldValue("opt_ivr_enabled", e.target.checked ? 1 : 0)
    }

    onChangeFieldBotsEnabled = e => {
        this.changeFieldValue("opt_bots_enabled", e.target.checked ? 1 : 0)
    }

    onChangeFieldSMSEnabled = e => {
        this.changeFieldValue("opt_sms_enabled", e.target.checked ? 1 : 0)
    }

    onChangeFieldBlacklistAutoremove = e => {
        this.changeFieldValue("opt_blacklist_check", e.target.checked ? "autoremove" : "check")
    }

    onChangeTrunks = e => {
        let trunks = this.props.user.get("trunks")
        let key = parseInt(e.target.value, 10)
        if (e.target.checked) trunks = trunks.add(key)
        else trunks = trunks.remove(key)
        this.changeFieldValue("trunks", trunks)
    }

    render() {
        const isNew = !this.props.user.get("id")

        return (
            <Form>
                <Tabs defaultActiveKey="general" id="tabs-user-edit">
                    <Tab eventKey="general" title="General">
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Email
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Email Address"
                                    value={this.props.user.get("email")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Password
                            </Form.Label>
                            <Col sm="9">
                                {!this.state.changepwd && (
                                    <Button className="changebt" onClick={this.changePwd}>
                                        Change
                                    </Button>
                                )}
                                {this.state.changepwd && (
                                    <Form.Control name="password" type="text" placeholder="Password" onChange={this.changeField} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Role
                            </Form.Label>
                            <Col sm="9">
                                <Form.Check
                                    inline
                                    label="Admin"
                                    type="radio"
                                    name="role"
                                    value="admin"
                                    defaultChecked={this.props.user.get("role") === "admin"}
                                    onChange={this.changeField}
                                />
                                <Form.Check
                                    inline
                                    label="User"
                                    type="radio"
                                    name="role"
                                    value="user"
                                    defaultChecked={this.props.user.get("role") !== "admin"}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Client Type
                            </Form.Label>
                            <Col sm="9">
                                <Form.Check
                                    inline
                                    label="Statistics"
                                    type="radio"
                                    name="clienttype"
                                    value="statistics"
                                    defaultChecked={this.props.user.get("clienttype") === "statistics"}
                                    onChange={this.changeField}
                                />
                                <Form.Check
                                    inline
                                    label="Wholesale"
                                    type="radio"
                                    name="clienttype"
                                    value="wholesale"
                                    defaultChecked={this.props.user.get("clienttype") !== "statistics"}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Blacklist Check
                            </Form.Label>
                            <Col sm="9">
                                <Form.Check
                                    inline
                                    label="Enable Blacklist Check"
                                    type="checkbox"
                                    checked={this.props.user.get("opt_blacklist_check") !== "disabled"}
                                    onChange={this.onChangeFieldBlacklistEnabled}
                                />
                                <Form.Check
                                    inline
                                    label="Autoremove Blacklisted Numbers"
                                    type="checkbox"
                                    checked={this.props.user.get("opt_blacklist_check") === "autoremove"}
                                    disabled={this.props.user.get("opt_blacklist_check") === "disabled"}
                                    onChange={this.onChangeFieldBlacklistAutoremove}
                                />
                            </Col>
                        </Form.Group>
                        {!!config.enableIVR && (
                            <>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        IVR
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Check
                                            inline
                                            label="Enable"
                                            type="checkbox"
                                            checked={this.props.user.get("opt_ivr_enabled")}
                                            onChange={this.onChangeFieldIVREnabled}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Virtual Assistants
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Check
                                            inline
                                            label="Enable"
                                            type="checkbox"
                                            checked={this.props.user.get("opt_bots_enabled")}
                                            onChange={this.onChangeFieldBotsEnabled}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        SMS
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Check
                                            inline
                                            label="Enable"
                                            type="checkbox"
                                            checked={this.props.user.get("opt_sms_enabled")}
                                            onChange={this.onChangeFieldSMSEnabled}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Trunks
                                    </Form.Label>
                                    <Col sm="9">
                                        {Object.keys(config.trunks).map(key => (
                                            <Form.Check
                                                key={key}
                                                inline
                                                label={config.trunks[key].title}
                                                value={key}
                                                type="checkbox"
                                                checked={this.props.user.get("trunks").has(parseInt(key, 10))}
                                                onChange={this.onChangeTrunks}
                                            />
                                        ))}
                                    </Col>
                                </Form.Group>
                                {!isNew && (
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            API key
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                style={{width: "300px", display: "inline-block"}}
                                                type="text"
                                                readOnly
                                                value={this.props.user.get("apikey")}
                                            />
                                            <Button variant="link" style={{paddingTop: "2px"}} onClick={this.props.onRenewApiKey}>
                                                Renew
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                )}
                            </>
                        )}
                    </Tab>
                    <Tab eventKey="billing" title="Billing">
                        <Form.Group as={Row} className={this.props.errors.has("ratein") ? "error" : ""}>
                            <Form.Label column sm="4"></Form.Label>
                            <Col sm={8}>
                                <Row>
                                    <Col sm="4" className="center">
                                        Regular DIDs
                                    </Col>
                                    <Col sm="4" className="center">
                                        Toll-free DIDs
                                    </Col>
                                    <Col sm="4" className="center">
                                        SIP
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Inbound Rate <sup>USD/min.</sup>
                            </Form.Label>
                            <Col sm={8}>
                                <Row>
                                    <Col sm="4" className={this.props.errors.has("ratein") ? "error" : ""}>
                                        <Form.Control
                                            type="text"
                                            name="ratein"
                                            placeholder="Inbound Rate (USD/min.)"
                                            value={this.props.user.get("ratein")}
                                            onChange={this.changeField}
                                        />
                                    </Col>
                                    <Col sm="4" className={this.props.errors.has("rateintf") ? "error" : ""}>
                                        <Form.Control
                                            type="text"
                                            name="rateintf"
                                            placeholder="Inbound Rate (USD/min.)"
                                            value={this.props.user.get("rateintf")}
                                            onChange={this.changeField}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Outbound Rate <sup>USD/min.</sup>
                            </Form.Label>
                            <Col sm={8}>
                                <Row>
                                    <Col sm="4" className={this.props.errors.has("rateout") ? "error" : ""}>
                                        <Form.Control
                                            type="text"
                                            name="rateout"
                                            placeholder="Outbound Rate (USD/min.)"
                                            value={this.props.user.get("rateout")}
                                            onChange={this.changeField}
                                        />
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm="4" className={this.props.errors.has("rateoutsip") ? "error" : ""}>
                                        <Form.Control
                                            type="text"
                                            name="rateoutsip"
                                            placeholder="Outbound Rate (USD/min.)"
                                            value={this.props.user.get("rateoutsip")}
                                            onChange={this.changeField}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={this.props.errors.has("ratein") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Billing Increment <sup>sec.</sup>
                            </Form.Label>
                            <Col sm={8}>
                                <Row>
                                    <Col sm="4">
                                        <Form.Control
                                            as="select"
                                            name="billinginc"
                                            value={this.props.user.get("billinginc")}
                                            onChange={this.changeIntField}
                                        >
                                            {this.billingIncrements.map(inc => (
                                                <option key={inc} value={inc}>
                                                    {inc}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col sm="4">
                                        <Form.Control
                                            as="select"
                                            name="billinginctf"
                                            value={this.props.user.get("billinginctf")}
                                            onChange={this.changeIntField}
                                        >
                                            {this.billingIncrements.map(inc => (
                                                <option key={inc} value={inc}>
                                                    {inc}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col sm="4">
                                        <Form.Control
                                            as="select"
                                            name="billingincsip"
                                            value={this.props.user.get("billingincsip")}
                                            onChange={this.changeIntField}
                                        >
                                            {this.billingIncrements.map(inc => (
                                                <option key={inc} value={inc}>
                                                    {inc}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>

                        <hr />

                        <Form.Group as={Row}>
                            <Form.Label column sm="4"></Form.Label>
                            <Col sm="4" className="center">
                                Regular DIDs
                            </Col>
                            <Col sm="4" className="center">
                                Toll-free DIDs
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                DID Order Price
                            </Form.Label>
                            <Col sm="4" className={this.props.errors.has("priceorder") ? "error" : ""}>
                                <Form.Control
                                    type="text"
                                    name="priceorder"
                                    placeholder="Order Price"
                                    value={this.props.user.get("priceorder")}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm="4" className={this.props.errors.has("priceordertf") ? "error" : ""}>
                                <Form.Control
                                    type="text"
                                    name="priceordertf"
                                    placeholder="Order Price"
                                    value={this.props.user.get("priceordertf")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                DID Cancelation Price
                            </Form.Label>
                            <Col sm="4" className={this.props.errors.has("pricecancel") ? "error" : ""}>
                                <Form.Control
                                    type="text"
                                    name="pricecancel"
                                    placeholder="Cancelation Price"
                                    value={this.props.user.get("pricecancel")}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm="4" className={this.props.errors.has("pricecanceltf") ? "error" : ""}>
                                <Form.Control
                                    type="text"
                                    name="pricecanceltf"
                                    placeholder="Cancelation Price"
                                    value={this.props.user.get("pricecanceltf")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                DID Monthly Price
                            </Form.Label>
                            <Col sm="4" className={this.props.errors.has("pricemonthly") ? "error" : ""}>
                                <Form.Control
                                    type="text"
                                    name="pricemonthly"
                                    placeholder="Monthly Price"
                                    value={this.props.user.get("pricemonthly")}
                                    onChange={this.changeField}
                                />
                            </Col>
                            <Col sm="4" className={this.props.errors.has("pricemonthlytf") ? "error" : ""}>
                                <Form.Control
                                    type="text"
                                    name="pricemonthlytf"
                                    placeholder="Monthly Price"
                                    value={this.props.user.get("pricemonthlytf")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <hr />

                        <Form.Group as={Row} className={this.props.errors.has("notificationlimit") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Notification Limit
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="notificationlimit"
                                    placeholder="Notification Limit"
                                    value={this.props.user.get("notificationlimit")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <hr />

                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Outbound SMS Price
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="pricesms"
                                    placeholder="SMS Price"
                                    value={this.props.user.get("pricesms")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Inbound SMS Price
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="pricesmsin"
                                    placeholder="SMS Price"
                                    value={this.props.user.get("pricesmsin")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <hr />

                        <Form.Group as={Row} className={this.props.errors.has("suspensionlimit") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Suspension Limit
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="suspensionlimit"
                                    placeholder="Suspension Limit"
                                    value={this.props.user.get("suspensionlimit")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <hr />

                        <Form.Group as={Row} className={this.props.errors.has("mintopup") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Minimal Top-Up Amount <sup>USD</sup>
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="mintopup"
                                    placeholder="Minimal Top-Up Amount"
                                    value={this.props.user.get("mintopup")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("servicefeepaypal") ? "error" : ""}>
                            <Form.Label column sm="4">
                                PayPal Service Fee <sup>%</sup>
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="servicefeepaypal"
                                    placeholder="PayPal Service Fee"
                                    value={this.props.user.get("servicefeepaypal")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("servicefeestripe") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Stripe Service Fee <sup>%</sup>
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="servicefeestripe"
                                    placeholder="Stripe Service Fee"
                                    value={this.props.user.get("servicefeestripe")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                    </Tab>
                    <Tab eventKey="billingprofile" title="Billing Profile">
                        <Form.Group as={Row} className={this.props.errors.has("companyname") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Company Name
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="companyname"
                                    placeholder="Company Name"
                                    value={this.props.user.get("companyname")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={this.props.errors.has("contactname") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Contact Name
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="contactname"
                                    placeholder="Contact Name"
                                    value={this.props.user.get("contactname")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("address") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Address
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    value={this.props.user.get("address")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("city") ? "error" : ""}>
                            <Form.Label column sm="4">
                                City
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={this.props.user.get("city")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("state") ? "error" : ""}>
                            <Form.Label column sm="4">
                                State
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    as="select"
                                    name="state"
                                    placeholder="State"
                                    value={this.props.user.get("state")}
                                    onChange={this.changeField}
                                >
                                    <option>Select State</option>
                                    {states.keySeq().map(key => (
                                        <option key={key} value={key}>
                                            {states.get(key)}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("zip") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Zip
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="zip"
                                    placeholder="Zip"
                                    value={this.props.user.get("zip")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={this.props.errors.has("phone") ? "error" : ""}>
                            <Form.Label column sm="4">
                                Phone
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="Phone"
                                    value={this.props.user.get("phone")}
                                    onChange={this.changeField}
                                />
                            </Col>
                        </Form.Group>
                    </Tab>
                </Tabs>
            </Form>
        )
    }
}
export default UserEdit
