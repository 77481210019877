import initialState from "./initial"
import {List, fromJS} from "immutable"

const ivrsReducer = (state = initialState.ivrs, action) => {
    if (action.type === "IVRS_LOADING") {
        return state.set("loading", true)
    }
    if (action.type === "IVRS_LOADERROR") {
        return state
            .set("data", List([]))
            .set("loaded", false)
            .set("loading", false)
            .set("loaderror", action.msg)
    }
    if (action.type === "IVRS_LOADED") {
        state = state
            .set("data", fromJS(action.data))
            .set("loaded", true)
            .set("loading", false)
            .set("loaderror", "")
        return state
    }
    return state
}

export default ivrsReducer
