import React, {Component} from "react"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import {Card, Col, Row} from "react-bootstrap"
import * as actions from "./actions/reports"
import * as loading from "./actions/loading"
import Title from "./Title"
import {formatNumber} from "./tools"

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = this.propsToState(props)
    }

    propsToState = props => {
        return {
            user: props.user.toJS(),
        }
    }

    reload = spinner => {
        if (spinner === true) {
            this.props.dispatch(loading.start())
        }
        actions
            .infoLoad(this.props.dispatch)
            .then(data => {
                if (spinner === true) {
                    this.props.dispatch(loading.end())
                }
                let info = {}
                if (data.cdrs) {
                    data.cdrs.forEach(item => {
                        const direction = item.direction
                        const period = item.period
                        if (info[direction] === undefined) {
                            info[direction] = {
                                today: {},
                                week: {},
                                month: {},
                            }
                        }
                        info[direction][period] = {num: item.num, duration: item.duration}
                    })
                }
                data.calls = info
                this.setState({info: data})
            })
            .catch(e => {
                if (spinner === true) {
                    this.props.dispatch(loading.end())
                }
                toast.error("Error loading statistics", {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    componentDidMount = () => {
        this.reload(true)
        this.timer = setInterval(() => this.reload(false), 60000)
    }

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    render() {
        if (!this.state.user || !this.state.info) {
            return true
        }

        return (
            <>
                <Title title="Dashboard" />
                <Row style={{paddingTop: "20pt"}}>
                    {this.state.info.users && (
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Users</Card.Title>
                                    <Card.Text as="div">
                                        Active users: <strong>{this.state.info.users.active}</strong>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    {this.state.info.dids && (
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>DIDs</Card.Title>
                                    <Card.Text>
                                        Active dids: <strong>{this.state.info.dids.active}</strong>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    {this.state.info.jobs && (
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Jobs</Card.Title>
                                    <Card.Text as="div">
                                        Active jobs: <strong>{this.state.info.jobs.active}</strong>
                                        <br />
                                        Finished jobs: <strong>{this.state.info.jobs.finished}</strong>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    {!!this.state.info.calls.in && (
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Inbound Calls</Card.Title>
                                    <Card.Text as="div">
                                        <div>
                                            Today:{" "}
                                            <strong>
                                                {formatNumber(this.state.info.calls.in.today.num || 0)} (
                                                {formatNumber((this.state.info.calls.in.today.duration || 0) / 60)} minutes)
                                            </strong>
                                        </div>
                                        <div>
                                            Week:{" "}
                                            <strong>
                                                {formatNumber(this.state.info.calls.in.week.num || 0)} (
                                                {formatNumber((this.state.info.calls.in.week.duration || 0) / 60)} minutes)
                                            </strong>
                                        </div>
                                        <div>
                                            Month:{" "}
                                            <strong>
                                                {formatNumber(this.state.info.calls.in.month.num || 0)} (
                                                {formatNumber((this.state.info.calls.in.month.duration || 0) / 60)} minutes)
                                            </strong>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    {!!this.state.info.calls.out && (
                        <Col sm={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Outbound Calls</Card.Title>
                                    <Card.Text as="div">
                                        <div>
                                            Today:{" "}
                                            <strong>
                                                {formatNumber(this.state.info.calls.out.today.num || 0)} (
                                                {formatNumber((this.state.info.calls.out.today.duration || 0) / 60)} minutes)
                                            </strong>
                                        </div>
                                        <div>
                                            Week:{" "}
                                            <strong>
                                                {formatNumber(this.state.info.calls.out.week.num || 0)} (
                                                {formatNumber((this.state.info.calls.out.week.duration || 0) / 60)} minutes)
                                            </strong>
                                        </div>
                                        <div>
                                            Month:{" "}
                                            <strong>
                                                {formatNumber(this.state.info.calls.out.month.num || 0)} (
                                                {formatNumber((this.state.info.calls.out.month.duration || 0) / 60)} minutes)
                                            </strong>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(Home)
