export function load(filter, start, limit, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BLACKLIST_LOAD",
            filter: filter,
            start: start,
            limit: limit,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function csv(filter, sortfield, sortdir, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BLACKLIST_CSV_LINK",
            filter: filter,
            sortfield: sortfield,
            sortdir: sortdir,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function save(blacklist, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BLACKLIST_SAVE",
            blacklist: blacklist,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function usage(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BLACKLIST_USAGE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function del(id, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BLACKLIST_DELETE",
            id: id,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function delAll(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "BLACKLIST_DELETE_ALL",
            resolve: resolve,
            reject: reject,
        })
    })
}
