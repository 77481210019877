import reducers from "./reducers"
import {combineReducers} from "redux"
import {createBrowserHistory} from "history"
import {createStore, applyMiddleware, compose} from "redux"
import authMiddleware from "./authMiddleware"
import didsMiddleware from "./didsMiddleware"
import usersMiddleware from "./usersMiddleware"
import contactsMiddleware from "./contactsMiddleware"
import clientsMiddleware from "./clientsMiddleware"
import jobsMiddleware from "./jobsMiddleware"
import ivrsMiddleware from "./ivrsMiddleware"
import botsMiddleware from "./botsMiddleware"
import recordingsMiddleware from "./recordingsMiddleware"
import ratecentersMiddleware from "./ratecentersMiddleware"
import templatesMiddleware from "./templatesMiddleware"
import reportsMiddleware from "./reportsMiddleware"
import groupsMiddleware from "./groupsMiddleware"
import routingGroupsMiddleware from "./routingGroupsMiddleware"
import blacklistMiddleware from "./blacklistMiddleware"
import transactionsMiddleware from "./transactionsMiddleware"
import stripeMiddleware from "./stripeMiddleware"
import smsEndpointsMiddleware from "./smsEndpointsMiddleware"
import {connectRouter, routerMiddleware} from "connected-react-router"
import thunk from "redux-thunk"

const history = createBrowserHistory()
reducers.router = connectRouter(history)

const store = createStore(
    connectRouter(history)(combineReducers(reducers)),
    compose(
        applyMiddleware(
            thunk,
            authMiddleware,
            didsMiddleware,
            usersMiddleware,
            contactsMiddleware,
            clientsMiddleware,
            jobsMiddleware,
            ivrsMiddleware,
            botsMiddleware,
            recordingsMiddleware,
            ratecentersMiddleware,
            reportsMiddleware,
            templatesMiddleware,
            groupsMiddleware,
            routingGroupsMiddleware,
            blacklistMiddleware,
            transactionsMiddleware,
            stripeMiddleware,
            smsEndpointsMiddleware,
            routerMiddleware(history),
        ),
    ),
)

export {store, history}
