import React from "react"
import {fromJS, List} from "immutable"
import {Button, Form, Col, Row} from "react-bootstrap"
import {isNumber} from "../tools"
import StatesMap from "../states.js"
import {Typeahead, Menu, MenuItem} from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "react-bootstrap-typeahead/css/Typeahead-bs4.min.css"
import onClickOutside from "react-onclickoutside"

class Comp extends React.Component {
    handleClickOutside = e => {
        if (this.props.onClick) this.props.onClick(e)
    }

    render() {
        return this.props.children
    }
}

export const OutsideClickMonitor = onClickOutside(Comp)

class RoutingGroupContactEdit extends React.PureComponent {
    state = {
        statesEditor: false,
    }

    changeField = e => {
        let field = e.target.name
        let value = e.target.value
        this.changeFieldValue(field, value)
    }

    changeFieldValue = (field, value) => {
        if (field === "forwards" && isNumber(value)) {
            value = parseInt(value, 10)
        }
        let contact = this.props.contact.set(field, value)
        if (field === "action") {
            contact = contact.set("value", "")
        }
        if (field === "mindurationtext") {
            try {
                const s = value.split(":")
                const v = parseInt(s[0], 10) * 60 + parseInt(s[1], 10)
                if (!isNaN(v) && v >= 0) {
                    contact = contact.set("minduration", v)
                }
            } catch (e) {
                console.log(e)
            }
        }
        this.props.onChange(contact)
    }

    formatStates = states => {
        let tmp = List([])

        if (states.count() === 0) {
            tmp = tmp.push("(edit)")
        } else if (states.count() < 5) {
            tmp = states.sort()
        } else {
            tmp = states
                .sort()
                .slice(0, 4)
                .push("...")
        }

        return (
            <Button variant="link" onClick={() => this.setState({statesEditor: true})}>
                {tmp.join(" ")}
            </Button>
        )
    }

    onStateBlur = () => {
        this.setState({statesEditor: false})
    }

    render() {
        const contact = this.props.contact
        let states = []
        if (contact.get("omitstates")) {
            contact.get("omitstates").forEach(state => states.push({id: state, label: StatesMap[state]}))
        }

        return (
            <>
                <Col xs={11} sm={7}>
                    <Row>
                        <Col xs={12} sm={4}>
                            <Form.Control as="select" name="contact" value={contact.get("contact")} onChange={this.changeField}>
                                {!contact.get("contact") && (
                                    <option disabled value="">
                                        Select Contact
                                    </option>
                                )}
                                {this.props.contacts.map(contact => (
                                    <option key={contact.get("id")} value={contact.get("id")}>
                                        {contact.get("title")} ({contact.get("mode") === "did" && contact.get("number")}
                                        {contact.get("mode") === "sip" && contact.get("siptrunk")})
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col className="d-none d-sm-block" sm={4}>
                            <Form.Control
                                type="text"
                                name="mindurationtext"
                                placeholder="M:SS"
                                value={contact.get("mindurationtext")}
                                onChange={this.changeField}
                            />
                        </Col>
                        <Col className="d-none d-sm-block" xs={4}>
                            {!this.state.statesEditor && this.formatStates(contact.get("omitstates"))}
                            {!!this.state.statesEditor && (
                                <OutsideClickMonitor onClick={this.onStateBlur}>
                                    <Typeahead
                                        id="users-select"
                                        selected={states}
                                        onChange={selected => {
                                            console.log("change")
                                            this.changeFieldValue("omitstates", fromJS(selected.map(value => value.id)))
                                        }}
                                        labelKey={option => option.id}
                                        options={Object.keys(StatesMap).map(key => {
                                            return {id: key, label: StatesMap[key]}
                                        })}
                                        placeholderText="Select States"
                                        multiple
                                        renderMenu={(results, menuProps) => (
                                            <Menu {...menuProps}>
                                                {results.map((result, index) => (
                                                    <MenuItem key={index} option={result} position={index}>
                                                        {result.label}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        )}
                                        open
                                    />
                                </OutsideClickMonitor>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} className="d-none d-sm-block">
                    <Form.Control type="text" name="forwards" placeholder="" value={contact.get("forwards")} onChange={this.changeField} />
                </Col>
                <Col sm={1} className="d-none d-sm-block">
                    <Form.Control plaintext readOnly defaultValue={contact.get("forwardsused")} style={{textAlign: "center"}} />
                </Col>
                <Col xs={1} sm={2} className="buttons">
                    <Button className="float-right responsive" size="sm" variant="danger" onClick={this.props.onDelete}>
                        Delete
                    </Button>
                </Col>
            </>
        )
    }
}

export default RoutingGroupContactEdit
