import React, {Component} from "react"
import {connect} from "react-redux"
import CdrsGrouped from "./CdrsGrouped"
import {List, Map} from "immutable"
import {toast} from "react-toastify"
import {cdrsLoadGrouped} from "./actions/reports"
import {Button} from "react-bootstrap"
import Title from "./Title"
import Filters from "./filters/Filters"
import ReloadIcon from "./ReloadIcon"
import * as loading from "./actions/loading"
import {push} from "connected-react-router"
import iconReports from "./images/menu/reports.svg"
import moment from "moment"

class CdrsGroupedControlled extends Component {
    constructor(props) {
        super(props)

        this.filters = ["date1", "date2", "did", "src", "duration", "email", "group"]
        this.state = {
            data: List([]),
            filter: Map({
                date1: moment()
                    .startOf("day")
                    .toDate(),
            }),
        }
    }

    reload = () => {
        this.props.dispatch(loading.start())

        cdrsLoadGrouped(this.state.filter, this.props.dispatch)
            .then(response => {
                this.props.dispatch(loading.end())
                this.setState({data: response.data})
            })
            .catch(e => {
                toast.error("Error loading cdrs", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount() {
        this.reload()
    }

    onChangeFilter = filter => {
        this.setState({filter: filter}, () => {
            this.reload()
        })
    }

    saveFilter = did => {
        const filter = {
            did: did,
        }
        window.sessionStorage.setItem("cdrs-filter", JSON.stringify(filter))
    }

    onSelectDid = did => {
        this.saveFilter(did)
        this.props.dispatch(push(`/cdrs/`))
    }

    onExportCSV = () => {
        const csv = this.state.data
            .map(item => {
                return [item.get("did"), item.get("num")].join(",")
            })
            .toJS()
            .join("\r\n")

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        const type = isSafari ? "application/csv" : "text/csv"
        const blob = new Blob([csv], {type: type})

        const link = document.createElement("a")
        link.download = `dids-grouped.csv`
        link.href = window.URL.createObjectURL(blob)
        link.onclick = () => document.body.removeChild(link)
        document.body.appendChild(link)
        link.click()
    }

    render() {
        return (
            <>
                <Title title="Group Report" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters icon={iconReports} enabled={this.filters} filter={this.state.filter} onChange={this.onChangeFilter} />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <CdrsGrouped data={this.state.data} onSelect={this.onSelectDid} links={true} />
                <div className="actionBar d-none d-md-block">
                    <div className="float-right">
                        <Button variant="secondary" className="download" onClick={this.onExportCSV}>
                            Download CSV
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

export default connect()(CdrsGroupedControlled)
