import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"
import {fromJS} from "immutable"

const createUrl = (url, action) => {
    let filter = action.filter ? JSON.stringify(action.filter.filter(v => !!v).toJS()) : ""

    let u = `${config.apiEndpoint}${url}?filter=${encodeURIComponent(filter)}`
    if (action.start !== undefined) u = u + `&start=${action.start}&limit=${action.limit}`
    if (action.sortfield !== undefined) u = u + `&sortfield=${action.sortfield}&sortdir=${action.sortdir}`
    return u
}

const blacklistMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "BLACKLIST_LOAD":
                store.dispatch(loading.start())
                axios
                    .get(createUrl("blacklist", action))
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve({
                            data: fromJS(response.data),
                            total: parseInt(response.headers["x-total-count"], 10),
                        })
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })

                break

            case "BLACKLIST_CSV_LINK": {
                const url =
                    createUrl("blacklist", Object.assign({start: 0, limit: 1000000}, action)) + "&format=text&filename=blacklist.csv"
                action.resolve(url)
                break
            }

            case "BLACKLIST_SAVE":
                store.dispatch(loading.start())
                const blacklist = action.blacklist.toJS()

                const form_data = new FormData()
                form_data.append("clid", blacklist.clid)
                form_data.append("did", blacklist.did)
                form_data.append("notes", blacklist.notes)
                form_data.append("mode", blacklist.mode)
                if (blacklist.csv) {
                    form_data.append("csv", blacklist.csv)
                }

                const req = blacklist.id
                    ? axios.put(`${config.apiEndpoint}blacklist/${blacklist.id}`, form_data)
                    : axios.post(`${config.apiEndpoint}blacklist`, form_data)
                req.then(response => {
                    store.dispatch(loading.end())
                    action.resolve(response.data.id)
                }).catch(e => {
                    store.dispatch(loading.end())
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            case "BLACKLIST_DELETE":
                store.dispatch(loading.start())
                axios
                    .delete(`${config.apiEndpoint}blacklist/${action.id}`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve()
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "BLACKLIST_DELETE_ALL":
                store.dispatch(loading.start())
                axios
                    .delete(`${config.apiEndpoint}blacklist/all`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve()
                    })
                    .catch(e => {
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default blacklistMiddleware
