import React, {Component} from "react"
import {Button, Modal, Form, Col, Row, Alert} from "react-bootstrap"
import {Map} from "immutable"
import iconDids from "./images/menu/dids.svg"
import Icon from "./Icon"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"

class DidsChangeRoutingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            routing: Map({
                type: "",
                value: "",
            }),
        }
    }

    onChange = () => {
        this.props.onChange(this.state.routing)
    }

    onChangeField = e => {
        let name = e.target.name
        let value = e.target.value
        this.setState({
            routing: this.state.routing.set(name, value),
        })
    }

    render() {
        return (
            <Modal show backdrop animation={false}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            Bulk Change Routing
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column xs="2">
                                    Route To
                                </Form.Label>
                                <Col xs="4">
                                    <RoutingTypeSelect
                                        type="did"
                                        name="type"
                                        value={this.state.routing.get("type")}
                                        onChange={this.onChangeField}
                                    />
                                </Col>
                                <Col xs="6">
                                    <RoutingValueSelect
                                        name="value"
                                        type={this.state.routing.get("type")}
                                        value={this.state.routing.get("value")}
                                        onChange={this.onChangeField}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                        {this.props.isAdmin && (
                            <Alert variant="warning">
                                <strong>Warning!</strong> Routing will be changed only for DIDs created by you
                            </Alert>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="continue"
                        onClick={this.onChange}
                        disabled={this.state.routing.get("type") !== "dnc" && this.state.routing.get("value") === ""}
                    >
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DidsChangeRoutingModal
