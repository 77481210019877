import React from "react"
import {Table, Button} from "react-bootstrap"
import Icon from "../Icon"
import RoutingDestination from "../RoutingDestination"

class RoutingGroups extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Default Action</th>
                        {!this.props.restricted && <th>Creator</th>}
                        <th style={{width: "150px"}}></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={5}>
                                <center>No data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map(row => (
                        <tr key={row.get("id")}>
                            <td>{row.get("id")}</td>
                            <td>{row.get("title")}</td>
                            <td>{row.get("type")}</td>
                            <td>
                                <RoutingDestination type={row.get("dest_type")} value={row.get("dest_value")} />
                            </td>
                            {!this.props.restricted && <td>{row.get("email")}</td>}
                            <td style={{textAlign: "right"}}>
                                <Button variant="info" size="sm" onClick={() => this.props.onEdit(row)}>
                                    <Icon icon="pencil-alt" /> Edit
                                </Button>{" "}
                                <Button variant="danger" size="sm" onClick={() => this.props.onDelete(row)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default RoutingGroups
