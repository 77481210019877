import React, {Component} from "react"
import {Button, Modal} from "react-bootstrap"
import QuestionEdit from "./QuestionEdit"
import {toast} from "react-toastify"
import {isNumber} from "./tools"
import SpinnerButton from "./SpinnerButton"
import Icon from "./Icon"
import iconBots from "./images/menu/bots.svg"

class QuestionEditControlled extends Component {
    constructor(props) {
        super(props)

        this.state = {
            question: props.question,
            valid: this.validate(props.question, false),
            templates: props.templates,
        }
    }

    validate = (q, showAlert) => {
        const err = msg => {
            if (showAlert) {
                toast.error(msg, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
            return false
        }

        if (!q.get("name")) return err("Enter unique question ID")
        if (!q.get("questions").filter(q => !!q).size) return err("Enter questions")
        if (!isNumber(q.get("attempts"))) return err("Enter attempts count")

        const c = q.get("constraints")
        if (!isNumber(c.get("min"))) return err("Enter Min constraint")
        if (!isNumber(c.get("max"))) return err("Enter Max constraint")
        if (!isNumber(c.get("target_min"))) return err("Enter Target Min constraint")
        if (!isNumber(c.get("target_max"))) return err("Enter Target Max constraint")

        if (q.get("type") === "numeric") {
            if (c.get("min") > c.get("max")) return err("Min constraint cannot be greater than Max constraint")
            if (c.get("target_min") > c.get("target_max")) return err("Target Min constraint cannot be greater than Target Max constraint")
        }

        return true
    }

    onChange = question => {
        this.setState({
            question: question,
            valid: this.validate(question),
        })
    }

    onSave = () => {
        let q = this.state.question
        q = q.set("questions", q.get("questions").filter(q => !!q))
        q = q.set("retries", q.get("retries").filter(q => !!q))
        if (this.validate(q, true)) {
            this.props.onChange(q)
        } else {
            this.setState({question: q})
        }
    }

    render() {
        return (
            <Modal show size="lg" backdrop animation={false}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconBots} alt="" style={{marginRight: "20px"}} />
                            {this.state.question.get("id") === 0 && <span>New Question</span>}
                            {this.state.question.get("id") !== 0 && <span>Edit Question</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <QuestionEdit
                        question={this.state.question}
                        onChange={this.onChange}
                        onClose={this.props.onClose}
                        templates={this.state.templates}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSave}>Save</SpinnerButton>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default QuestionEditControlled
