import React from "react"
import {Button, Form, Col} from "react-bootstrap"
import RoutingTypeSelect from "./RoutingTypeSelect"
import RoutingValueSelect from "./RoutingValueSelect"

class IvrItemEdit extends React.PureComponent {
    changeField = e => {
        let item = this.props.item.set(e.target.name, e.target.value)
        if (e.target.name === "action") {
            item = item.set("value", "")
        }
        this.props.onChange(item)
    }

    render() {
        const item = this.props.item
        return (
            <React.Fragment>
                <Col xs={2}>
                    <Form.Control type="text" name="opt" placeholder="Opt" value={item.get("opt")} onChange={this.changeField} />
                </Col>
                <Col xs={4}>
                    <RoutingTypeSelect type="ivr" name="action" value={item.get("action")} onChange={this.changeField} />
                </Col>
                <Col xs={4}>
                    <RoutingValueSelect name="value" type={item.get("action")} value={item.get("value")} onChange={this.changeField} />
                </Col>
                <Col xs={2}>
                    <Button block variant="danger" onClick={this.props.onDelete}>
                        Delete
                    </Button>
                </Col>
            </React.Fragment>
        )
    }
}

export default IvrItemEdit
