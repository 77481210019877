import React from "react"
import {connect} from "react-redux"
import {Button} from "react-bootstrap"
import {Map} from "immutable"

import * as actions from "../actions/contacts"
import Icon from "../Icon"
import ReloadIcon from "../ReloadIcon"
import Title from "../Title"

import Contacts from "./Contacts"
import ContactEditControlled from "./ContactEditControlled"
import DeleteModal from "../DeleteModal"

class ContactsControlled extends React.PureComponent {
    state = {
        edit: null,
        deleting: null,
    }

    newContact = Map({
        id: 0,
        title: "",
        mode: "did",
        number: "",
        siptrunk: "",
        contactuserid: null,
        maxcalls: 0,
        password: "<hidden>",
        dest_type: "hangup",
    })

    reload = () => {
        this.props.dispatch(actions.load())
    }

    onEdit = contact => {
        this.setState({
            edit: contact,
        })
    }

    onDelete = contact => {
        this.setState({deleting: contact})
    }

    handleChangeStatus = (contact, status) => {
        actions.status(contact.get("id"), status, this.props.dispatch).then(() => this.reload())
    }

    render() {
        return (
            <div>
                <Title title="Contacts" />
                <div className="actionBar">
                    <div className="float-right">
                        <Button variant="success" className="addnew" onClick={() => this.onEdit(this.newContact)}>
                            <Icon icon="plus" /> Add Contact
                        </Button>
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                {this.props.loaded && (
                    <Contacts
                        data={this.props.data}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                        onReload={this.reload}
                        onChangeStatus={this.handleChangeStatus}
                    />
                )}
                {this.state.edit !== null && (
                    <ContactEditControlled contact={this.state.edit} onClose={() => this.setState({edit: null})} />
                )}
                {this.state.deleting && (
                    <DeleteModal
                        id={this.state.deleting.get("id")}
                        title={this.state.deleting.get("title")}
                        type="contact"
                        onClose={() => this.setState({deleting: null})}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.contacts.get("data"),
        loaded: state.contacts.get("loaded"),
    }
}

export default connect(mapStateToProps)(ContactsControlled)
