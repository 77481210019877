import axios from "axios"
import config from "../config"
import * as loading from "../actions/loading"
import {fromJS} from "immutable"

const clientsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "CLIENTS_LOAD":
                store.dispatch(loading.start())
                axios
                    .get(`${config.apiEndpoint}clients`)
                    .then(response => {
                        store.dispatch(loading.end())
                        action.resolve(fromJS(response.data))
                    })
                    .catch(e => {
                        console.log(e)
                        store.dispatch(loading.end())
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })

                break

            case "CLIENTS_SAVE":
                const client = action.client.toJS()
                const noid = action.client.delete("id").toJS()
                const req = client.id
                    ? axios.put(`${config.apiEndpoint}client/${client.id}`, noid)
                    : axios.post(`${config.apiEndpoint}client`, noid)
                req.then(response => {
                    action.resolve(response.data.id)
                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                    else action.reject(e.message)
                })
                break

            case "CLIENTS_DELETE":
                axios
                    .delete(`${config.apiEndpoint}client/${action.id}`, {
                        data: action.newrouting,
                    })
                    .then(response => {
                        action.resolve()
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            default:
                return next(action)
        }
    }
})()

export default clientsMiddleware
