import React from "react"
import moment from "moment"
import {formatMoney} from "../../tools"

class SpendingRow extends React.PureComponent {
    render() {
        const row = this.props.row
        return (
            <tr>
                <td>{moment(row.get("date")).format("L")}</td>
                <td className="right">{row.get("minutes_in")}</td>
                <td className="right">{formatMoney(row.get("price_in"), 2)}</td>
                <td className="right">{row.get("minutes_out")}</td>
                <td className="right">{formatMoney(row.get("price_out"), 2)}</td>
                <td className="right">{formatMoney(row.get("price_in") + row.get("price_out"), 2)}</td>
            </tr>
        )
    }
}

export default SpendingRow
