export function login(email, password, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "AUTH_LOGIN",
            email: email,
            password: password,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function check(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "AUTH_CHECK",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function checked() {
    return {
        type: "AUTH_CHECKED",
    }
}

export function error(msg) {
    return {
        type: "AUTH_ERROR",
        msg: msg,
    }
}

export function logout() {
    return {
        type: "AUTH_LOGOUT",
    }
}

export function balance(dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "AUTH_BALANCE",
            resolve: resolve,
            reject: reject,
        })
    })
}

export function balanceLoaded(balance) {
    return {
        type: "AUTH_BALANCE_LOADED",
        balance: balance,
    }
}

export function smsApiKey(apikey) {
    return {
        type: "AUTH_SMS_APIKEY",
        apikey: apikey,
    }
}

export function apiKey(apikey) {
    return {
        type: "AUTH_APIKEY",
        apikey: apikey,
    }
}

export function postLogout() {
    return {
        type: "AUTH_POST_LOGOUT",
    }
}

export function preAuth(user, token, stack, timeOffset) {
    return {
        type: "AUTH_PRE_AUTHORIZED",
        user: user,
        token: token,
        stack: stack,
        timeOffset: timeOffset,
    }
}

export function authorized(user, token, stack, timeOffset, theme) {
    return {
        type: "AUTH_AUTHORIZED",
        user: user,
        token: token,
        stack: stack,
        timeOffset: timeOffset,
        theme: theme,
    }
}

export function impersonate(user, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "AUTH_IMPERSONATE",
            user: user,
            resolve: resolve,
            reject: reject,
        })
    })
}

export function setColorTheme(theme) {
    return {
        type: "AUTH_COLOR_THEME",
        theme: theme,
    }
}
