import moment from "moment"

export const isNumber = (s, nozero) => {
    let n = parseInt(s, 10)
    if (isNaN(n) || n < 0) return false
    if (n === 0 && nozero === true) return false
    if (n.toString() !== s.toString()) return false
    return true
}

export const formatCardBrand = brand => {
    switch (brand) {
        case "visa":
            return "VISA"
        case "mastercard":
            return "MasterCard"
        case "amex":
            return "American Express"
        case "diners":
            return "Diners Club"
        case "discover":
            return "Discover"
        case "jcb":
            return "JCB"
        default:
            return "Unknown"
    }
}

export const formatNumber = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? "-" : ""

        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
        let j = i.length > 3 ? i.length % 3 : 0

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : "")
        )
    } catch (e) {
        console.log(e)
    }
}

export const formatPhone = value => {
    let phone = value ? value.replace(/[^0-9]+/g, "") : ""
    if (phone.length === 11 && phone.substr(0, 1) === "1") {
        phone = phone.substr(1)
    }
    if (phone.length === 10) {
        phone = "(" + phone.substr(0, 3) + ") " + phone.substr(3, 3) + "-" + phone.substr(6)
    }
    return phone
}

export const formatMoney = (value, prec) => {
    let val = value ? value : 0
    if (prec === undefined) prec = 2
    if (prec === 2 && Math.abs(val) > 0 && Math.abs(val) < 0.01) prec = 4
    const sign = val < 0 ? "−" : ""
    return sign + "$" + formatNumber(Math.abs(val), prec)
}

export const formatTime = time => {
    const h = Math.floor(time / (60 * 60))
    const m = Math.floor((time % (60 * 60)) / 60)
    const s = time % 60
    const result = h + ":" + String(m).padStart(2, "0") + ":" + String(s).padStart(2, "0")
    return result
}

export const formatTimeMS = time => {
    if (isNaN(time)) time = 0
    const m = Math.floor(time / 60)
    const s = time % 60
    const result = String(m) + ":" + String(s).padStart(2, "0")
    return result
}

export const fixTime = (apitime, timeOffset) => {
    return timeOffset !== 0 ? moment(apitime.substring(0, 19)) : moment(apitime)
}

export const noNaN = value => {
    return value ? value : 0
}

export const trimStrings = data => {
    return data.map(item => (typeof item === "string" ? item.trim() : item))
}

export const convertTypes = (data, types) => {
    return data.map((v, k) => {
        switch (types[k]) {
            case "int":
                v = parseInt(v, 10)
                return isNaN(v) ? 0 : v
            case "decimal":
                v = parseFloat(v)
                return isNaN(v) ? 0 : v
            default:
                return v
        }
    })
}
