import React from "react"
import {connect} from "react-redux"
import Jobs from "./Jobs"
import {Map, List} from "immutable"
import LoadError from "./LoadError"
import * as actions from "./actions/jobs"
import Paginator from "./Paginator"
import Filters from "./filters/Filters"
import ReloadIcon from "./ReloadIcon"
import iconDids from "./images/menu/dids.svg"

const pageLength = 50

class JobsControlled extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            loaderror: "",
            data: List([]),
            currentPage: 0,
            filter: Map({}),
        }
    }

    reload = state => {
        if (!state) state = this.state
        let page = state.currentPage

        let start = pageLength * page

        actions
            .load(this.state.filter, start, pageLength, this.props.dispatch)
            .then(response => {
                const {data, total} = response
                const totalPages = Math.ceil(total / pageLength)
                if (page > totalPages) {
                    page = totalPages
                }

                this.setState({
                    loaded: true,
                    loaderror: null,
                    currentPage: page,
                    totalItems: total,
                    data: data,
                })
            })
            .catch(e => {
                this.setState({loaderror: "Error loading jobs", loaded: false})
            })
    }

    componentDidMount() {
        this.reload()
    }

    onSetPage = page => {
        this.reload({
            currentPage: page,
        })
    }

    onFilter = fields => {
        this.onChangeFilter(this.state.filter.merge(fields))
    }

    onChangeFilter = filter => {
        this.setState(
            {
                currentPage: 0,
                filter: filter,
            },
            () => {
                this.reload()
            },
        )
    }

    render() {
        return (
            <div>
                {!this.state.loaderror && (
                    <div className="actionBar">
                        <div className="float-right">
                            <Filters
                                icon={iconDids}
                                enabled={["date1", "date2", "user"]}
                                filter={this.state.filter}
                                onChange={this.onChangeFilter}
                            />
                            <ReloadIcon onClick={this.reload} />
                        </div>
                    </div>
                )}
                {this.state.loaded && (
                    <>
                        <Jobs
                            restricted={this.props.user.get("role") !== "admin"}
                            data={this.state.data}
                            onReload={this.reload}
                            timeOffset={this.props.timeOffset}
                        />
                        <Paginator
                            currentPage={this.state.currentPage}
                            totalItems={this.state.totalItems}
                            pageLength={pageLength}
                            onSetPage={this.onSetPage}
                        />
                    </>
                )}
                {this.state.loaderror && <LoadError message={this.state.loaderror} onReload={this.reload} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
        timeOffset: state.auth.get("timeOffset"),
    }
}

export default connect(mapStateToProps)(JobsControlled)
