import React, {Component} from "react"
import {connect} from "react-redux"
import UserEarnings from "./UserEarnings"
import {List, Map} from "immutable"
import {toast} from "react-toastify"
import {userEarningsLoad} from "../../actions/reports"
import Title from "../../Title"
import Filters from "../../filters/Filters"
import ReloadIcon from "../../ReloadIcon"
import * as loading from "../../actions/loading"
import {push} from "connected-react-router"
import iconReports from "../../images/menu/reports.svg"
import moment from "moment"

class UserEarningsControlled extends Component {
    constructor(props) {
        super(props)

        this.filters = ["date1", "date2"]
        this.state = {
            data: List([]),
            filter: Map({
                date1: moment()
                    .startOf("day")
                    .toDate(),
            }),
        }
    }

    reload = () => {
        this.props.dispatch(loading.start())

        userEarningsLoad(this.state.filter, this.props.dispatch)
            .then(response => {
                this.props.dispatch(loading.end())
                this.setState({data: response.data})
            })
            .catch(e => {
                console.log(e)
                toast.error("Error loading report data", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.dispatch(loading.end())
            })
    }

    componentDidMount() {
        this.reload()
    }

    onChangeFilter = filter => {
        this.setState({filter: filter}, () => {
            this.reload()
        })
    }

    saveFilter = did => {
        const filter = {
            did: did,
        }
        window.sessionStorage.setItem("cdrs-filter", JSON.stringify(filter))
    }

    onSelectDid = did => {
        this.saveFilter(did)
        this.props.dispatch(push(`/cdrs/`))
    }

    render() {
        return (
            <>
                <Title title="Client Endpoints" />
                <div className="actionBar">
                    <div className="float-right">
                        <Filters icon={iconReports} enabled={this.filters} filter={this.state.filter} onChange={this.onChangeFilter} />
                        <ReloadIcon onClick={this.reload} />
                    </div>
                </div>
                <UserEarnings data={this.state.data} />
            </>
        )
    }
}

export default connect()(UserEarningsControlled)
