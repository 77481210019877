import React, {Component} from "react"
import {connect} from "react-redux"
import {Button, Modal, Form, Col, Row} from "react-bootstrap"
import iconDids from "./images/menu/dids.svg"
import Icon from "./Icon"

class DidsTransferModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userid: 0,
        }
    }

    onChange = () => {
        this.props.onChange(parseInt(this.state.userid, 10))
    }

    onChangeField = e => {
        let value = e.target.value
        this.setState({
            userid: value,
        })
    }

    render() {
        if (!this.props.loaded) return ""

        const users = this.props.users.filter(user => user.get("id") !== this.props.user.get("id"))

        return (
            <Modal show backdrop animation={false}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconDids} alt="" style={{marginRight: "20px"}} />
                            Transfer DIDs
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column xs="2">
                                    User
                                </Form.Label>
                                <Col xs="10">
                                    <Form.Control as="select" value={this.state.userid} onChange={this.onChangeField}>
                                        <option value="">Select User</option>
                                        {users.map(user => (
                                            <option value={user.get("id")}>{user.get("email")}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="info" className="continue" onClick={this.onChange} disabled={!this.state.userid}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.get("data"),
        loaded: state.users.get("loaded"),
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(DidsTransferModal)
