import React from "react"
import {Form} from "react-bootstrap"

const make = title => {
    return {
        title: title,
        Input: props => {
            return (
                <Form.Control
                    type="text"
                    name="number"
                    value={props.value.replace(/%/g, "")}
                    onChange={e => {
                        let val = e.target.value.trim()

                        if (!(val.length >= 10 && val.length <= 11))
                            val = '%' + val + '%'

                        props.onChange(val, e.target.value)
                    }}
                />
            )
        },
    }
}

export default make
