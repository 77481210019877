import React from "react"
import {connect} from "react-redux"
import Icon from "./Icon"

const RoutingDestination = React.memo(props => {
    switch (props.type) {
        case "replay":
            return (
                <span title="Replay">
                    <Icon icon="retreet" />
                </span>
            )
        case "dnc":
            return (
                <span title="Do Not Call(Blacklist)">
                    <Icon icon="ban" />
                </span>
            )
        case "hangup":
            return (
                <span title="Hangup">
                    <Icon icon="phone-slash" />
                </span>
            )
        case "contact": {
            const item = props.contacts.filter(i => i.get("id").toString() === props.value).get(0)
            return (
                <span title="Contact">
                    <Icon icon="user" /> {!!item && item.get("title")}
                </span>
            )
        }
        case "ivr": {
            const item = props.ivrs.filter(i => i.get("id").toString() === props.value).get(0)
            return (
                <span title="IVR">
                    <Icon icon="list" /> {!!item && item.get("name")}
                </span>
            )
        }
        case "routinggroup": {
            const item = props.routinggroups.filter(i => i.get("id").toString() === props.value).get(0)
            return (
                <span title="Routing Group">
                    <Icon icon="users" /> {!!item && item.get("title")}
                </span>
            )
        }
        case "":
            return "Not Configured"
        default:
            alert(props.type)
    }
})

const mapStateToProps = state => {
    return {
        contacts: state.contacts.get("data"),
        ivrs: state.ivrs.get("data"),
        routinggroups: state.routinggroups.get("data"),
    }
}

export default connect(mapStateToProps)(RoutingDestination)
