import React from "react"
import {connect} from "react-redux"
import {Button, Modal} from "react-bootstrap"

import * as actions from "../actions/contacts"
import SpinnerButton from "../SpinnerButton"
import Icon from "../Icon"
import iconContacts from "../images/menu/clients.svg"

import ContactEdit from "./ContactEdit"
import {toast} from "react-toastify"
import {Set} from "immutable"
import {isInt} from "../validators"

import {load as loadClients} from "../actions/clients"

class ContactEditControlled extends React.PureComponent {
    constructor(props) {
        super(props)

        let contact = props.contact
        let sip = contact.get("siptrunk").match(/^sip:([^@]*)@(.*):(\d*)$/)
        if (!sip) {
            sip = ["", "", "", ""]
        }

        contact = contact
            .set("sipusername", sip[1])
            .set("sipip", sip[2])
            .set("sipport", sip[3])

        this.state = {
            saving: false,
            errors: this.validate(contact),
            contact: contact,
            clients: null,
        }
    }

    componentDidMount = () => {
        if (!this.props.nochangeclient)
            loadClients(this.props.dispatch)
                .then(response => this.setState({clients: response}))
                .catch(e => console.log(e))
    }

    onSaveContact = () => {
        this.setState({saving: true})
        const int = ["maxcalls", "clientuserid"]
        let contact = this.state.contact.map((v, k) => (int.includes(k) ? parseInt(v, 10) : v))
        contact = contact
            .set("siptrunk", `sip:${contact.get("sipusername")}@${contact.get("sipip")}:${contact.get("sipport")}`)
            .remove("sipusername")
            .remove("sipip")
            .remove("sipport")

        actions
            .save(contact, this.props.dispatch)
            .then(() => {
                toast.success("Contact saved", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                this.props.onClose()
                this.props.dispatch(actions.load())
            })
            .catch(error => {
                this.setState({saving: false})
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    validate = contact => {
        const fields = ["maxcalls", "title"]
        let errors = Set([])
        for (var field of fields) {
            const value = contact.has(field) ? contact.get(field) : ""
            if (field === "maxcalls") {
                if (!isInt(value)) {
                    errors = errors.add(field)
                }
            }
            if (field === "title" && !value) {
                errors = errors.add("title")
            }
        }
        if (contact.get("mode") === "did" && !contact.get("number")) {
            errors = errors.add("number")
        }
        if (contact.get("mode") === "sip") {
            for (var f of ["sipip", "sipport", "sipusername"]) {
                if (!contact.get(f)) {
                    errors = errors.add(f)
                }
            }
        }
        return errors
    }

    onChange = contact => {
        this.setState({contact: contact, errors: this.validate(contact)})
    }

    render() {
        if (!this.state.clients && !this.props.nochangeclient) return ""
        return (
            <Modal show backdrop animation={false} onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon icon="arrow-left" onClick={this.props.onClose} color="#CDCDCD" />
                        <span>
                            <img src={iconContacts} alt="" style={{height: "46px", marginRight: "20px"}} />
                            {this.state.contact.get("id") === 0 && <span>New Contact</span>}
                            {this.state.contact.get("id") !== 0 && <span>Edit Contact</span>}
                        </span>
                        <Icon icon="times" onClick={this.props.onClose} color="#CDCDCD" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ContactEdit
                        contact={this.state.contact}
                        errors={this.state.errors}
                        onChange={this.onChange}
                        clients={this.state.clients}
                        user={this.props.user}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" className="close" onClick={this.props.onClose} disabled={this.state.saving}>
                        Close
                    </Button>
                    <SpinnerButton onClick={this.onSaveContact} active={this.state.saving} disabled={this.state.errors.size > 0} />
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.get("user"),
    }
}

export default connect(mapStateToProps)(ContactEditControlled)
