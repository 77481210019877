import React, {Component} from "react"
import {Form, InputGroup, Button} from "react-bootstrap"
import Icon from "./Icon"

class GroupField extends Component {
    constructor(props) {
        super(props)

        const group = props.group === null ? "" : props.group

        this.state = {
            select: this.isSelect(group),
            group: group,
            groups: props.groups,
        }
    }

    componentDidUpdate = prevProps => {
        if (prevProps.group !== this.props.group) {
            this.setState({group: this.props.group})
        }
    }

    isSelect = group => {
        return !group || this.props.groups.filter(g => group === g.get("name")).size > 0
    }

    onClear = () => {
        this.setState(
            {
                select: true,
            },
            () =>
                this.props.onChange({
                    target: {
                        name: "group",
                        value: "",
                    },
                }),
        )
    }

    onChange = e => {
        const value = e.target.value
        if (value === "__new__") {
            this.setState({select: false})
            this.props.onChange({
                target: {
                    name: "group",
                    value: "",
                },
            })
            return
        }
        this.props.onChange(e)
    }

    render() {
        return this.state.select ? (
            <Form.Control as="select" name="group" value={this.state.group} onChange={this.onChange}>
                <option value="">Select Group</option>
                <option value="__new__">Add New Group...</option>
                {this.state.groups.map(group => (
                    <option key={group.get("id")} value={group.get("name")}>
                        {group.get("name")}
                    </option>
                ))}
            </Form.Control>
        ) : (
            <InputGroup>
                <Form.Control autoFocus name="group" placeholder="New Did Group" value={this.state.group} onChange={this.onChange} />
                <InputGroup.Append>
                    <Button variant="secondary" onClick={this.onClear}>
                        <Icon icon="times" />
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        )
    }
}

export default GroupField
