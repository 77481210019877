import React from "react"
import {Form} from "react-bootstrap"

const make = title => {
    return {
        title: title,
        Input: props => {
            return <Form.Control type="text" name="number" value={props.value} onChange={e => props.onChange(e.target.value)} />
        },
    }
}

export default make
