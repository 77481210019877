import React from "react"
import {Form} from "react-bootstrap"
import states from "../states"
import {Map} from "immutable"

const sortedStates = Map(states).sort()

const filter = {
    title: "State",
    Input: props => {
        return (
            <Form.Control as="select" value={props.value} onChange={e => props.onChange(e.target.value, sortedStates.get(e.target.value))}>
                <option value="">Select State</option>
                {sortedStates.keySeq().map(key => (
                    <option value={key} key={key}>
                        {sortedStates.get(key)}
                    </option>
                ))}
            </Form.Control>
        )
    },
}

export default filter
