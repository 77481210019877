import React from "react"
import {Table} from "react-bootstrap"
import Cdr from "./Cdr"
import Th from "./Th"

class Cdrs extends React.PureComponent {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th className="d-none d-lg-table-cell">ID</th>
                        {this.props.role !== "client" && <th>Direction</th>}
                        {this.props.role !== "client" && (
                            <Th
                                field="cdrs.did"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                DID
                            </Th>
                        )}
                        <Th
                            field="src"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                            className="d-none d-lg-table-cell"
                        >
                            Caller ID
                        </Th>
                        {this.props.role === "client" && (
                            <Th
                                field="cdrs.userfield"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                Destination
                            </Th>
                        )}
                        {this.props.role !== "client" && <th>Destination</th>}
                        <Th
                            field="duration"
                            sortfield={this.props.sortfield}
                            sortdir={this.props.sortdir}
                            onChange={this.props.onChangeSort}
                        >
                            Duration
                        </Th>
                        {this.props.role !== "client" && (
                            <Th
                                field="price"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                            >
                                Price
                            </Th>
                        )}
                        {this.props.role === "admin" && (
                            <Th
                                field="email"
                                sortfield={this.props.sortfield}
                                sortdir={this.props.sortdir}
                                onChange={this.props.onChangeSort}
                                className="d-none d-lg-table-cell"
                            >
                                User
                            </Th>
                        )}
                        <Th field="id" sortfield={this.props.sortfield} sortdir={this.props.sortdir} onChange={this.props.onChangeSort}>
                            Date/time
                        </Th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.size === 0 && (
                        <tr>
                            <td colSpan={9}>
                                <center>No Data</center>
                            </td>
                        </tr>
                    )}
                    {this.props.data.map((item, key) => (
                        <Cdr
                            role={this.props.role}
                            index={key}
                            id={key}
                            key={item.get("id")}
                            cdr={item}
                            onEdit={() => this.props.onEdit(item)}
                            onDelete={() => this.props.onDelete(item)}
                            onFilter={this.props.onFilter}
                            filter={this.props.filter}
                            timeOffset={this.props.timeOffset}
                        />
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default Cdrs
