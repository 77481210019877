import axios from "axios"
import config from "../config"
import moment from "moment"
import {fromJS} from "immutable"

const createUrl = (url, action) => {
    const offset = moment().utcOffset()
    let filter = action.filter ? JSON.stringify(action.filter.filter(v => !!v && v !== "all").toJS()) : ""

    let u = `${config.apiEndpoint}${url}?filter=${encodeURIComponent(filter)}&offset=${offset}`
    if (action.start !== undefined) u = u + `&start=${action.start}&limit=${action.limit}`
    if (action.sortfield !== undefined) u = u + `&sortfield=${action.sortfield}&sortdir=${action.sortdir}`
    return u
}

const fetchReport = (url, action) => {
    const dataurl = createUrl(url, action)
    axios
        .get(dataurl)
        .then(response => {
            action.resolve({
                data: fromJS(response.data),
                total: parseInt(response.headers["x-total-count"], 10),
            })
        })
        .catch(e => {
            console.log(e)
            if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
            else action.reject(e.message)
        })
}

const fetchStats = (url, action) => {
    let filter = action.filter
        ? JSON.stringify(
              action.filter
                  .map((v, k) => (k === "groups" ? v.map(g => g.id).join(",") : v))
                  .filter(v => !!v)
                  .toJS(),
          )
        : ""
    axios
        .get(`${config.apiEndpoint}${url}?filter=${encodeURIComponent(filter)}`)
        .then(response => {
            action.resolve(response.data)
        })
        .catch(e => {
            if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
            else action.reject(e.message)
        })
}

const reportsMiddleware = (function() {
    return store => next => action => {
        switch (action.type) {
            case "REPORT_CDRS_LOAD":
                fetchReport("reports/cdrs", action)
                break

            case "REPORT_CDRS_CSV_LINK":
                {
                    const url =
                        createUrl("reports/cdrs", Object.assign({start: 0, limit: 100000}, action)) + "&format=text&filename=cdrs.csv"
                    action.resolve(url)
                }

                break

            case "REPORT_CDRS_GROUPED_LOAD":
                fetchReport("reports/cdrs/grouped", action)
                break

            case "REPORT_SMS_LOAD":
                fetchReport("sms", action)
                break

            case "REPORT_TRANSACTIONS_LOAD":
                fetchReport("transactions", action)
                break

            case "REPORT_DIDS_LOAD":
                fetchReport("reports/dids", action)
                break

            case "REPORT_SPENDING_LOAD":
                fetchReport("reports/spending", action)
                break

            case "REPORT_CDRS_STATS_LOAD":
                fetchStats("reports/cdrs/stats", action)
                break

            case "REPORT_LIVECALLS_LOAD":
                fetchReport("reports/livecalls", action)
                break

            case "REPORT_LIVECALLS_KILL":
                axios
                    .post(`${config.apiEndpoint}voip/hangup`, action.data)
                    .then(response => action.resolve(response.data))
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "REPORT_IVRLOGS_LOAD":
                fetchReport("reports/ivrlog", action)
                break

            case "REPORT_INFO_LOAD":
                let date = new Date()
                date.setHours(0, 0, 0)

                axios
                    .get(`${config.apiEndpoint}info?start=` + Math.floor(date.getTime() / 1000))
                    .then(response => {
                        action.resolve(response.data)
                    })
                    .catch(e => {
                        if (e.response && e.response.data && e.response.data.msg) action.reject(e.response.data.msg)
                        else action.reject(e.message)
                    })
                break

            case "REPORT_EARNINGS_LOAD":
                fetchReport("reports/earnings", action)
                break

            case "REPORT_USEREARNINGS_LOAD":
                fetchReport("user/earnings", action)
                break

            default:
                return next(action)
        }
    }
})()

export default reportsMiddleware
